<template>
  <div :class="[componentClass.root, classes]">
    <template v-for="tab in props.tabs" :key="tab.id">
      <PmTabNavigationItemPure
        v-bind="normalizeTab(tab)"
        :is-selected="isTabSelected(tab)"
        :class="componentClass.element('button')"
        :expanded="expanded"
        @select-tab="(id) => (selectedTabId = id)"
      />
    </template>
  </div>
</template>

<script setup lang="ts" generic="Tabs extends Props['tabs'] = []">
import { computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import { useLink } from 'vue-router'

import PmTabNavigationItemPure, {
  type Props as PropsTabNavigationItemPure,
} from '@/components/basics/PmTabNavigation/PmTabNavigationItemPure.vue'

export type Props = {
  expanded?: boolean
  tabs: PropsTabNavigationItemPure[]
}

type PropsPrivate = Omit<Props, 'tabs'> & {
  tabs: Tabs
}

type Tab = Tabs[number]
type TabId = Tabs[number]['id']

const props = withDefaults(defineProps<PropsPrivate>(), {})

const componentClass = useComponentClass()
const selectedTabId = defineModel<TabId>('selectedTabId')

const classes = computed(() => {
  return {
    [componentClass.modifier('expanded')]: props.expanded,
  }
})

function normalizeTab(tab: Props['tabs'][number]) {
  return {
    ...tab,
    id: tab.id as string,
  }
}

function isTabSelected(tab: Tab) {
  if (tab.to) {
    const link = useLink({ to: tab.to })
    return link.isActive.value
  }

  return tab.id == selectedTabId.value
}
</script>

<style lang="scss">
.PmTabNavigationPure {
  $block: &;

  display: inline-flex;

  &--expanded {
    display: flex;
  }

  &-button {
    #{$block}--expanded & {
      flex-basis: 100%;
      flex-grow: 1;
    }
  }
}
</style>
