<template>
  <div class="PmNotificationDropdownPure">
    <div class="PmNotificationDropdownPure-header">
      <div class="PmNotificationDropdownPure-title">Benachrichtigungen</div>

      <PmLoadingPure v-if="isLoading" size="small" />
    </div>

    <div class="PmNotificationDropdownPure-list">
      <slot
        name="notifications"
        :class="{ 'PmNotificationDropdownPure-listItem': true }"
      >
      </slot>

      <div v-if="isEmpty" class="PmNotificationDropdownPure-empty">
        <PmIconPure
          name="snooze"
          class="PmNotificationDropdownPure-emptyIcon"
        />

        <div class="PmNotificationDropdownPure-emptyText">
          Keine Benachrichtigungen
        </div>
      </div>
    </div>

    <div class="PmNotificationDropdownPure-control">
      <PmButtonPure
        icon="checkCheck"
        label="Alle als gelesen markieren"
        alternative="ghost"
        :disabled="isMarkAllAsReadButtonDisabled"
        @click="emit('markAllAsRead')"
      />

      <PmCheckboxPure v-model:value="showRead" label="Alle anzeigen" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmNotificationDropdownPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmCheckboxPure from '@/components/basics/PmCheckboxPure.vue'

import { useHasSlotContent } from '@/composition/useHasSlotContent'

export interface Props {
  isLoading?: boolean
  hasUnreadNotifications?: boolean
}

const props = withDefaults(defineProps<Props>(), {})
const showRead = defineModel('showRead', { type: Boolean })

const emit = defineEmits<{
  (event: 'markAllAsRead'): void
}>()

const hasSlotContent = useHasSlotContent(['notifications'])

const isEmpty = computed(() => hasSlotContent.value.notifications === false)

const isMarkAllAsReadButtonDisabled = computed(() => {
  if (isEmpty.value) return true
  if (props.hasUnreadNotifications !== true) return true

  return false
})
</script>

<style lang="scss">
.PmNotificationDropdownPure {
  $block: &;
  $space: 12px;

  width: 600px;
  max-width: 100%;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  max-height: calc(80vh - 80px);

  @include mixin.media('height<=500px') {
    max-height: calc(100vh - 80px);
  }

  &-header {
    position: sticky;
    top: 0;
    background-color: color.$white;
    z-index: 1;
    border-bottom: 1px solid color.$gray-300;
    display: flex;
    align-items: center;
    padding-left: $space;
    padding-right: $space;
  }

  &-title {
    @include typography.h4($includeMargin: false);

    padding-top: $space;
    padding-bottom: $space;
    flex-grow: 1;
  }

  &-list {
    padding-left: $space;
    padding-right: $space;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
  }

  &-listItem {
    padding-top: 12px;
    padding-bottom: 12px;

    &:not(:last-child) {
      border-bottom: 1px solid color.$gray-200;
    }
  }

  &-empty {
    padding-top: 28px;
    padding-bottom: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    color: color.$gray-400;
  }

  &-emptyIcon {
    width: 32px;
    height: 32px;
  }

  &-emptyText {
    font-size: constant.$fontSize-large;
    font-weight: bold;
  }

  &-control {
    position: sticky;
    bottom: 0;
    background-color: color.$white;
    padding: $space;
    z-index: 1;
    border-top: 1px solid color.$gray-300;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
