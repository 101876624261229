<template>
  <PmMultipleResourcesDefaultPure
    :type="resourceType"
    :number-of-selected-resource-functions="numberOfSelectedResourceFunctions"
    :number-of-selected-resources-to-allocate="
      numberOfSelectedResourcesToAllocate
    "
    :status-options="resourceAllocationStates.statusOptions.value"
    :default-values="{
      statusId: STATUS_RESOURCE_ALLOCATION_ID_LOOKUP['considered'],
    }"
    @search="search"
    @cancel="emit('cancel')"
    @submit="(payload) => createResourceAllocations(payload)"
  >
    <template #resourcesInput>
      <PmResourceDropzone
        :resource-type="resourceType"
        :resource-ids="selectedResourcesIds"
      />
    </template>
  </PmMultipleResourcesDefaultPure>
</template>

<script setup lang="ts">
import PmMultipleResourcesDefaultPure, {
  type Props as PropsMultipleResourcesDefaultPure,
  type Emits as EmitsMultipleResourcesDefaultPure,
} from '@/components/persoplan/PmMultiSelectionController/PmMultipleResourcesDefault/PmMultipleResourcesDefaultPure.vue'
import PmResourceDropzone from '@/components/persoplan/PmResourceDropzone/PmResourceDropzone.vue'
import type { ResourceType } from '@/constants/persoplan'
import { useResourceAllocationStates } from '@/composition/useResourceAllocationStates'
import { STATUS_RESOURCE_ALLOCATION_ID_LOOKUP } from '@/constants/persoplan'

export interface Props
  extends Pick<
    PropsMultipleResourcesDefaultPure,
    | 'numberOfSelectedResourceFunctions'
    | 'type'
    | 'numberOfSelectedResourceFunctions'
    | 'numberOfSelectedResourcesToAllocate'
  > {
  resourceType: Exclude<ResourceType, 'freelancer'>
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  search: {
    type: Exclude<ResourceType, 'freelancer'>
  }
  createResourceAllocations: {
    resourceIds: number[]
  } & EmitsMultipleResourcesDefaultPure['submit']
}

const emit = defineEmits<{
  search: [Emits['search']]
  cancel: []
  createResourceAllocations: [Emits['createResourceAllocations']]
}>()

const resourceAllocationStates = useResourceAllocationStates()

const selectedResourcesIds = defineModel<Set<number>>('selectedResourcesIds', {
  default: new Set(),
})

function search() {
  emit('search', {
    type: props.resourceType,
  })
}

function createResourceAllocations(
  payload: EmitsMultipleResourcesDefaultPure['submit']
) {
  if (selectedResourcesIds.value.size === 0) return

  console.log('create', payload)

  emit('createResourceAllocations', {
    ...payload,
    resourceIds: Array.from(selectedResourcesIds.value),
  })
}
</script>
