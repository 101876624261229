import type { ResourceType } from '@/constants/persoplan'
import EventHub from '@/eventHub'
import { EVENT } from '@/constants/events'

type Filter = {
  resourceType: Exclude<ResourceType, 'freelancer'>
  resourceFunctionIds: number[]
}

function setFilter(options: Filter) {
  EventHub.$emit(EVENT.ACTIONBAR_SET_FILTER, {
    type: options.resourceType,
    resourceFunctionIds: options.resourceFunctionIds,
  })
}

export function useActionbar() {
  return {
    setFilter,
  }
}
