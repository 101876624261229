<template>
  <div
    class="PmContextNavigationPure"
    :class="[$attrs.class, classes]"
    aria-hidden="true"
  >
    <div ref="elTrigger" class="PmContextNavigationPure-triggerContainer">
      <PmButtonPure
        v-if="!hasSlotContent.trigger"
        :icon="icon"
        :variant="variant"
        v-bind="separateAttrs($attrs).attributes"
        aria-hidden="true"
        tabindex="-1"
        class="PmContextNavigationPure-trigger"
        :disabled="disabled"
        :active="isOpenNormalized"
        :alternative="alternative"
        :size="size"
        @click="togglePopover"
      />

      <slot
        name="trigger"
        :toggle-popover="togglePopover"
        :disabled="disabled"
        :is-open="isOpenNormalized"
      />
    </div>

    <template v-if="isOpenNormalized">
      <portal to="contextNavigation">
        <PmOnClickOutsidePure
          :key="id"
          :disabled="!isOpenNormalized"
          :do="close"
        >
          <PmPopoverPure
            class="PmContextNavigationPure-popover"
            :element="elTrigger"
            is-positioned-on="element"
            :padding="false"
            :is-loading="isLoading"
            placement="right-start"
            :is-arrow-visible="false"
            :strategy="popoverStrategy"
            :use-max-dimensions="true"
          >
            <slot />

            <PmContextNavigationListPure
              :items="itemsNormalized"
              :disabled="disabled"
              @click-on-item="onClickOnItem"
            />
          </PmPopoverPure>
        </PmOnClickOutsidePure>
      </portal>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmContextNavigationPure'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
  inheritAttrs: false,
})
</script>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import cuid from '@paralleldrive/cuid2'

import { type Icon } from '@/constants/icons'

import { separateAttrs } from '@/utilities/misc'
import { useHasSlotContent } from '@/composition/useHasSlotContent'

import PmButtonPure, {
  type Props as PropsButtonPure,
} from '@/components/basics/PmButtonPure.vue'
import PmOnClickOutsidePure from '@/components/utilities/PmOnClickOutsidePure.vue'
import PmContextNavigationListPure, {
  type Item as ItemBase,
} from '@/components/basics/PmContextNavigation/PmContextNavigationListPure.vue'

import PmPopoverPure from '@/components/basics/PmPopover/PmPopoverPure.vue'

import type { Props as PropsPopoverPure } from '@/components/basics/PmPopover/PmPopoverPure.vue'

export interface Item extends ItemBase {
  visible?: boolean
}

export interface Props {
  items?: Item[]
  isLoading?: boolean
  icon?: Icon
  variant?: PropsButtonPure['variant']
  size?: PropsButtonPure['size']
  alternative?: PropsButtonPure['alternative']
  popoverStrategy?: PropsPopoverPure['strategy']
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  icon: 'contextNavigation',
  strategy: 'fixed',
})

const emit = defineEmits<{
  (event: 'open'): void
  (event: 'close'): void
}>()

const hasSlotContent = useHasSlotContent(['trigger'])

/**
 * id is used as a key for the context navigation to improve behaviour when
 * opening a context navigation while another is open already
 */
const id = cuid.createId()
const isOpen = ref(false)
const elTrigger = ref<HTMLElement>()

const isOpenNormalized = computed(() => {
  return elTrigger.value && isOpen.value
})

const classes = computed(() => {
  return {
    'is-open': isOpenNormalized.value,
  }
})

const itemsNormalized = computed(() => {
  if (!props.items) return []

  const visibleItems = props.items.filter((item) => {
    if (item.visible === false) return false
    return true
  })

  return visibleItems
})

function onClickOnItem(id: string) {
  // @ts-expect-error No way to define general emits?
  emit(id)
  close()
}

function togglePopover() {
  if (isOpen.value) {
    isOpen.value = false
    return
  }

  isOpen.value = true
}

function close() {
  isOpen.value = false
}

watch(isOpen, () => {
  isOpen.value ? emit('open') : emit('close')
})
</script>

<style lang="scss">
.PmContextNavigationPure {
  $block: &;

  display: inline-block;

  &-popover {
    // TODO: https://gitlab.com/pro-musik/frontend/-/issues/909
  }
}
</style>
