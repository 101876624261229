import type { FieldFunctionOptions } from '@apollo/client/cache/inmemory/policies'

import { InMemoryCache, type Reference } from '@apollo/client/core'
import { get } from 'lodash-es'

import generatedIntrospection from '@/../generated/introspectionResult'

/**
 * Helper function to retrieve cache results for a collection based on a filter
 *
 * It is very important to only return something, if there are actual cache items
 * found, otherwise it will trip the cache and don't show the data retrieved
 * over the network
 *
 * mapToMultiple apparently does not work reliably, for example, when excludeProjectIds is true
 * it would still return the projectIds defined in filter.projectIds instead of everything else.
 * Not sure how this did work in the cache of v2
 *
 */
function mapMultiple(
  { args, toReference, canRead }: FieldFunctionOptions,
  { typename, path }: { typename: string; path: string }
) {
  if (!args) return

  const ids = get(args, path)

  if (!ids) {
    console.warn(`mapMultiple: Could not find ids: ${typename} to ${path}`)

    if (import.meta.env.DEV) {
      // console.log(args)
      // throw new Error(`mapMultiple: Could not find ids: ${typename} to ${path}`)
    }

    return
  }

  const references: Reference[] = []

  ids.forEach((id: string | number) => {
    const reference = toReference({
      __typename: typename,
      id: id,
    })

    if (!reference) return
    references.push(reference)
  })

  if (!references.length) return

  /**
   * Workaround for a bug, when only partial data on a list is present
   * @see https://github.com/apollographql/apollo-client/issues/9063#issuecomment-1000147426
   *
   * If this is the case, we just return nothing, to force apollo to get
   * the results over the network
   */
  const canReadAllReferences = references.reduce<boolean>(
    (canReadAllReferences, reference) => {
      // It's already over
      if (canReadAllReferences === false) {
        return canReadAllReferences
      }

      const canReadReference = canRead(reference)
      canReadAllReferences = canReadReference ? true : false

      return canReadAllReferences
    },
    true
  )

  if (!canReadAllReferences) return undefined

  return references
}

function mapSingle(
  { args, toReference }: FieldFunctionOptions,
  { typename }: { typename: string }
) {
  if (!args) return

  return toReference({
    __typename: typename,
    id: args.id,
  })
}

/**
 * Configure cache redirects
 * @see: https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
 */
export const cache = new InMemoryCache({
  possibleTypes: generatedIntrospection.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        /**
         * Single
         */
        project: {
          read: (_, params) =>
            mapSingle(params, {
              typename: 'Project',
            }),
        },

        job: {
          read: (_, params) =>
            mapSingle(params, {
              typename: 'Job',
            }),
        },

        jobAppointment: {
          read: (_, params) =>
            mapSingle(params, {
              typename: 'JobAppointment',
            }),
        },

        resourceFunctionAllocation: {
          read: (_, params) =>
            mapSingle(params, {
              typename: 'ResourceFunctionAllocation',
            }),
        },

        resourceAllocation: {
          read: (_, params) =>
            mapSingle(params, {
              typename: 'ResourceAllocation',
            }),
        },

        address: {
          read: (_, params) =>
            mapSingle(params, {
              typename: 'Address',
            }),
        },

        vehicle: {
          read: (_, params) =>
            mapSingle(params, {
              typename: 'Vehicle',
            }),
        },

        stockTypeService: {
          read: (_, params) =>
            mapSingle(params, {
              typename: 'StockTypeService',
            }),
        },

        purchaseOrder: {
          read: (_, params) =>
            mapSingle(params, {
              typename: 'PurchaseOrder',
            }),
        },

        /**
         * Multiple
         */
        // projects: {
        //   read: (_, params) =>
        //     mapMultiple(params, {
        //       typename: 'Project',
        //       path: 'filter.projectIds',
        //     }),
        // },

        resourceFunctionAllocations: {
          read: (_, params) =>
            mapMultiple(params, {
              typename: 'ResourceFunctionAllocation',
              path: 'filter.ids',
            }),
        },

        // resourceAllocations: {
        //   read: (_, params) =>
        //     mapMultiple(params, {
        //       typename: 'ResourceAllocation',
        //       path: 'filter.resourceAllocationIds',
        //     }),
        // },

        addresses: {
          read: (_, params) => {
            /**
             * Only read addresses from cache if there only the filter for
             * addressIds used and nothing else
             */
            if (!params.args?.filter) return

            const hasAddressIdsFilter = Object.prototype.hasOwnProperty.call(
              params.args.filter,
              'addressIds'
            )

            const isOnlyOneFilter =
              Object.keys(params.args?.filter).length === 1

            if ((hasAddressIdsFilter && isOnlyOneFilter) !== true) return

            return mapMultiple(params, {
              typename: 'Address',
              path: 'filter.addressIds',
            })
          },
        },

        vehicles: {
          read: (_, params) => {
            /**
             * Only read addresses from cache if there only the filter for
             * vehicleIds used and nothing else
             */
            if (!params.args?.filter) return

            const hasVehicleIdsFilter = Object.prototype.hasOwnProperty.call(
              params.args.filter,
              'vehicleIds'
            )

            const isOnlyOneFilter =
              Object.keys(params.args?.filter).length === 1

            if ((hasVehicleIdsFilter && isOnlyOneFilter) !== true) return

            return mapMultiple(params, {
              typename: 'Vehicle',
              path: 'filter.vehicleIds',
            })
          },
        },

        /**
         * @todo This and useCachedquery in PmSidebarProjects would save one query
         * Needs more testing though
         */
        // projects: {
        //   read: (_, params) => {
        //     /**
        //      * Only read addresses from cache if there only the filter for
        //      * projectIds used and nothing else
        //      */
        //     if (!params.args?.filter) return

        //     const hasProjectIdsFilter = Object.prototype.hasOwnProperty.call(
        //       params.args.filter,
        //       'projectIds'
        //     )

        //     const isOnlyOneFilter =
        //       Object.keys(params.args?.filter).length === 1

        //     if ((hasProjectIdsFilter && isOnlyOneFilter) !== true) return

        //     return mapMultiple(params, {
        //       typename: 'Project',
        //       path: 'filter.projectIds',
        //     })
        //   },
        // },
      },
    },
  },
})
