<template>
  <PmTableRowPure :class="componentClass.root">
    <template #cell(createdDate)>
      {{ formatToRelativeDate(createdDate) }}
    </template>

    <template #cell(type)>
      {{ typeLabel }}
    </template>

    <template #cell(date)>
      {{ dateNormalized }}
    </template>

    <template #cell(status)>
      <PmStatusPillPure type="leaveRequest" :leave-request-status="status" />
    </template>

    <template #cell(action)>
      <div :class="componentClass.element('action')">
        <PmContextNavigationPure
          :items="[
            {
              id: 'openDetails',
              label: 'Details',
              icon: 'info',
            },
            {
              id: 'abort',
              variant: 'danger',
              label: 'Zurückziehen',
              icon: 'close',
              disabled: props.status === 'aborted',
              visible: props.requestType !== 'expenseReport',
            },
          ]"
          @abort="emit('abort')"
          @open-details="emit('openDetails')"
        />
      </div>
    </template>
  </PmTableRowPure>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import PmTableRowPure from '@/components/basics/PmTable/PmTableRowPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import {
  startEndDateForText,
  formatToRelativeDate,
  formatWithLocale,
} from '@/utilities/date'
import {
  LEAVE_REQUEST_TYPE_LABEL_LOOKUP,
  type LeaveRequestStatus,
  type LeaveRequestType,
  isLeaveRequest,
} from '@/constants/leaveRequest'
import PmContextNavigationPure from '@/components/basics/PmContextNavigation/PmContextNavigationPure.vue'
import { type ExternalServiceRequestStatus } from '@/constants/externalServiceRequest'

export interface Props {
  type?: LeaveRequestType
  startDate?: Date
  endDate?: Date
  date?: Date
  createdDate: Date
  status?: LeaveRequestStatus | ExternalServiceRequestStatus
  requestType: 'leaveRequest' | 'externalServiceRequest' | 'expenseReport'
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  openDetails: []
  abort: []
}>()

const componentClass = useComponentClass()

const typeLabel = computed(() => {
  if (props.type && isLeaveRequest(props.type)) {
    return LEAVE_REQUEST_TYPE_LABEL_LOOKUP[props.type]
  }

  if (props.requestType === 'externalServiceRequest')
    return 'Fremddienstleistung'

  if (props.requestType === 'expenseReport') return 'Spesenabrechnung'

  return 'n/a'
})

const dateNormalized = computed(() => {
  if (props.requestType === 'leaveRequest') {
    if (!props.startDate || !props.endDate)
      throw new Error('startDate or endDate is undefined')

    return startEndDateForText(props.startDate, props.endDate, {
      showTime: false,
    })
  }

  if (props.requestType === 'externalServiceRequest') {
    if (!props.startDate || !props.endDate)
      throw new Error('startDate or endDate is undefined')

    return startEndDateForText(props.startDate, props.endDate)
  }

  if (props.requestType === 'expenseReport') {
    if (!props.date) throw new Error('date is undefined')

    return formatWithLocale(props.date)
  }

  throw new Error(`type "${props.type}" not supported yet`)
})
</script>

<style lang="scss">
.PmMyRequestsListItemPure {
  $block: &;

  &-action {
    padding: 6px;
  }
}
</style>
