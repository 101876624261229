export const EVENT = {
  ACTIONBAR_SET_FILTER: 'Actionbar:setFilter',
  DATA_MODAL_SHOW: 'DataModal:show',
  MODAL_SEND_TO_WEBFLEET_SHOW: 'ModalSendToWebfleet:show',
  PACKING_FINISHED: 'Packing:finished',
  LOGIN_INTERCEPTION_START: 'LoginInterception.start',
  LOGIN_INTERCEPTION_SUCCESS: 'LoginInterception.success',
  UPDATE_SCROLLBAR_DIMENSIONS: 'UpdateScrollbarDimensions',
  SIDEBAR_JOB_SCROLL_INTO_VIEW: 'Sidebar:Job:scrollIntoView',
  SIDEBAR_PROJECT_SCROLL_INTO_VIEW: 'Sidebar:Project:scrollIntoView',
  RESOURCE_ALLOCATION_CLICK: 'ResourceAllocation:Click',
  RESOURCE_ALLOCATION_HIGHLIGHT: 'ResourceAllocation:Highlight',
  RESOURCE_ALLOCATION_CLEAR_HIGHLIGHT: 'ResourceAllocation:ClearHighlight',
  RESOURCE_ALLOCATIONS_CLOSE_DUTY_ORDER_INFO:
    'ResourceAllocations:closeDutyOrderInfo',
  CACHE_CALENDAR_START: 'Cache:Calendar:Start',
  CACHE_CALENDAR_FINISH: 'Cache:Calendar:Finish',
  CACHE_CALENDAR_UPDATE: 'Cache:Calendar:Update',
  LAZYLOAD_APPOINTMENT_ENTER: 'Lazyload:Appointment:Enter',
  LAZYLOAD_APPOINTMENT_LEAVE: 'Lazyload:Appointment:Leave',
} as const
