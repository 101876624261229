<template>
  <div :class="[componentClass.root, classes]">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'

export interface Props {
  padding?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  padding: true,
})

const componentClass = useComponentClass({ props })

const classes = computed(() => {
  return [componentClass.propModifier({ name: 'padding' })]
})
</script>

<style lang="scss">
@use 'sass:color' as sassColor;

.PmSlotPlaceholderPure {
  $block: &;
  $color: #9747ff;

  outline: 2px dashed sassColor.adjust($color, $lightness: 10%, $alpha: -0.5);
  outline-offset: -1px;
  background-color: sassColor.adjust($color, $lightness: 15%, $alpha: -0.8);
  border-radius: 8px;
  color: sassColor.adjust($color, $lightness: 10%, $saturation: 20%);

  &--hasPadding {
    padding: 16px;
  }
}
</style>
