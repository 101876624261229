import { useQuery } from '@vue/apollo-composable'
import { CurrentUserDocument } from '@/../generated/graphql'
import { computed } from 'vue'

export function useCurrentUser() {
  const userQuery = useQuery(CurrentUserDocument)

  const userNormalized = computed(() => {
    const user = userQuery.result.value?.user
    if (!user) return

    return {
      id: user.id,
      address: {
        id: user.address?.id,
      },
    }
  })

  return userNormalized
}
