import { useQuery } from '@vue/apollo-composable'
import { computed, ref } from 'vue'
import { sortBy } from 'lodash-es'

import { ResourceAllocationStatesDocument } from '@/../generated/graphql'
import { STATUS_RESOURCE_ALLOCATION_SORT_ORDER } from '@/constants/persoplan'

/**
 * Loads the resource allocations states from the api
 */
export function useResourceAllocationStates() {
  const resourceAllocationStatesQuery = useQuery(
    ResourceAllocationStatesDocument
  )

  const resourceAllocationStates = computed(
    () => resourceAllocationStatesQuery.result.value?.resourceAllocationStates
  )

  type StatusOption = {
    id: number
    label: string
  }

  type StatusId = (typeof STATUS_RESOURCE_ALLOCATION_SORT_ORDER)[number]

  function isValidStatusId(id: number): id is StatusId {
    return STATUS_RESOURCE_ALLOCATION_SORT_ORDER.includes(id as StatusId)
  }

  const statusOptions = computed(() => {
    if (!resourceAllocationStates.value) return []

    const statusOptions: StatusOption[] = []

    resourceAllocationStates.value.forEach((resourceAllocationState) => {
      if (!resourceAllocationState) return

      const statusOption: StatusOption = {
        id: resourceAllocationState.id,
        label: resourceAllocationState.caption,
      }

      statusOptions.push(statusOption)
    })

    const statusOptionsSorted = sortBy(statusOptions, (statusOption) => {
      if (!isValidStatusId(statusOption.id)) return undefined
      return STATUS_RESOURCE_ALLOCATION_SORT_ORDER.indexOf(statusOption.id)
    })

    return statusOptionsSorted
  })

  return {
    loading: resourceAllocationStatesQuery.loading,
    statusOptions: statusOptions,
  }
}
