<template>
  <PmRequestOverviewDetailsPure
    :is-loading="isLoading"
    :can-be-approved="canBeApproved"
    :start-date="dates?.start"
    :end-date="dates?.end"
    :mode="mode"
    @approve="onApprove"
    @reject="onReject"
  >
    <PmRequestDetail
      v-if="selectedRequestId && selectedRequestType"
      :id="selectedRequestId"
      :type="selectedRequestType"
    />
  </PmRequestOverviewDetailsPure>

  <PmDialogRequestApprovePure
    v-if="
      xstate.snapshot.matches('approve') && xstate.snapshot.context.requestType
    "
    :approval-type="xstate.snapshot.context.approvalType"
    :request-type="xstate.snapshot.context.requestType"
    :initial-form-data="{
      selectedTypeId: currentLeaveRequestQuery.result.value?.leaveRequest?.type,
    }"
    :is-loading="xstate.snapshot.matches({ approve: 'saving' })"
    :error-message="xstate.snapshot.context.error"
    :error-details="xstate.snapshot.context.errorDetails"
    @cancel="xstate.send({ type: 'cancel' })"
    @submit="(variables) => xstate.send({ type: 'confirmApprove', variables })"
  />

  <PmDialogRequestRejectPure
    v-if="
      xstate.snapshot.matches('reject') && xstate.snapshot.context.requestType
    "
    :request-type="xstate.snapshot.context.requestType"
    :is-loading="xstate.snapshot.matches({ reject: 'saving' })"
    :error-message="xstate.snapshot.context.error"
    :error-details="xstate.snapshot.context.errorDetails"
    @cancel="xstate.send({ type: 'cancel' })"
    @submit="(variables) => xstate.send({ type: 'confirmReject', variables })"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { fromPromise } from 'xstate5'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { useStore } from 'vuex'
import type { Get } from 'type-fest'
import { orderBy } from 'lodash-es'

import PmRequestOverviewDetailsPure from '@/components/PmRequestOverview/PmRequestOverviewDetailsPure.vue'
import PmRequestDetail from '@/components/PmRequestDetail/PmRequestDetail.vue'
import { useXState } from '@/composition/useXState5'
import { PmRequestOverviewDetailsState } from '@/components/PmRequestOverview/PmRequestOverviewDetailsState'
import type { Nilable } from '@/types/misc'
import {
  ApproveLeaveRequestDocument,
  type ApproveLeaveRequestMutationVariables,
  LeaveRequestType,
  LeaveRequestDetailsDocument,
  type LeaveRequestDetailsQuery,
  RejectLeaveRequestDocument,
  type RejectLeaveRequestMutationVariables,
  CurrentUserDocument,
  LeaveRequestAction,
  ApproveExternalServiceRequestDocument,
  type ApproveExternalServiceRequestMutationVariables,
  RejectExternalServiceRequestDocument,
  ExternalServiceRequestDetailsDocument,
  type ExternalServiceRequestDetailsQuery,
  ExternalServiceRequestAction,
} from '@/../generated/graphql'
import { throwFriendlyError } from '@/functional/friendlyErrors'
import PmDialogRequestApprovePure, {
  type Emits as EmitsDialogRequestApprovePure,
} from '@/components/PmRequestOverview/PmDialogRequestApprove/PmDialogRequestApprovePure.vue'
import PmDialogRequestRejectPure, {
  type Emits as EmitsDialogRequestRejectPure,
} from '@/components/PmRequestOverview/PmDialogRequestReject/PmDialogRequestRejectPure.vue'
import { parseServerDateString } from '@/utilities/date'

export interface Props {
  selectedRequestId?: Nilable<number>
  selectedRequestType?:
    | 'leaveRequest'
    | 'externalServiceRequest'
    | 'expenseReport'
  mode: 'current' | 'archive'
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'current',
})

const store = useStore()

const xstate = useXState(
  PmRequestOverviewDetailsState.provide({
    actions: {
      showApproveSuccessNotification: () => {
        store.commit('notification/add', {
          variant: 'success',
          title: 'Antrag genehmigt',
        })
      },

      showRejectSuccessNotification: () => {
        store.commit('notification/add', {
          variant: 'success',
          title: 'Antrag abgelehnt',
        })
      },
    },

    actors: {
      approveRequest: fromPromise(async ({ input }) =>
        approveRequest(input.variables)
      ),

      rejectRequest: fromPromise(async ({ input }) =>
        rejectRequest(input.variables)
      ),
    },
  })
)

const currentUserQuery = useQuery(CurrentUserDocument)
const currentUserId = computed(() => currentUserQuery.result.value?.user?.id)

/**
 * This awkwardly loads the data additionally to PmMRequestDetail component
 * but there is not good way to access data needed here otherwise
 * @todo Optimize
 */
const currentLeaveRequestQuery = useQuery(
  LeaveRequestDetailsDocument,
  // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
  () => ({
    id: props.selectedRequestId,
  }),
  () => ({
    enabled:
      props.selectedRequestId !== undefined &&
      props.selectedRequestType === 'leaveRequest',
  })
)

const currentLeaveRequest = computed(
  () => currentLeaveRequestQuery.result.value?.leaveRequest
)

const currentApprovalType = computed(
  () => currentLeaveRequestQuery.result.value?.leaveRequest?.approvalType
)

const isLoading = computed(() => {
  if (props.selectedRequestType === 'leaveRequest')
    return currentLeaveRequestQuery.loading.value

  return false
})

/**
 * This awkwardly loads the data additionally to PmMRequestDetail component
 * but there is not good way to access data needed here otherwise
 * @todo Optimize
 */
const currentExternalServiceRequestQuery = useQuery(
  ExternalServiceRequestDetailsDocument,
  // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
  () => ({
    id: props.selectedRequestId,
  }),
  () => ({
    enabled:
      props.selectedRequestId !== undefined &&
      props.selectedRequestType === 'externalServiceRequest',
  })
)

const currentExternalServiceRequest = computed(
  () => currentExternalServiceRequestQuery.result.value?.externalServiceRequest
)

/**
 * Start-/EndDate of displayed request
 */
const dates = computed(() => {
  if (props.selectedRequestType === 'leaveRequest') {
    if (!currentLeaveRequest.value) return undefined

    return {
      start: parseServerDateString(currentLeaveRequest.value?.startDate),
      end: parseServerDateString(currentLeaveRequest.value?.endDate),
    }
  }

  if (props.selectedRequestType === 'externalServiceRequest') {
    if (!currentExternalServiceRequest.value) return undefined

    return {
      start: parseServerDateString(
        currentExternalServiceRequest.value?.startDate
      ),
      end: parseServerDateString(currentExternalServiceRequest.value?.endDate),
    }
  }

  return undefined
})

/**
 * Approve
 */

/**
 * Check if the leave request can be approved. This is for example the case
 * - when a request has already been approved by the current user, it can't be approved again
 */
const canBeApproved = computed(() => {
  if (
    props.selectedRequestType === 'leaveRequest' &&
    currentLeaveRequest.value
  ) {
    return canLeaveRequestBeApproved(currentLeaveRequest.value)
  }

  if (
    props.selectedRequestType === 'externalServiceRequest' &&
    currentExternalServiceRequest.value
  ) {
    return canExternalServiceRequestBeApproved(
      currentExternalServiceRequest.value
    )
  }

  return true
})

/**
 * If the last action of the current user was `approved` the
 * user should not be able to approve again
 */
function canLeaveRequestBeApproved(
  leaveRequest: Get<LeaveRequestDetailsQuery, 'leaveRequest'>
) {
  if (!currentUserId.value) return false
  if (!leaveRequest?.communications) return true

  const actionsOfUser = leaveRequest.communications?.filter((communication) => {
    return communication.user.id === currentUserId.value
  })
  if (actionsOfUser.length === 0) return true

  const lastActionOfUser = orderBy(actionsOfUser, (communication) =>
    parseServerDateString(communication.timestamp)
  )[0]

  return lastActionOfUser.action !== LeaveRequestAction.accept
}

/**
 * If the last action of the current user was `approved` the
 * user should not be able to approve again
 */
function canExternalServiceRequestBeApproved(
  externalServiceRequest: Get<
    ExternalServiceRequestDetailsQuery,
    'externalServiceRequest'
  >
) {
  if (!currentUserId.value) return false
  if (!externalServiceRequest?.communications) return true

  const actionsOfUser = externalServiceRequest.communications?.filter(
    (communication) => {
      return communication.user.id === currentUserId.value
    }
  )
  if (actionsOfUser.length === 0) return true

  const lastActionOfUser = orderBy(actionsOfUser, (communication) =>
    parseServerDateString(communication.timestamp)
  )[0]

  return lastActionOfUser.action !== ExternalServiceRequestAction.accept
}

const approveLeaveRequestMutation = useMutation(ApproveLeaveRequestDocument)

async function approveLeaveRequest(
  variables: ApproveLeaveRequestMutationVariables
) {
  try {
    await approveLeaveRequestMutation.mutate(variables)
  } catch (error) {
    throwFriendlyError(error)
  }
}

const approveExternalServiceRequestMutation = useMutation(
  ApproveExternalServiceRequestDocument
)

async function approveExternalServiceRequest(
  variables: ApproveExternalServiceRequestMutationVariables
) {
  try {
    await approveExternalServiceRequestMutation.mutate(variables)
  } catch (error) {
    throwFriendlyError(error)
  }
}

function onApprove() {
  if (!props.selectedRequestId)
    throw new Error('selectedRequestId is undefined')
  if (!props.selectedRequestType)
    throw new Error('selectedRequestType is undefined')
  if (props.selectedRequestType === 'expenseReport') return

  xstate.value.send({
    type: 'approve',
    variables: {
      id: props.selectedRequestId,
      type: props.selectedRequestType,
      approvalType: currentApprovalType.value,
    },
  })
}

export type ApproveRequestVariables = {
  id: number
  type: 'leaveRequest' | 'externalServiceRequest'
  formData: EmitsDialogRequestApprovePure['submit']
}

async function approveRequest(options: ApproveRequestVariables) {
  const type = options.formData.type
    ? LeaveRequestType[options.formData.type]
    : undefined

  if (options.type === 'leaveRequest') {
    return approveLeaveRequest({
      id: options.id,
      comment: options.formData.note,
      type,
    })
  }

  if (options.type === 'externalServiceRequest') {
    return approveExternalServiceRequest({
      id: options.id,
      comment: options.formData.note,
    })
  }
}

/**
 * Reject
 */
const rejectLeaveRequestMutation = useMutation(RejectLeaveRequestDocument)

async function rejectLeaveRequest(
  variables: RejectLeaveRequestMutationVariables
) {
  try {
    await rejectLeaveRequestMutation.mutate(variables)
  } catch (error) {
    throwFriendlyError(error)
  }
}

const rejectExternalServiceRequestMutation = useMutation(
  RejectExternalServiceRequestDocument
)

async function rejectExternalServiceRequest(
  variables: RejectLeaveRequestMutationVariables
) {
  try {
    await rejectExternalServiceRequestMutation.mutate(variables)
  } catch (error) {
    throwFriendlyError(error)
  }
}

function onReject() {
  if (!props.selectedRequestId)
    throw new Error('selectedRequestId is undefined')
  if (!props.selectedRequestType)
    throw new Error('selectedRequestType is undefined')
  if (props.selectedRequestType === 'expenseReport') return

  xstate.value.send({
    type: 'reject',
    variables: { id: props.selectedRequestId, type: props.selectedRequestType },
  })
}

export type RejectRequestVariables = {
  id: number
  type: 'leaveRequest' | 'externalServiceRequest'
  formData: EmitsDialogRequestRejectPure['submit']
}

async function rejectRequest(options: RejectRequestVariables) {
  if (options.type === 'leaveRequest') {
    return rejectLeaveRequest({
      id: options.id,
      comment: options.formData.note,
    })
  }

  if (options.type === 'externalServiceRequest') {
    return rejectExternalServiceRequest({
      id: options.id,
      comment: options.formData.note,
    })
  }
}
</script>
