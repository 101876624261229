<template>
  <div class="PmResourceAllocation">
    <PmResourceAllocationPure
      ref="resourceAllocation"
      v-bind="resourceAllocationProps"
      :has-notification-for-details="
        hasNotificationForResourceRequestFeedbackStateUpdated
      "
      :can-edit-resource-request="can('edit', 'resourceRequest')"
      :can-create-webfleet-order="can('create', 'webfleetOrder')"
      :can-enter-select-mode="canEnterSelectMode"
      @edit="edit"
      @show-details="showDetails"
      @click="
        EventHub.$emit(EVENT.RESOURCE_ALLOCATION_CLICK, {
          id: id,
          type: type,
        })
      "
      @highlight-vehicle="highlightVehicle"
      @clear-highlight-vehicle="clearHighlightVehicle"
      @highlight-driver="highlightDriver"
      @clear-highlight-driver="clearHighlightDriver"
      @click-on-duty-order="toggleDutyOrderInfo"
      @jump-to-timeline="jumpToTimeline"
      @context-navigation-open="checkIfJumpToTimelineIsPossble"
      @select="selectionResourceAllocations.add(id, type)"
      @unselect="selectionResourceAllocations.remove(id)"
      @enter-select-mode="enterSelectMode"
      @exit-select-mode="exitSelectMode"
      @open-resource-request="openResourceRequest"
      @create-resource-request="isCreateResourceRequestVisible = true"
      @send-to-webfleet="sendToWebfleet"
    />

    <div ref="popoverElement" class="PmResourceAllocation-popoverElement"></div>

    <PmResourceAllocationEdit
      v-if="$refs.resourceAllocation && editPopoverVisible"
      :id="id"
      :type="type"
      :popover-element="$refs.popoverElement"
      @close="editPopoverVisible = false"
      @create-resource-request="isCreateResourceRequestVisible = true"
    />

    <PmResourceDay
      v-if="dutyOrderInfoVisible"
      :address-id="addressId"
      :vehicle-id="vehicleId"
      :date="dateForResourceDay"
      :type="type"
      :show-all-info="true"
      show-in="floatingBar"
      @close="closeDutyOrderInfo"
    />
  </div>

  <PmMultipleResourceAllocationsRequest
    v-if="isCreateResourceRequestVisible"
    :resource-allocation-ids="[id]"
    @close="isCreateResourceRequestVisible = false"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceAllocation'

export const propTypes = {}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { ref, computed, watch, onBeforeUnmount, inject } from 'vue'
import { isValid, isWithinInterval } from 'date-fns'
import { useStore } from 'vuex'

import { EVENT } from '@/constants/events'
import {
  RESOURCE_ALLOCATION_HIGHLIGHT_REASON,
  STATUS_RESOURCE_ALLOCATION_LOOKUP,
  STATUS_RESOURCE_ALLOCATION,
  RESOURCE_TYPE,
  DATA_MODAL_TYPE,
} from '@/constants/persoplan'

import { getDisplayNameOfAddress } from '@/utilities/string'
import { parseServerDateString } from '@/utilities/date'
import {
  persoplanStateKey,
  injectStrict,
  selectionAppointmentHelperKey,
  selectionProjectOrJobHelperKey,
} from '@/utilities/inject'
import EventHub from '@/eventHub'
import { lookup } from '@/utilities/misc'
import { useCachedQuery } from '@/composition/useCachedQuery'
import { useResourceTimelines } from '@/pinia/resourceTimelines'
import { useSelectionResourceAllocations } from '@/pinia/selectionResourceAllocations'
import { useAppAbility } from '@/composition/useAppAbility'
import notifications from '@/store/notifications/notifications'

import PmResourceAllocationPure, {
  type Props as PropsResourceAllocationPure,
} from '@/components/persoplan/PmResourceAllocation/PmResourceAllocationPure.vue'
import PmResourceAllocationEdit from '@/components/persoplan/PmResourceAllocation/PmResourceAllocationEdit.vue'
import PmResourceDay from '@/components/persoplan/PmResourceDay/PmResourceDay.vue'
import PmMultipleResourceAllocationsRequest from '@/components/persoplan/PmMultipleResourceAllocationsRequest/PmMultipleResourceAllocationsRequest.vue'

import {
  ResourceAllocationDocument,
  ResourceAllocationDetailsDocument,
} from '@/../generated/graphql'

import type { ResourceType } from '@/constants/persoplan'

export interface Props {
  id: number
  type: ResourceType
  editButtonVisible?: boolean // TODO: Rename to `isInEditMode`
  mountInEditMode?: boolean
  containerStartDate?: Date
}

const props = withDefaults(defineProps<Props>(), {})

// const emit = defineEmits<{
// }>()

const persoplanState = injectStrict(persoplanStateKey)
const store = useStore()
const resourceTimelines = useResourceTimelines()
const { can } = useAppAbility()
const selectionResourceAllocations = useSelectionResourceAllocations()
const isCreateResourceRequestVisible = ref(false)

const hasNotificationForResourceRequestFeedbackStateUpdated = computed(() => {
  return notifications.unreadNotificationsForResourceRequestFeedbackStateUpdated.value?.has(
    props.id
  )
})

// Data
const editPopoverVisible = ref(false)
const isHighlightedBecauseVehicleDriverConnection = ref(false)
const isHighlightedBecauseSrollIntoView = ref(false)
const isVehicleHighlighted = ref(false)
const isDriverHighlighted = ref(false)
const dutyOrderInfoVisible = ref(false)

const resourceAllocationQuery = useCachedQuery(
  ResourceAllocationDocument,
  () => {
    return {
      id: props.id,
    }
  }
)

const resourceAllocation = computed(() => {
  return resourceAllocationQuery.result.value?.resourceAllocation
})

const isSkeleton = computed(() => {
  return resourceAllocationQuery.loading.value
})

const resourceAllocationDetailsQuery = useCachedQuery(
  ResourceAllocationDetailsDocument,
  () => ({
    id: props.id,
  })
)

const resourceAllocationDetails = computed(
  () => resourceAllocationDetailsQuery.result.value?.resourceAllocationDetails
)

watch(editPopoverVisible, () => {
  editPopoverVisible.value
    ? store.commit('persoplan/resourceAllocationEditPopoverIsOpen')
    : store.commit('persoplan/resourceAllocationEditPopoverIsClosed')
})

const title = computed(() => {
  if (
    props.type === RESOURCE_TYPE.ADDRESS ||
    props.type === RESOURCE_TYPE.FREELANCER
  ) {
    return titleAddress.value
  }

  if (props.type === RESOURCE_TYPE.VEHICLE) {
    return titleVehicle.value
  }

  console.log('props.id', props.id)
  // throw new Error('type is undefined')
  return undefined
})

const titleAddress = computed(() => {
  let name = getDisplayNameOfAddress(resourceAllocation.value?.address)

  const vehicleName =
    resourceAllocation.value?.resourceAllocationDriver?.vehicle?.caption

  if (vehicleName) {
    name = `${name}@${vehicleName}`
  }

  return name
})

const titleVehicle = computed(() => {
  let name = resourceAllocation.value?.vehicle?.caption ?? undefined

  const driverName = getDisplayNameOfAddress(
    resourceAllocation.value?.resourceAllocationDriver?.address
  )

  if (driverName) {
    name = `${name}@${driverName}`
  }

  return name
})

const status = computed(() => {
  return lookup(
    resourceAllocation.value?.resourceAllocationState?.id,
    STATUS_RESOURCE_ALLOCATION_LOOKUP
  )
})

const hasDriver = computed(() => {
  if (props.type !== RESOURCE_TYPE.VEHICLE) return false
  if (resourceAllocation.value?.resourceAllocationDriver?.address) return true

  return false
})

const isDriver = computed(() => {
  if (
    props.type !== RESOURCE_TYPE.ADDRESS &&
    props.type !== RESOURCE_TYPE.FREELANCER
  ) {
    return false
  }

  if (resourceAllocation.value?.resourceAllocationDriver?.vehicle) return true

  return false
})

const asButton = computed(() => {
  if (persoplanState.state.value.matches('edit.vehicleAllocation')) return true

  if (persoplanState.state.value.matches('edit.driverAllocation')) return true

  return false
})

const isDisabled = computed(() => {
  if (
    props.type === RESOURCE_TYPE.ADDRESS ||
    props.type === RESOURCE_TYPE.FREELANCER
  ) {
    return isDisabledTypeAddress.value
  }

  if (props.type === RESOURCE_TYPE.VEHICLE) return isDisabledTypeVehicle.value

  return false
})

const isDisabledTypeAddress = computed(() => {
  // Wrong allocation type
  if (persoplanState.state.value.matches('edit.vehicleAllocation')) return true

  // Check if same jobID
  if (persoplanState.state.value.matches('edit.driverAllocation')) {
    const jobId = resourceAllocation.value?.resourceFunctionAllocation?.job?.id

    const isSameJob = persoplanState.state.value.context.jobId === jobId
    const isAlreadyAllocated =
      persoplanState.state.value.context.allocatedDriverId ===
      resourceAllocation.value?.id

    if (!isSameJob) return true
    if (isAlreadyAllocated) return true
  }

  return false
})

const isDisabledTypeVehicle = computed(() => {
  // Wrong allocation type
  if (persoplanState.state.value.matches('edit.driverAllocation')) return true

  // Check if same jobID and not already the saved vehicle
  if (persoplanState.state.value.matches('edit.vehicleAllocation')) {
    const jobId = resourceAllocation.value?.resourceFunctionAllocation?.job?.id

    const isSameJob = persoplanState.state.value.context.jobId === jobId

    const isAlreadyAllocated =
      persoplanState.state.value.context.allocatedVehicleId ===
      resourceAllocation.value?.id

    if (!isSameJob) return true
    if (isAlreadyAllocated) return true
  }

  return false
})

const isHighlightedNormalized = computed(() => {
  if (editPopoverVisible.value) return true
  // if (this.dutyOrderInfoVisible) return true
  if (isVehicleHighlighted.value) return false
  if (isDriverHighlighted.value) return false
  if (isHighlightedBecauseVehicleDriverConnection.value) return true
  if (isHighlightedBecauseSrollIntoView.value) return true

  return false
})

const isDutyOrderButtonDisabled = computed(() => {
  if (editPopoverVisible.value) return true
  return false
})

const addressId = computed(() => {
  if (
    props.type !== RESOURCE_TYPE.ADDRESS &&
    props.type !== RESOURCE_TYPE.FREELANCER
  ) {
    return
  }

  return resourceAllocation.value?.address?.id ?? undefined
})

const vehicleId = computed(() => {
  if (props.type !== RESOURCE_TYPE.VEHICLE) return

  return resourceAllocation.value?.vehicle?.id ?? undefined
})

const dateForResourceDay = computed(() => {
  if (!resourceAllocation.value) return

  const startDateStringOfResource =
    resourceAllocation.value?.resourceFunctionAllocation?.startDate

  // Fallback container start date
  if (!startDateStringOfResource) {
    return props.containerStartDate
  }

  const startDateOfResource = parseServerDateString(startDateStringOfResource)
  if (!startDateOfResource) throw new Error('startDateOfResource is undefined')

  /**
   * Fallback container start date is outside of visible timeframe
   */
  const isInVisibleTimeframe = isWithinInterval(startDateOfResource, {
    start: store.state.persoplan.visibleStartDate,
    end: store.state.persoplan.visibleEndDate,
  })

  if (!isInVisibleTimeframe) {
    return props.containerStartDate
  }

  return startDateOfResource
})

const hasResourceRequest = computed<boolean>(() => {
  const id = resourceAllocation.value?.resourceRequest?.id
  if (id) return true
  return false
})

const statusResourceRequestFeedbackNormalized = computed<
  PropsResourceAllocationPure['statusResourceRequestFeedback']
>(() => {
  // No resourceRequest created
  if (!resourceAllocation.value?.resourceRequest) return

  /**
   * In case the resourceRequest was processed by Pro Musik the request is complete
   * and we don't need to show the icon anymore
   */
  if (resourceAllocation.value.resourceRequest.state !== null) return

  const status = resourceAllocation.value?.resourceRequest?.feedback?.userState
  if (status === null) return 'unknown'
  return status
})

const resourceAllocationProps = computed(() => {
  const numberOfOverlappingAllocations =
    resourceAllocationDetails.value?.dutyOrder?.overlappingAllocationIds?.length

  const result: PropsResourceAllocationPure = {
    id: props.id,
    type: props.type,
    title: title.value,
    factor: resourceAllocation.value?.factor ?? undefined,
    note: resourceAllocation.value?.extra?.notice ?? undefined,
    travel: resourceAllocation.value?.travel ?? undefined,
    hotel: resourceAllocation.value?.hotel ?? undefined,
    status: status.value,
    isHighlighted: isHighlightedNormalized.value,
    isInEditMode: props.editButtonVisible,
    userCanEdit: can('edit', 'persoplan'),
    isUiDisabled: store.state.persoplan.resourceAllocationEditPopoverIsOpen,
    isSkeleton: isSkeleton.value,
    asButton: asButton.value,
    disabled: isDisabled.value,
    isDriver: isDriver.value,
    hasDriver: hasDriver.value,
    dutyOrderButtonDisabled: isDutyOrderButtonDisabled.value,
    isJumpToTimelinePossible: isJumpToTimelinePossible.value,
    selected: selectionResourceAllocations.isSelected(props.id),
    canBeSelected: selectionResourceAllocations.canTypeBeSelected(props.type),
    isInSelectionMode: selectionResourceAllocations.isInSelectionMode,
    hasResourceRequest: hasResourceRequest.value,
    statusResourceRequestFeedback:
      statusResourceRequestFeedbackNormalized.value,
    isWebfleetVehicle: resourceAllocation.value?.vehicle?.isWebfleetVehicle,

    // This gets lazyloaded:
    dutyOrder: resourceAllocationDetails.value?.dutyOrder?.order ?? undefined,
    alertOnDutyOrder: numberOfOverlappingAllocations
      ? numberOfOverlappingAllocations > 0
      : false,
  }

  return result
})

function showDetails() {
  EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
    type: DATA_MODAL_TYPE.RESOURCE_ALLOCATION,
    id: props.id,
  })
}

function openResourceRequest() {
  EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
    type: DATA_MODAL_TYPE.RESOURCE_REQUEST,
    id: props.id,
  })
}

function maybeHighlight({ id, reason }: { id: number; reason: string }) {
  if (props.id !== id) return

  if (
    reason === RESOURCE_ALLOCATION_HIGHLIGHT_REASON.VEHICLE_DRIVER_CONNECTION
  ) {
    isHighlightedBecauseVehicleDriverConnection.value = true
  }

  if (reason === RESOURCE_ALLOCATION_HIGHLIGHT_REASON.SCROLL_INTO_VIEW) {
    isHighlightedBecauseSrollIntoView.value = true
  }
}

function clearHighlight({ reason }: { reason: string }) {
  if (
    reason === RESOURCE_ALLOCATION_HIGHLIGHT_REASON.VEHICLE_DRIVER_CONNECTION
  ) {
    isHighlightedBecauseVehicleDriverConnection.value = false
  }

  if (reason === RESOURCE_ALLOCATION_HIGHLIGHT_REASON.SCROLL_INTO_VIEW) {
    isHighlightedBecauseSrollIntoView.value = false
  }
}

function highlightVehicle() {
  const vehicleAllocationId =
    resourceAllocation.value?.resourceAllocationDriver?.id

  if (!vehicleAllocationId) return

  isVehicleHighlighted.value = true

  EventHub.$emit(EVENT.RESOURCE_ALLOCATION_HIGHLIGHT, {
    id: vehicleAllocationId,
    reason: RESOURCE_ALLOCATION_HIGHLIGHT_REASON.VEHICLE_DRIVER_CONNECTION,
  })
}

function clearHighlightVehicle() {
  isVehicleHighlighted.value = false

  EventHub.$emit(EVENT.RESOURCE_ALLOCATION_CLEAR_HIGHLIGHT, {
    reason: RESOURCE_ALLOCATION_HIGHLIGHT_REASON.VEHICLE_DRIVER_CONNECTION,
  })
}

function highlightDriver() {
  const driverAllocationId =
    resourceAllocation.value?.resourceAllocationDriver?.id

  if (!driverAllocationId) return

  isDriverHighlighted.value = true

  EventHub.$emit(EVENT.RESOURCE_ALLOCATION_HIGHLIGHT, {
    id: driverAllocationId,
    reason: RESOURCE_ALLOCATION_HIGHLIGHT_REASON.VEHICLE_DRIVER_CONNECTION,
  })
}

function clearHighlightDriver() {
  isDriverHighlighted.value = false

  EventHub.$emit(EVENT.RESOURCE_ALLOCATION_CLEAR_HIGHLIGHT, {
    reason: RESOURCE_ALLOCATION_HIGHLIGHT_REASON.VEHICLE_DRIVER_CONNECTION,
  })
}

function toggleDutyOrderInfo() {
  if (dutyOrderInfoVisible.value === true) {
    closeDutyOrderInfo()
    return
  }

  if (!isValid(props.containerStartDate)) {
    throw new Error('containerStartDate is not a valid date!')
  }

  showDutyOrderInfo()
}

function showDutyOrderInfo() {
  // Close other open duty order infos
  EventHub.$emit(EVENT.RESOURCE_ALLOCATIONS_CLOSE_DUTY_ORDER_INFO)

  dutyOrderInfoVisible.value = true
}

function closeDutyOrderInfo() {
  dutyOrderInfoVisible.value = false
}

/**
 * Jump to timeline
 */
const isJumpToTimelinePossible = ref<boolean>(false)

function getTimelineId() {
  let id: number | undefined

  if (props.type === 'address') {
    id = addressId.value
  }

  if (props.type === 'vehicle') {
    id = vehicleId.value
  }

  if (!id) {
    throw new Error('id is undefined')
  }

  return id
}

function checkIfJumpToTimelineIsPossble() {
  isJumpToTimelinePossible.value = resourceTimelines.isTimelineVisible({
    id: getTimelineId(),
    type: props.type,
  })
}

function jumpToTimeline() {
  resourceTimelines.scrollIntoView({
    id: getTimelineId(),
    type: props.type,
  })
}

/**
 * Edit
 */
function edit() {
  persoplanState.service.value.send('START_EDIT')
  editPopoverVisible.value = true
}

function enterSelectMode() {
  persoplanState.service.value.send('START_EDIT')
  selectionResourceAllocations.enterSelectionMode()
}

function exitSelectMode() {
  selectionResourceAllocations.exitSelectionMode()
}

/**
 * Add and remove events
 */
EventHub.$on(EVENT.RESOURCE_ALLOCATION_HIGHLIGHT, maybeHighlight)
EventHub.$on(EVENT.RESOURCE_ALLOCATION_CLEAR_HIGHLIGHT, clearHighlight)
EventHub.$on(
  EVENT.RESOURCE_ALLOCATIONS_CLOSE_DUTY_ORDER_INFO,
  closeDutyOrderInfo
)

onBeforeUnmount(() => {
  /**
   * We need to set this status on unmounting, in case the resource allocation is deleted
   * In this case the edit popover is never closed and the status won't get updated
   */
  if (props.editButtonVisible) {
    store.commit('persoplan/resourceAllocationEditPopoverIsClosed')
  }

  EventHub.$off(EVENT.RESOURCE_ALLOCATION_HIGHLIGHT, maybeHighlight)
  EventHub.$off(EVENT.RESOURCE_ALLOCATION_CLEAR_HIGHLIGHT, clearHighlight)
  EventHub.$off(
    EVENT.RESOURCE_ALLOCATIONS_CLOSE_DUTY_ORDER_INFO,
    closeDutyOrderInfo
  )
})

if (props.mountInEditMode) {
  editPopoverVisible.value = true
}

/**
 * Selection by project/job/appointment
 */
const selectionAppointmentHelper = inject(selectionAppointmentHelperKey)
if (selectionAppointmentHelper) {
  watch(selectionAppointmentHelper.selectionTrigger, selectIfConsidered)
}

const selectionProjectOrJobHelper = inject(selectionProjectOrJobHelperKey)
if (selectionProjectOrJobHelper) {
  watch(selectionProjectOrJobHelper.selectionTrigger, selectIfConsidered)
}

function selectIfConsidered() {
  if (props.type !== RESOURCE_TYPE.ADDRESS) return
  if (status.value !== STATUS_RESOURCE_ALLOCATION.CONSIDERED) return

  console.log('select if considered', props.id)
  enterSelectMode()
  selectionResourceAllocations.add(props.id, props.type)
}

/**
 * Send to webfleet
 */
function sendToWebfleet() {
  EventHub.$emit(EVENT.MODAL_SEND_TO_WEBFLEET_SHOW, {
    resourceAllocationId: props.id,
  })
}

/**
 * Determine if selection mode for multiple resource allocations
 * can be entered
 */
const canEnterSelectMode = computed(() => {
  if (store.state.persoplan.resourceSelectionType !== undefined) return false
  return true
})
</script>

<style lang="scss">
.PmResourceAllocation {
  $block: &;

  position: relative;

  &-popoverElement {
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
