<template>
  <PmTablePure :columns="columns" :is-loading="isLoading">
    <PmMyRequestsListItemPure
      v-for="item in sortedTableData.data.value"
      :key="JSON.stringify(item)"
      v-bind="item"
      @open-details="
        emit('openDetails', { requestType: item.requestType, id: item.id })
      "
      @abort="onAbort({ requestType: item.requestType, id: item.id })"
    />
  </PmTablePure>
</template>

<script setup lang="ts">
import { toRef } from 'vue'
import PmTablePure, {
  type TableColumns,
} from '@/components/basics/PmTable/PmTablePure.vue'
import PmMyRequestsListItemPure, {
  type Props as PropsMyRequestsListItemPure,
} from '@/components/PmMyRequests/PmMyRequestsListItemPure.vue'
import { useSortedTableData } from '@/components/basics/PmTable/useSortedTableData'

export interface Props {
  requests?: (PropsMyRequestsListItemPure & { id: number })[]
  isLoading?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  openDetails: {
    requestType: PropsMyRequestsListItemPure['requestType']
    id: number
  }
  abort: {
    requestType: Exclude<
      PropsMyRequestsListItemPure['requestType'],
      'expenseReport'
    >
    id: number
  }
}

const emit = defineEmits<{
  openDetails: [Emits['openDetails']]
  abort: [Emits['abort']]
}>()

const columns: TableColumns = [
  {
    label: 'Erstellt',
    id: 'createdDate',
  },
  { label: 'Typ', id: 'type' },
  { label: 'Datum', id: 'date' },
  { label: 'Status', id: 'status' },
  { id: 'action', width: 'auto', padding: false },
]

const sortedTableData = useSortedTableData({
  data: toRef(props, 'requests'),
})

sortedTableData.updateSortOptions({
  id: 'createdDate',
  direction: 'descending',
})

function onAbort(options: {
  requestType: PropsMyRequestsListItemPure['requestType']
  id: number
}) {
  if (options.requestType === 'expenseReport') return
  emit('abort', { requestType: options.requestType, id: options.id })
}
</script>
