<template>
  <div :class="classes">
    <dt :class="componentClass.element('label')">{{ label }}:</dt>
    <dd :class="componentClass.element('content')">
      <div :class="componentClass.element('contentInner')">
        <slot>{{ value }} </slot>
      </div>

      <div v-if="action" :class="componentClass.element('action')">
        <PmButtonPure v-bind="action" @click="onClick" />
      </div>
    </dd>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import { keyValueListPureKey, injectStrict } from '@/utilities/inject'

import PmButtonPure, {
  type Props as PropsButtonPure,
} from '@/components/basics/PmButtonPure.vue'

export interface Props {
  label: string
  value?: string | number
  hasNotification?: boolean
  variant?: 'debug'
  action?: {
    id: string
  } & Pick<
    PropsButtonPure,
    'label' | 'icon' | 'iconAfter' | 'notification' | 'variant'
  >
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const keyValueListPure = injectStrict(keyValueListPureKey)

const componentClass = useComponentClass()

const classes = computed(() => {
  return [
    componentClass.root,
    componentClass.propModifier({
      name: 'appearance',
      value: keyValueListPure.appearance.value,
    }),
    {
      [componentClass.modifier('debug')]: props.variant === 'debug',
    },
  ]
})

function onClick() {
  // @ts-expect-error Impossilbe to type
  emit(props.action.id)
}
</script>

<style lang="scss">
.PmKeyValuePure {
  $block: &;
  $parent: '.PmKeyValueListPure';

  $borderRadius: constant.$borderRadius-default;

  @include cssVar.define($block, 'padding', cssVar.use($parent, 'padding'));
  @include cssVar.define(
    $block,
    'colorBorder',
    cssVar.use($parent, 'colorBorder')
  );
  @include cssVar.define(
    $block,
    'colorBorderInline',
    cssVar.use($parent, 'colorBorderInline')
  );
  @include cssVar.define(
    $block,
    'borderRadius',
    cssVar.use($parent, 'borderRadius')
  );
  @include cssVar.define($block, 'colorBackground', inherit);
  @include cssVar.define($block, 'colorBackground--hover', color.$gray-50);
  @include cssVar.define($block, 'colorText', inherit);
  @include cssVar.define($block, 'colorOutline', transparent);

  display: grid;
  grid-column: -1 / 1;
  grid-template-columns: subgrid;
  vertical-align: middle;
  color: cssVar.use($block, 'colorText');

  &--appearanceDefault {
    outline: 2px solid cssVar.use($block, 'colorOutline');
    outline-offset: -1px;
    background-color: cssVar.use($block, 'colorBackground');
  }

  &--debug {
    color: color.$gray-400;
  }

  &:not(:last-child) {
    &#{$block}--appearanceDefault {
      border-bottom: 1px solid cssVar.use($block, 'colorBorderInline');
    }
  }

  &:first-child {
    &#{$block}--appearanceDefault {
      border-top-left-radius: cssVar.use($block, 'borderRadius');
      border-top-right-radius: cssVar.use($block, 'borderRadius');
    }
  }

  &:last-child {
    &#{$block}--appearanceDefault {
      border-bottom-left-radius: cssVar.use($block, 'borderRadius');
      border-bottom-right-radius: cssVar.use($block, 'borderRadius');
    }
  }

  &:hover {
    &#{$block}--appearanceDefault {
      background-color: cssVar.use($block, 'colorBackground--hover');
    }
  }

  &.is-disabled {
    @include cssVar.define($block, 'colorText', color.$text-light);

    pointer-events: none;

    // Give non-text contents opacity of 0.5
    // stylelint-disable-next-line plugin/selector-bem-pattern
    & > * {
      opacity: 0.5;
    }
  }

  &-label {
    font-weight: bold;
    max-width: 25ch;

    #{$block}--appearanceDefault > & {
      padding: cssVar.use($block, 'padding');
    }
  }

  &-content {
    display: flex;
    min-width: 0;
    container-type: inline-size;
  }

  &-contentInner {
    width: 100%;

    #{$block}--appearanceDefault > #{$block}-content > & {
      padding: cssVar.use($block, 'padding');
    }

    #{$block}--appearanceNaked > #{$block}-content > & {
      padding-left: cssVar.use($block, 'padding');
    }
  }

  &-action {
    margin-left: auto;
    position: relative;
    display: flex;
    align-items: center;
    // padding-right: 8px;
    padding-right: 4px;
  }

  &-notification {
    $size: 8px;

    background-color: color.$danger-500;
    width: $size;
    height: $size;
    border-radius: $size;
  }
}
</style>
