<template>
  <PmTableRowPure
    :class="componentClass.root"
    :active="selected"
    @click="onClick"
  >
    <template #cell(createdDate)>
      {{ formatToRelativeDate(createdDate) }}
    </template>

    <template #cell(name)> {{ name }}</template>

    <template #cell(type)>
      <template v-if="props.requestType === 'leaveRequest' && props.type">
        {{ LEAVE_REQUEST_TYPE_LABEL_LOOKUP[props.type] }}
      </template>

      <template v-if="props.requestType === 'externalServiceRequest'">
        Fremddienstleistung
      </template>

      <template v-if="props.requestType === 'expenseReport'">
        Spesenabrechnung
      </template>
    </template>

    <template #cell(date)>
      {{ dateNormalized }}
    </template>

    <template #cell(status)>
      <PmStatusPillPure type="leaveRequest" :leave-request-status="status" />
    </template>
  </PmTableRowPure>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'

import {
  type LeaveRequestType,
  LEAVE_REQUEST_TYPE_LABEL_LOOKUP,
  type LeaveRequestStatus,
} from '@/constants/leaveRequest'
import {
  startEndDateForText,
  formatToRelativeDate,
  formatWithLocale,
} from '@/utilities/date'
import PmTableRowPure from '@/components/basics/PmTable/PmTableRowPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import type { Nilable } from '@/types/misc'
import type { ExternalServiceRequestStatus } from '@/constants/externalServiceRequest'

export interface Props {
  id: number
  selected?: boolean
  name?: Nilable<string>
  type?: LeaveRequestType
  requestType: 'leaveRequest' | 'externalServiceRequest' | 'expenseReport'
  status?: LeaveRequestStatus | ExternalServiceRequestStatus
  createdDate: Date
  date:
    | {
        start: Date
        end: Date
      }
    | Date
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'click'): void
  (event: 'select'): void
  (event: 'unselect'): void
}>()

const componentClass = useComponentClass()

function onClick() {
  props.selected ? emit('unselect') : emit('select')
}

const dateNormalized = computed(() => {
  if (props.requestType === 'expenseReport') {
    if (!(props.date instanceof Date))
      throw new Error('props.date is not a Date')

    return formatWithLocale(props.date)
  }

  if (!('start' in props.date) || !('end' in props.date)) {
    throw new Error('props.date are invalid')
  }

  if (props.requestType === 'leaveRequest') {
    return startEndDateForText(props.date.start, props.date.end, {
      showTime: false,
    })
  }

  if (props.requestType === 'externalServiceRequest') {
    return startEndDateForText(props.date.start, props.date.end)
  }

  return undefined
})
</script>

<style lang="scss">
.PmRequestOverviewItemPure {
  $block: &;
  $parent: '.PmTablePure';

  @include cssVar.define(
    $block,
    'cellPadding',
    cssVar.use($parent, 'cellPadding')
  );

  &-contextNavigation {
    display: flex;
    height: 100%;
    padding-inline: cssVar.use($block, 'cellPadding');
    padding-top: calc(cssVar.use($block, 'cellPadding') - 1px);
  }

  &-note {
    display: flex;
    height: 100%;
  }
}
</style>
