<template>
  <div class="PmResourceRequestOverviewListPure">
    <PmTablePure
      :columns="columns"
      :sortable="true"
      @update-sorting="(event) => emit('updateSorting', event)"
    >
      <slot name="table" />
    </PmTablePure>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResourceRequestOverviewListPure'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})

export const columns: TableColumns = [
  { id: 'selected', width: 'auto' },
  // { id: 'id', label: 'Id', width: 'auto' },
  { id: 'statusFeedback', label: 'Status Feedback' },
  { id: 'status', label: 'Status' },
  {
    id: 'resourceFunctions',
    label: 'Ressourcenfunktion',
    sort: {
      property: 'label',
    },
  },
  {
    id: 'appointments',
    label: 'Termin',
    sort: {
      property: 'name',
    },
  },
  { id: 'name', label: 'Name' },
  { id: 'createdBy', label: 'Erstellt von' },
  { id: 'updated', label: 'Aktualisiert' },
]
</script>

<script setup lang="ts">
import PmTablePure, {
  type TableColumns,
  type Emit as EmitTablePure,
} from '@/components/basics/PmTable/PmTablePure.vue'

export interface Props {
  myProp?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'updateSorting', payload: EmitTablePure['updateSorting']): void
}>()
</script>

<style lang="scss">
.PmResourceRequestOverviewListPure {
  $block: &;

  user-select: none;
}
</style>
