import * as v from 'valibot'
import { DRAG_AND_DROP_TYPE } from '@/constants/persoplan'

export const dataCreateResourceAllocationAddress = v.object({
  type: v.literal(DRAG_AND_DROP_TYPE.CREATE_RESOURCE_ALLOCATION_ADDRESS),
  data: v.object({
    id: v.number(),
    resourceType: v.literal('address'),
  }),
})

export type DataCreateResourceAllocationAddress = v.InferOutput<
  typeof dataCreateResourceAllocationAddress
>

export const dataCreateResourceAllocationVehicle = v.object({
  type: v.literal(DRAG_AND_DROP_TYPE.CREATE_RESOURCE_ALLOCATION_VEHICLE),
  data: v.object({
    id: v.number(),
    resourceType: v.literal('vehicle'),
  }),
})

export type DataCreateResourceAllocationVehicle = v.InferOutput<
  typeof dataCreateResourceAllocationVehicle
>
