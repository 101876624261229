<template>
  <div :class="componentClass.root">
    <PmLoadingPure v-if="isLoading" size="default" />

    <template v-if="!isLoading">
      <div :class="componentClass.element('content')">
        <PmKeyValueListPure>
          <PmKeyValuePure v-if="IS_DEVELOPMENT" label="Id" variant="debug">
            {{ id }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Bezeichnung">
            {{ name }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Nummer">
            <PmPillPure :label="number" />
          </PmKeyValuePure>

          <PmKeyValuePure label="Status">
            <PmStatusPillPure
              v-if="status"
              type="project"
              :project-status="status.status"
              :label="status.label"
            />
          </PmKeyValuePure>

          <PmKeyValuePure label="Kunde">
            <PmAddressPure :address="customer" />
          </PmKeyValuePure>

          <PmKeyValuePure label="Ansprechpartner">
            <PmAddressPure :address="contact" />
          </PmKeyValuePure>

          <PmKeyValuePure label="Typ">
            {{ type }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Start/Ende">
            <template v-if="props.startDate && props.endDate">
              {{ startEndDateForText(props.startDate, props.endDate) }}
            </template>
          </PmKeyValuePure>

          <PmKeyValuePure label="Erstellt">
            <template v-if="props.createdDate && props.createdBy">
              {{ formatToRelativeDate(props.createdDate) }}
              von {{ props.createdBy }}
            </template>
          </PmKeyValuePure>

          <PmKeyValuePure label="Zuletzt geändert">
            <template v-if="props.editedDate && props.editedBy">
              {{ formatToRelativeDate(props.editedDate) }}
              von {{ props.editedBy }}
            </template>
          </PmKeyValuePure>

          <PmKeyValuePure label="Projektleiter">
            {{ projectLeader }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Priorität">
            {{ priority }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Jobs">
            <ul
              v-if="allJobs?.length"
              :class="componentClass.element('allJobsList')"
            >
              <li
                v-for="job in allJobs"
                :key="job.id"
                :class="componentClass.element('allJobsListItem')"
              >
                <PmStatusPillPure
                  type="job"
                  :label="job.number"
                  :job-status="job.status"
                />

                <PmLinkPure
                  :label="job.title"
                  @click="emit('openJob', job.id)"
                />
              </li>
            </ul>
          </PmKeyValuePure>
        </PmKeyValueListPure>

        <div :class="componentClass.element('note')">
          <slot name="note" />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useComponentClass } from '@thomasaull-shared/composables'

import { IS_DEVELOPMENT } from '@/constants/general'
import { startEndDateForText, formatToRelativeDate } from '@/utilities/date'

import PmKeyValueListPure from '@/components/basics/PmKeyValueList/PmKeyValueListPure.vue'
import PmKeyValuePure from '@/components/basics/PmKeyValueList/PmKeyValuePure.vue'
import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import PmLinkPure from '@/components/basics/PmLink/PmLinkPure.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import PmAddressPure, {
  type Props as PropsAddressPure,
} from '@/components/basics/PmAddress/PmAddressPure.vue'

import type { Nilable } from '@/types/misc'
import type { ProjectStatus, JobStatus } from '@/constants/persoplan'

export interface Props {
  id: number
  isLoading?: boolean
  number?: Nilable<string>
  name?: Nilable<string>
  status?: Nilable<Status>
  customer?: PropsAddressPure['address']
  contact?: PropsAddressPure['address']
  type?: Nilable<string>
  startDate?: Nilable<Date>
  endDate?: Nilable<Date>
  createdDate?: Nilable<Date>
  createdBy?: Nilable<string>
  editedDate?: Nilable<Date>
  editedBy?: Nilable<string>
  projectLeader?: Nilable<string>
  // event?: // Was macht das
  priority?: Nilable<string>
  allJobs?: Job[]
  note?: Nilable<string>
}

export type Status = {
  status: ProjectStatus
  label: string
}

export type Job = {
  id: number
  number: Nilable<string>
  title: Nilable<string>
  status: Nilable<JobStatus>
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'openJob', id: number): void
}>()

const componentClass = useComponentClass()
</script>

<style lang="scss">
.PmDataModalProjectPure {
  $block: &;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-allJobsList {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-allJobsListItem {
    display: flex;
    gap: 8px;
  }
}
</style>
