<template>
  <PmTableRowPure class="PmMultipleResourceAllocationsUpdateItemPure">
    <template #cell(status)>
      <PmBeforeAfterPure v-if="statusBefore">
        <template #before>
          <PmStatusDotPure
            type="resourceAllocation"
            :resource-allocation-status="statusBefore?.status"
          />

          {{ statusBefore?.label }}
        </template>

        <template v-if="statusAfter" #after>
          <PmStatusDotPure
            type="resourceAllocation"
            :resource-allocation-status="statusAfter?.status"
          />

          {{ statusAfter?.label }}
        </template>
      </PmBeforeAfterPure>
    </template>

    <template #cell(note)>
      <PmBeforeAfterPure>
        <template v-if="noteBefore" #before>
          {{ noteBefore }}
        </template>

        <template
          v-if="
            isFilled({
              valueBefore: noteBefore,
              valueAfter: noteAfter,
              overwrite: overwriteNote,
            })
          "
          #after
        >
          {{ noteAfter ?? '—' }}
        </template>
      </PmBeforeAfterPure>
    </template>

    <template #cell(travel)>
      <PmBeforeAfterPure>
        <template v-if="travelBefore" #before>
          {{ normalizeNoteWithStatus(travelBefore) }}
        </template>

        <template
          v-if="
            isFilled({
              valueBefore: travelBefore,
              valueAfter: travelAfter,
              overwrite: overwriteTravel,
            })
          "
          #after
        >
          {{ normalizeNoteWithStatus(travelAfter) ?? '—' }}
        </template>
      </PmBeforeAfterPure>
    </template>

    <template #cell(hotel)>
      <PmBeforeAfterPure>
        <template v-if="hotelBefore" #before>
          {{ normalizeNoteWithStatus(hotelBefore) }}
        </template>

        <template
          v-if="
            isFilled({
              valueBefore: hotelBefore,
              valueAfter: hotelAfter,
              overwrite: overwriteHotel,
            })
          "
          #after
        >
          {{ normalizeNoteWithStatus(hotelAfter) ?? '—' }}
        </template>
      </PmBeforeAfterPure>
    </template>

    <template #cell(state)>
      <PmLoadingPure v-if="state === 'updating'" size="small" />

      <PmIconPure
        v-if="state === 'error'"
        :name="ICONS.ALERT"
        class="PmMultipleResourceAllocationsUpdateItemPure-statusIcon is-error"
      />

      <PmIconPure
        v-if="state === 'success'"
        :name="ICONS.CHECK"
        class="PmMultipleResourceAllocationsUpdateItemPure-statusIcon is-success"
      />
    </template>
  </PmTableRowPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { lookup } from '@/utilities/misc'

const COMPONENT_NAME = 'PmMultipleResourceAllocationsUpdateItemPure'

export const propTypes = {
  state: {
    allowed: ['updating', 'error', 'success'],
  },
  status: {
    allowed: Object.values(STATUS_RESOURCE_ALLOCATION),
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { ICONS } from '@/constants/icons'
import {
  type StatusIdTravelAndHotel,
  type ResourceAllocationStatus,
  STATUS_RESOURCE_ALLOCATION,
  STATUS_TRAVEL_AND_HOTEL_LOOKUP,
} from '@/constants/persoplan'

import { extractStatusAndValueFromString } from '@/utilities/string'

import PmTableRowPure from '@/components/basics/PmTable/PmTableRowPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import PmStatusDotPure from '@/components/persoplan/PmStatusDot/PmStatusDotPure.vue'
import PmBeforeAfterPure from '@/components/basics/PmBeforeAfter/PmBeforeAfterPure.vue'

export interface Status {
  status?: ResourceAllocationStatus
  label?: string
}

export interface NoteWithStatus {
  note?: string
  statusId?: StatusIdTravelAndHotel
}

export interface Props {
  state?: (typeof propTypes.state.allowed)[number]
  statusBefore?: Status
  statusAfter?: Status
  noteBefore?: string
  noteAfter?: string | null
  overwriteNote: boolean
  travelBefore?: string
  travelAfter?: string | null
  overwriteTravel: boolean
  hotelBefore?: string
  hotelAfter?: string | null
  overwriteHotel: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

function normalizeNoteWithStatus(value: string | undefined | null) {
  if (!value) return

  const extracted = extractStatusAndValueFromString(value)
  if (!extracted) return

  const status = lookup(extracted.statusId, STATUS_TRAVEL_AND_HOTEL_LOOKUP)

  if (status === undefined) {
    return extracted.valueWithoutStatus
  }

  return `${extracted.valueWithoutStatus} (${status})`
}

function isFilled({
  valueBefore,
  valueAfter,
  overwrite,
}: {
  valueBefore?: string | null | undefined
  valueAfter?: string | null | undefined
  overwrite: boolean
}) {
  if (valueBefore && overwrite === false) {
    return false
  }

  if (valueAfter === null) return true
  if (!valueAfter) return false

  return valueAfter ? true : false
}
</script>

<style lang="scss">
.PmMultipleResourceAllocationsUpdateItemPure {
  $block: &;

  &-statusIcon {
    width: 20px;
    height: 20px;

    &.is-error {
      color: color.$danger-600;
    }

    &.is-success {
      color: color.$success-600;
    }
  }
}
</style>
