<template>
  <div :class="componentClass.root">
    <PmKeyValueListPure :is-loading="isLoading">
      <template v-if="details">
        <PmKeyValuePure v-if="IS_DEVELOPMENT" label="Id" variant="debug">
          {{ id }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Typ">
          <template v-if="type === 'leaveRequest' && details.type">
            {{ LEAVE_REQUEST_TYPE_LABEL_LOOKUP[details.type] }}
          </template>

          <template v-if="type === 'externalServiceRequest'">
            Fremddienstleistung
          </template>
        </PmKeyValuePure>

        <PmKeyValuePure label="Name">
          {{ details.name }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Datum">
          {{
            startEndDateForText(details.startDate, details.endDate, {
              showTime: showTime,
            })
          }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Status">
          <PmStatusPillPure
            type="leaveRequest"
            :leave-request-status="details.status"
          />
        </PmKeyValuePure>

        <PmKeyValuePure label="Verlauf">
          <PmTimelinePure>
            <PmTimelineItemPure
              v-for="item in details.communication"
              :key="`${item.status}.${item.date.getTime()}`"
              :date="item.date"
              :label="item.label"
              :additional-meta="item.additionalMeta"
            >
              <template #header>
                <template v-if="item.status">
                  <PmStatusPillPure
                    type="leaveRequest"
                    :leave-request-status="item.status"
                  />
                </template>
              </template>

              <div
                v-if="hasItemContent(item)"
                :class="componentClass.element('timelineItemContent')"
              >
                <template
                  v-if="
                    type === 'leaveRequest' &&
                    item.typeBefore &&
                    item.typeAfter &&
                    hasItemChangedType(item)
                  "
                >
                  <div :class="componentClass.element('leaveRequestStatus')">
                    <PmFromToPure>
                      <template v-if="item.typeBefore" #from>
                        <PmPillPure
                          :label="
                            LEAVE_REQUEST_TYPE_LABEL_LOOKUP[item.typeBefore]
                          "
                        />
                      </template>

                      <template #to>
                        <PmPillPure
                          :label="
                            LEAVE_REQUEST_TYPE_LABEL_LOOKUP[item.typeAfter]
                          "
                        />
                      </template>
                    </PmFromToPure>
                  </div>
                </template>

                <PmNotePure v-if="item.note" :note="item.note" />
              </div>
            </PmTimelineItemPure>
          </PmTimelinePure>
        </PmKeyValuePure>
      </template>
    </PmKeyValueListPure>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import type { Get } from 'type-fest'

import { startEndDateForText } from '@/utilities/date'
import { IS_DEVELOPMENT } from '@/constants/general'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import PmKeyValueListPure from '@/components/basics/PmKeyValueList/PmKeyValueListPure.vue'
import PmKeyValuePure from '@/components/basics/PmKeyValueList/PmKeyValuePure.vue'
import PmTimelinePure from '@/components/basics/PmTimeline/PmTimelinePure.vue'
import PmTimelineItemPure, {
  type Props as PropsTimelineItemPure,
} from '@/components/basics/PmTimeline/PmTimelineItemPure.vue'
import {
  type LeaveRequestType,
  LEAVE_REQUEST_TYPE_LABEL_LOOKUP,
  type LeaveRequestStatus,
} from '@/constants/leaveRequest'
import PmNotePure from '@/components/PmNote/PmNotePure.vue'
import type { Nilable } from '@/types/misc'
import type { ExternalServiceRequestStatus } from '@/constants/externalServiceRequest'
import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmFromToPure from '@/components/persoplan/PmFromTo/PmFromToPure.vue'

export interface Props {
  id: number
  isLoading?: boolean
  type: 'leaveRequest' | 'externalServiceRequest'
  details?: {
    type?: LeaveRequestType
    name: Nilable<string>
    startDate: Date
    endDate: Date
    status?: LeaveRequestStatus
    communication?: {
      date: Date
      label?: string
      status?: LeaveRequestStatus | ExternalServiceRequestStatus
      typeBefore?: LeaveRequestType
      typeAfter?: LeaveRequestType
      note?: Nilable<string>
      additionalMeta?: PropsTimelineItemPure['additionalMeta']
    }[]
  }
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  close: []
}>()

const componentClass = useComponentClass()

const showTime = computed(() => {
  if (props.type === 'externalServiceRequest') return true
  return false
})

type Item = NonNullable<Get<Props, 'details.communication[0]'>>

function hasItemChangedType(item: Item) {
  if (!item.typeBefore) return false
  if (!item.typeAfter) return false
  const hasChangedType = item.typeBefore !== item.typeAfter
  return hasChangedType
}

function hasItemContent(item: Item) {
  if (item.note) return true
  if (hasItemChangedType(item)) return true

  return false
}
</script>

<style lang="scss">
.PmRequestDetailPure {
  $block: &;

  &-timelineItemContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
