<template>
  <div class="PmDataModalJobPure">
    <PmLoadingPure v-if="isLoading" size="default" />

    <template v-if="!isLoading">
      <div class="PmDataModalJobPure-content">
        <PmKeyValueListPure>
          <PmKeyValuePure v-if="IS_DEVELOPMENT" label="Id" variant="debug">
            {{ id }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Bezeichnung">
            {{ name }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Nummer">
            <PmPillPure :label="number" />
          </PmKeyValuePure>

          <PmKeyValuePure label="Status">
            <PmStatusPillPure
              v-if="status"
              type="job"
              :job-status="status.status"
              :label="status.label"
            />
          </PmKeyValuePure>

          <PmKeyValuePure label="Start/Ende">
            <template v-if="props.startDate && props.endDate">
              {{ startEndDateForText(props.startDate, props.endDate) }}
            </template>
          </PmKeyValuePure>

          <PmKeyValuePure label="Erstellt">
            <template v-if="props.createdDate && props.createdBy">
              {{ formatToRelativeDate(props.createdDate) }}
              von {{ props.createdBy }}
            </template>
          </PmKeyValuePure>

          <PmKeyValuePure label="Zuletzt geändert">
            <template v-if="props.editedDate && props.editedBy">
              {{ formatToRelativeDate(props.editedDate) }}
              von {{ props.editedBy }}
            </template>
          </PmKeyValuePure>

          <PmKeyValuePure label="Jobleiter">
            <PmAddressPure :address="jobLeader" />
          </PmKeyValuePure>

          <PmKeyValuePure label="Service">
            {{ service }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Veranstaltungsort">
            <PmAddressPure :address="location">
              <PmKeyValuePure v-if="locationNote" label="Zusatztext">
                {{ locationNote }}
              </PmKeyValuePure>
            </PmAddressPure>
          </PmKeyValuePure>

          <PmKeyValuePure label="Ansprechpartner">
            <PmAddressPure :address="contact" />
          </PmKeyValuePure>

          <PmKeyValuePure label="Kunde">
            <PmAddressPure :address="customer" />
          </PmKeyValuePure>

          <PmKeyValuePure label="Projekt">
            <div
              v-if="project?.id && project?.status && project?.number"
              class="PmDataModalJobPure-project"
            >
              <PmStatusPillPure
                type="project"
                :label="project.number"
                :project-status="project.status"
              />

              <PmLinkPure
                :label="project.label"
                @click="
                  project?.id ? emit('openProject', project.id) : undefined
                "
              />
            </div>
          </PmKeyValuePure>

          <PmKeyValuePure label="Alle Jobs des Projekts">
            <ul v-if="allJobs?.length" class="PmDataModalJobPure-allJobsList">
              <li
                v-for="job in allJobs"
                :key="job.id"
                class="PmDataModalJobPure-allJobsListItem"
              >
                <PmStatusPillPure
                  type="job"
                  :label="job.number"
                  :job-status="job.status"
                />

                <PmLinkPure
                  :label="job.title"
                  @click="emit('openJob', job.id)"
                />
              </li>
            </ul>
          </PmKeyValuePure>

          <PmKeyValuePure label="Ressourcen ohne Termin">
            <PmRichTextPure v-if="resourcesWithoutAppointment?.length">
              <ul>
                <li
                  v-for="resource in resourcesWithoutAppointment"
                  :key="resource.id"
                >
                  {{ resource.title }}

                  <template v-if="resource.startDate && resource.endDate">
                    {{
                      startEndDateForText(resource.startDate, resource.endDate)
                    }}
                  </template>
                </li>
              </ul>
            </PmRichTextPure>
          </PmKeyValuePure>
        </PmKeyValueListPure>

        <div class="PmDataModalJobPure-note">
          <slot name="note" />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'DataModalJobPure'

export const propTypes = {
  type: {
    allowed: [RESOURCE_TYPE.ADDRESS, RESOURCE_TYPE.VEHICLE],
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import {
  type ProjectStatus,
  type JobStatus,
  RESOURCE_TYPE,
} from '@/constants/persoplan'
import { IS_DEVELOPMENT } from '@/constants/general'

import { startEndDateForText, formatToRelativeDate } from '@/utilities/date'

import PmRichTextPure from '@/components/basics/PmRichText/PmRichTextPure.vue'
import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmLinkPure from '@/components/basics/PmLink/PmLinkPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import PmKeyValueListPure from '@/components/basics/PmKeyValueList/PmKeyValueListPure.vue'
import PmKeyValuePure from '@/components/basics/PmKeyValueList/PmKeyValuePure.vue'
import PmAddressPure, {
  type Props as PropsAddressPure,
} from '@/components/basics/PmAddress/PmAddressPure.vue'

import type { Nilable } from '@/types/misc'

export interface Props {
  isLoading?: boolean
  id: Nilable<number>
  name?: string
  number?: string
  status?: Nilable<Status>
  startDate?: Nilable<Date>
  endDate?: Nilable<Date>
  createdDate?: Nilable<Date>
  createdBy?: Nilable<string>
  editedDate?: Nilable<Date>
  editedBy?: Nilable<string>
  jobLeader?: PropsAddressPure['address']
  service?: Nilable<string>
  location?: PropsAddressPure['address']
  locationNote?: Nilable<string>
  contact?: PropsAddressPure['address']
  customer?: PropsAddressPure['address']
  project?: Nilable<Project>
  allJobs?: Job[]
  resourcesWithoutAppointment?: Resource[]
}

export type Status = {
  status: JobStatus
  label: string
}

export type Project = {
  id: number
  number: Nilable<string>
  label: Nilable<string>
  status: Nilable<ProjectStatus>
}

export type Job = {
  id: number
  number: Nilable<string>
  title: Nilable<string>
  status: Nilable<JobStatus>
}

export type Resource = {
  id: number
  title: Nilable<string>
  startDate: Nilable<Date>
  endDate: Nilable<Date>
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'openProject', id: number): void
  (event: 'openJob', id: number): void
}>()
</script>

<style lang="scss">
.PmDataModalJobPure {
  $block: &;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-project {
    display: flex;
    gap: 8px;
  }

  &-allJobsList {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-allJobsListItem {
    display: flex;
    gap: 8px;
  }
}
</style>
