<template>
  <PmModalPure
    debug-title="modalTitle + ': ' + id"
    :title="modalTitle"
    class="DataModal"
    @close="$emit('close')"
  >
    <div v-if="note" class="alert alert-primary" v-html="note"></div>

    <PmLoadingPure
      v-if="isLegacyComponent && loading"
      class="DataModal-loading"
    />

    <component
      :is="componentName"
      :id="id"
      ref="dataView"
      @close="$emit('close')"
    />
  </PmModalPure>
</template>

<script>
import { upperFirst, has } from 'lodash-es'

import PmModalPure from '@/components/basics/PmModalPure.vue'
import DataModalAppointment from '@/components/persoplan/DataModal/PmDataModalAppointment/PmDataModalAppointment.vue'
import DataModalJob from '@/components/persoplan/DataModal/DataModalJob/PmDataModalJob.vue'
import DataModalProject from '@/components/persoplan/DataModal/DataModalProject/PmDataModalProject.vue'
import DataModalResource from '@/components/persoplan/DataModal/DataModalResource.vue'
import DataModalResourceAllocation from '@/components/persoplan/DataModal/PmDataModalResourceAllocation/PmDataModalResourceAllocation.vue'
import DataModalResourceRequest from '@/components/persoplan/DataModal/PmDataModalResourceRequest/PmDataModalResourceRequest.vue'
import DataModalEvent from '@/components/persoplan/DataModal/DataModalEvent.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'

export default {
  components: {
    PmModalPure,
    DataModalAppointment,
    DataModalJob,
    DataModalProject,
    DataModalResource,
    DataModalResourceAllocation,
    DataModalResourceRequest,
    DataModalEvent,
    PmLoadingPure,
  },

  props: {
    title: { type: String, required: true },
    id: { type: Number, required: true },
    type: { type: String, required: true },
    note: { type: String, default: undefined },
  },

  emits: ['close'],

  data() {
    return {
      mounted: false,
    }
  },

  computed: {
    modalTitle() {
      if (this.type === 'job') return `Job Details`
      if (this.type === 'appointment') return `Termin Details`
      if (this.type === 'project') return `Projekt Details`
      if (this.type === 'resource') return `Ressource Details`
      if (this.type === 'resourceAllocation')
        return `Ressourcen-Zuordnung Details`
      if (this.type === 'resourceRequest') return `Ressourcen-Anfrage Details`

      return 'Details'
    },

    isLegacyComponent() {
      if (this.type == 'project') return false
      if (this.type == 'job') return false
      if (this.type == 'resourceAllocation') return false
      if (this.type == 'resourceRequest') return false
      if (this.type == 'appointment') return false

      return true
    },

    componentName() {
      return `DataModal${upperFirst(this.type)}`
    },

    loading() {
      if (!this.mounted) return true
      if (!has(this.$refs, 'dataView')) return true

      return this.$refs.dataView.$apollo.loading
    },
  },

  mounted() {
    this.mounted = true
  },
}
</script>

<style lang="scss">
.DataModal-loading {
  width: 20px;
  height: 20px;
}

.DataModal-resource {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.DataModal-listTitle {
  margin-bottom: 5px;
  font-weight: bold;
}

.DataModal-list {
  margin: 0;
  padding-left: 20px;
}

.DataModal-listItem {
  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

// disable first border top because the tabs have already a line
/* stylelint-disable-next-line plugin/selector-bem-pattern */
.DataModal .table tr:first-child td {
  border-top: none;
}
</style>
