<template>
  <div
    class="PmAppNotificationPure"
    :class="classes"
    @mouseover="pauseTimeout"
    @mouseleave="resumeTimeout"
  >
    <div class="PmAppNotificationPure-inner">
      <div class="PmAppNotificationPure-iconContainer">
        <PmLoadingPure v-if="isLoading" size="small" />

        <PmIconPure
          v-if="!isLoading"
          :key="normalizedIcon"
          :name="normalizedIcon"
          class="PmAppNotificationPure-icon"
        />
      </div>

      <div class="PmAppNotificationPure-content">
        <div v-if="hasTitle" class="PmAppNotificationPure-title">
          {{ title }}
        </div>

        <div
          v-if="hasText || hasAdditionalContent"
          class="PmAppNotificationPure-text"
        >
          <PmRichTextPure v-if="hasText">
            <slot />
          </PmRichTextPure>

          <slot name="additionalContent" />
        </div>
      </div>

      <PmButtonListPure v-if="hasActions" class="PmAppNotificationPure-actions">
        <slot name="actions" />
      </PmButtonListPure>

      <div
        v-if="hasDetails && isDetailsVisibleNormalized === false"
        class="PmAppNotificationPure-detailsOpen"
      >
        <PmButtonPure
          :label="openDetailsLabel || 'Details anzeigen'"
          :variant="variant"
          @click="openDetails"
        />
      </div>

      <div
        v-if="hasDetails && isDetailsVisibleNormalized"
        class="PmAppNotificationPure-details"
      >
        <PmButtonPure
          v-if="false"
          class="PmAppNotificationPure-detailsClose"
          :icon="ICONS.CLOSE"
          alternative="ghost"
          @click="closeDetails"
        />

        <slot name="details" />
      </div>

      <div class="PmAppNotificationPure-control">
        <div v-if="timeout" class="PmAppNotificationPure-timer">
          <PmTimerCirclePure
            :progress="timeoutProgress"
            :radius="8"
            :stroke="2"
            :variant="variant"
          />
        </div>

        <div class="PmAppNotificationPure-close">
          <PmButtonPure
            v-if="canBeClosed"
            icon="close"
            alternative="ghost"
            :variant="variant"
            @click="$emit('close')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmAppNotificationPure'

export const propTypes = {
  variant: {
    allowed: ['success', 'warning', 'danger'] as const,
  },

  layout: {
    allowed: ['default', 'compact'] as const,
  },

  icon: {
    allowed: propTypesIconPure.name.allowed,
  },
}

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import {
  ref,
  computed,
  useSlots,
  onMounted,
  onUpdated,
  onBeforeUnmount,
} from 'vue'
import gsap from 'gsap'

import { ICONS } from '@/constants/icons'

import { hasSlotContent } from '@/utilities/misc'

import PmIconPure, {
  type Props as PropsIconPure,
  propTypes as propTypesIconPure,
} from '@/components/basics/PmIcon/PmIconPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmTimerCirclePure from '@/components/basics/PmTimerCircle/PmTimerCirclePure.vue'
import PmRichTextPure from '@/components/basics/PmRichText/PmRichTextPure.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'

export interface Props {
  variant?: (typeof propTypes.variant.allowed)[number]
  layout?: (typeof propTypes.layout.allowed)[number]
  title?: string
  icon?: PropsIconPure['name']
  canBeClosed?: boolean
  timeout?: number
  withShadow?: boolean
  isLoading?: boolean
  openDetailsLabel?: string
  isDetailsVisible?: boolean | undefined
}

const props = withDefaults(defineProps<Props>(), {
  isDetailsVisible: undefined,
})
const slots = useSlots()

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'timeoutComplete'): void
  (event: 'openDetails'): void
  (event: 'closeDetails'): void
}>()

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--variantSuccess`]: props.variant === 'success',
    [`${COMPONENT_NAME}--variantWarning`]: props.variant === 'warning',
    [`${COMPONENT_NAME}--variantDanger`]: props.variant === 'danger',
    [`${COMPONENT_NAME}--layoutDefault`]:
      props.layout === 'default' || props.layout === undefined,
    [`${COMPONENT_NAME}--layoutCompact`]: props.layout === 'compact',
    [`${COMPONENT_NAME}--withShadow`]: props.withShadow === true,
    'is-withoutTitle': hasTitle.value === false,
  }
})

const normalizedIcon = computed(() => {
  if (props.icon) return props.icon
  if (props.variant === 'success') return ICONS.CHECK
  if (props.variant === 'warning') return ICONS.ALERT
  if (props.variant === 'danger') return ICONS.ALERT

  return ICONS.INFO
})

const hasTitle = computed(() => {
  return props.title ? true : false
})

/**
 * Check slots for content
 */
const hasText = ref(false)
const hasAdditionalContent = ref(false)
const hasActions = ref(false)
const hasDetails = ref(false)

function checkSlots() {
  hasText.value = hasSlotContent(slots.default)
  hasAdditionalContent.value = hasSlotContent(slots.additionalContent)
  hasActions.value = hasSlotContent(slots.actions)
  hasDetails.value = hasSlotContent(slots.details)
}

onMounted(checkSlots)
onUpdated(checkSlots)

/**
 * Timeout
 */
const timeoutProgress = ref(0)
let timeoutTween: gsap.core.Tween | undefined = undefined

async function maybeInitTimeout() {
  if (!props.timeout) return

  const data = {
    progress: 0,
  }

  await new Promise((resolve) => {
    timeoutTween = gsap.to(data, {
      duration: props.timeout,
      progress: 100,
      roundProps: 'progress',
      ease: 'none',
      onUpdate: () => {
        timeoutProgress.value = data.progress
      },
      onComplete: resolve,
    })
  })

  emit('timeoutComplete')
}

function pauseTimeout() {
  if (!timeoutTween) return
  timeoutTween.pause()
}

function resumeTimeout() {
  if (!timeoutTween) return
  timeoutTween.play()
}

function maybeDestroyTimeout() {
  if (!timeoutTween) return
  timeoutTween.kill()
}

maybeInitTimeout()
onBeforeUnmount(maybeDestroyTimeout)

/**
 * Details
 */
const isDetailsVisibleInternal = ref(false)

const isDetailsVisibleControlledFromOutside = computed(() => {
  return props.isDetailsVisible !== undefined
})

const isDetailsVisibleNormalized = computed(() => {
  return isDetailsVisibleControlledFromOutside.value
    ? props.isDetailsVisible
    : isDetailsVisibleInternal.value
})

function openDetails() {
  isDetailsVisibleControlledFromOutside.value
    ? emit('openDetails')
    : (isDetailsVisibleInternal.value = true)
}

function closeDetails() {
  console.log('close details', isDetailsVisibleControlledFromOutside.value)

  isDetailsVisibleControlledFromOutside.value
    ? emit('closeDetails')
    : (isDetailsVisibleInternal.value = false)
}
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmAppNotificationPure {
  $block: &;

  @include cssVar.define($block, 'colorBackground', #{color.$gray-100});
  @include cssVar.define($block, 'colorBorder', #{color.$gray-300});
  @include cssVar.define($block, 'colorIcon', inherit);
  @include cssVar.define($block, 'colorText', inherit);

  background-color: cssVar.use($block, 'colorBackground');
  border-radius: constant.$borderRadius-default;
  border: 2px solid cssVar.use($block, 'colorBorder');
  display: flex;
  font-weight: 500;
  position: relative;
  text-align: left;

  &--withShadow {
    @include shadow.default('low', $outline: false);
  }

  &--variantSuccess {
    @include cssVar.define($block, 'colorBackground', color.$success-50);
    @include cssVar.define($block, 'colorBorder', color.$success-500);
    @include cssVar.define($block, 'colorIcon', color.$success-500);
    @include cssVar.define($block, 'colorText', color.$success-700);
  }

  &--variantWarning {
    @include cssVar.define($block, 'colorBackground', color.$warning-50);
    @include cssVar.define($block, 'colorBorder', color.$warning-500);
    @include cssVar.define($block, 'colorIcon', color.$warning-600);
    @include cssVar.define($block, 'colorText', color.$warning-800);
  }

  &--variantDanger {
    @include cssVar.define($block, 'colorBackground', color.$danger-50);
    @include cssVar.define($block, 'colorBorder', color.$danger-500);
    @include cssVar.define($block, 'colorIcon', color.$danger-500);
    @include cssVar.define($block, 'colorText', color.$danger-700);
  }

  &-inner {
    width: 100%;
    padding-bottom: 12px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      'icon content control'
      'icon actions actions'
      'icon detailsOpen detailsOpen'
      'details details details';

    #{$block}--layoutCompact & {
      padding-bottom: 12px;
    }
  }

  &-iconContainer {
    grid-area: icon;
    width: 28px;
    height: 28px;
    padding: 4px;
    color: cssVar.use($block, 'colorIcon');
    margin-right: 12px;
    margin-top: 12px;
    margin-left: 12px;
  }

  &-content {
    grid-area: content;
    padding-top: 12px + 6px;
    padding-right: 12px;

    #{$block}--layoutCompact & {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5em;
    }
  }

  &-title {
    @include typography.h4($includeMargin: false);

    color: cssVar.use($block, 'colorText');
    hyphens: auto;
  }

  &-text {
    color: cssVar.use($block, 'colorText');
    padding-bottom: 6px;

    #{$block}--layoutDefault:not(.is-withoutTitle) & {
      margin-top: 0.7em;
    }
  }

  &-actions {
    grid-area: actions;
    margin-top: 8px;
    padding-right: 12px;
  }

  &-detailsOpen {
    grid-area: detailsOpen;
    margin-top: 8px;
    padding-right: 12px;
  }

  &-details {
    @include shadow.default('low', $outline: false);

    grid-area: details;
    margin-top: 12px;
    position: relative;
    z-index: 0;

    // Alternative Styling 1
    // padding: 16px;
    // border-top: 2px solid cssVar.use($block, 'colorBorder');
    // margin-bottom: -12px;
    // background-color: rgba(color.$white, 0.75);

    // Alternative styling 2
    background-color: color.$white;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 120;
    border: 1px solid color.$gray-300;
    border-radius: constant.$borderRadius-large;
  }

  &-detailsClose {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1;
  }

  &-control {
    grid-area: control;
    display: flex;
    padding: 4px;
    flex: none;
  }

  &-timer {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-close {
    @include mixin.transition-hover(opacity, $block);

    opacity: 0.25;

    #{$block}:hover &,
    #{$block}.is-hover & {
      opacity: 1;
    }
  }
}
</style>
