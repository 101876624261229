<template>
  <button
    class="PmColorButtonPure"
    :style="styles"
    :class="classes"
    :title="title"
    @click="$emit('click')"
  >
    <PmIconPure
      v-if="icon && iconVisible && !isLoading"
      :key="icon"
      :name="icon"
      class="PmColorButtonPure-icon"
    />

    <PmIconPure
      v-if="!icon && !isLoading && isActive"
      :name="ICONS.CHECK"
      class="PmColorButtonPure-icon"
    />

    <PmLoadingPure v-if="isLoading" class="PmColorButtonPure-loading" />
  </button>
</template>

<script>
import Color from 'color'

import { ICONS } from '@/constants/icons'
import { color } from '@/assets/scss/scssVariables'

import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'

export default {
  name: 'PmColorButtonPure',
  components: {
    PmIconPure,
    PmLoadingPure,
  },

  props: {
    color: { type: String, default: undefined },
    isActive: { type: Boolean, default: false },
    iconVisible: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    icon: { type: String, default: undefined },
    title: { type: String, default: undefined },
  },

  emits: ['click'],

  setup() {
    return {
      ICONS,
    }
  },

  computed: {
    classes() {
      return {
        'is-active': this.isActive,
        'is-iconInverted': this.isIconInverted,
      }
    },

    styles() {
      return {
        [`--${this.$options.name}-color`]: this.colorNormalized,
        [`--${this.$options.name}-colorHover`]: this.colorHover,
        [`--${this.$options.name}-colorBorder`]: this.colorBorder,
      }
    },

    colorNormalized() {
      if (!this.color) return color['gray-500']
      return this.color
    },

    colorHover() {
      const color = Color(this.colorNormalized)
      return color.darken(0.1)
    },

    colorBorder() {
      const color = Color(this.colorNormalized)

      if (color.isLight()) return color.darken(0.2)
      return color.lighten(0.4)
    },

    isIconInverted() {
      const color = Color(this.colorNormalized)
      return color.isLight()
    },
  },
}
</script>

<style lang="scss">
.PmColorButtonPure {
  $block: &;

  @include mixin.transition-hover('background-color');

  $size: 24px;

  width: $size;
  height: $size;
  border-radius: $size;
  background-color: cssVar.use($block, 'color');
  position: relative;
  display: block;

  &::after {
    $size: 24px;

    content: '';
    width: $size;
    height: $size;
    border-radius: $size;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 0 3px cssVar.use($block, 'colorBorder');
    opacity: 0;
  }

  &:hover {
    background-color: cssVar.use($block, 'colorHover');
  }

  &.is-active {
    &::after {
      opacity: 1;
    }
  }

  &-icon {
    $size: 16px;

    width: $size;
    height: $size;
    color: color.$white;
    position: absolute;
    top: 4px;
    left: 4px;

    #{$block}.is-iconInverted & {
      color: color.$text-default;
    }
  }

  &-loading {
    $size: 14px;

    width: $size;
    height: $size;
    color: color.$white;
    position: absolute;
    top: 3px;
    left: 3px;

    #{$block}.is-iconInverted & {
      color: color.$text-default;
    }
  }
}
</style>
