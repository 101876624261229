import type { Ref, ComputedRef } from 'vue'
import { computed, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import type { ApolloCache } from '@apollo/client/cache'
import { useStore } from 'vuex'
import { cloneDeep } from 'lodash-es'

import { throwFriendlyError } from '@/functional/error'
import { getDisplayNameOfAddress } from '@/utilities/string'

import {
  UpdateConnectionToDriverDocument,
  DeleteConnectionToDriverDocument,
  UpdateResourceAllocationVehicleDocument,
  DriverAllocationDocument,
  type ResourceAllocationQuery,
  type CreateResourceAllocationVehicleMutation,
  CreateResourceAllocationVehicleDocument,
  ResourceDocument,
  VehicleDocument,
} from '@/../generated/graphql'

import type { FormData } from '@/components/persoplan/PmResourceAllocation/PmResourceAllocationEditPure.vue'

interface Options {
  formData: Ref<FormData>
  resourceAllocation: ComputedRef<ResourceAllocationQuery['resourceAllocation']>
  resourceId: Ref<number | undefined>
  vehicleId: Ref<number | undefined>
}

export function useResourceAllocationEditVehicle({
  formData,
  resourceAllocation,
  resourceId,
  vehicleId,
}: Options) {
  const store = useStore()
  const newDriverAllocationId = ref<number | null>()

  /**
   * Create
   */
  const createResourceAllocationVehicleMutation = useMutation(
    CreateResourceAllocationVehicleDocument
  )

  const createResourceAllocationVehicle = async () => {
    if (!resourceId.value) throw new Error('resourceId is undefined')
    if (!vehicleId.value) throw new Error('vehicleId is undefined')
    if (!formData.value.statusId) throw new Error('statusId is undefined')

    if (typeof formData.value.statusId !== 'number') {
      throw new Error('statusId is not a number')
    }

    try {
      const result = await createResourceAllocationVehicleMutation.mutate(
        {
          resourceId: resourceId.value,
          vehicleId: vehicleId.value,
          resourceAllocationStateId: formData.value.statusId,
          notice: formData.value.note,
        },
        {
          update: (cache, result) => {
            const createdAllocation = result?.data?.createVehicleAllocation
            if (!createdAllocation) return

            updateCacheAfterCreateResourceAllocation(cache, createdAllocation)
          },
        }
      )

      const resourceAllocationId = result?.data?.createVehicleAllocation?.id

      return {
        resourceAllocationId,
      }
    } catch (error) {
      throwFriendlyError(error)
    }
  }

  const updateCacheAfterCreateResourceAllocation = (
    cache: ApolloCache<any>,
    result: CreateResourceAllocationVehicleMutation['createVehicleAllocation']
  ) => {
    const queryVariables = {
      ...store.getters['queryVariables/calendar'],
      id: resourceId.value,
    }

    const readQueryResult = cache.readQuery({
      query: ResourceDocument,
      variables: queryVariables,
    })

    // readQuery is readonly, thus we need to create a deep copy
    const data = cloneDeep(readQueryResult)

    if (!data?.resource?.resourceAllocations) return

    // TODO: Fix this with better typing
    // @ts-expect-error This is wrongly types, but to lazy to fix
    data.resource.resourceAllocations.push(result)

    cache.writeQuery({
      query: ResourceDocument,
      variables: queryVariables,
      data,
    })
  }

  const create = async () => {
    const result = await createResourceAllocationVehicle()

    if (newDriverAllocationId.value) {
      await updateConnectionToDriver({
        vehicleAllocationId: result?.resourceAllocationId,
      })
    }

    return result
  }

  /**
   * Update
   */
  const updateResourceAllocationVehicleMutation = useMutation(
    UpdateResourceAllocationVehicleDocument
  )

  const updateResourceAllocationVehicle = async () => {
    if (!resourceAllocation.value?.vehicle?.id) {
      throw new Error('resourceAllocation.vehicle.id is undefined')
    }

    if (!formData.value.statusId) throw new Error('statusId is undefined')

    if (typeof formData.value.statusId !== 'number') {
      throw new Error('statusId is not a number')
    }

    try {
      await updateResourceAllocationVehicleMutation.mutate({
        resourceAllocationId: resourceAllocation.value.id,
        vehicleId: resourceAllocation.value.vehicle.id,
        stateId: formData.value.statusId,
        notice: formData.value.note,
      })
    } catch (error) {
      throwFriendlyError(error)
    }
  }

  const updateConnectionToDriverMutation = useMutation(
    UpdateConnectionToDriverDocument
  )

  const updateConnectionToDriver = async ({
    vehicleAllocationId,
  }: {
    vehicleAllocationId?: number
  }) => {
    if (!newDriverAllocationId.value)
      throw new Error('newDriverAllocationId is undefined')

    if (!vehicleAllocationId) throw new Error('resourceAllocation is undefined')

    try {
      await updateConnectionToDriverMutation.mutate({
        vehicleAllocationId: vehicleAllocationId,
        driverAllocationId: newDriverAllocationId.value,
      })
    } catch (error) {
      throwFriendlyError(error)
    }
  }

  const deleteConnectionToDriverMutation = useMutation(
    DeleteConnectionToDriverDocument
  )

  const deleteConnectionToDriver = async () => {
    if (!resourceAllocation.value?.id)
      throw new Error('resourceAllocation is undefined')

    try {
      await deleteConnectionToDriverMutation.mutate({
        vehicleAllocationId: resourceAllocation.value.id,
      })
    } catch (error) {
      throwFriendlyError(error)
    }
  }

  const update = async () => {
    await updateResourceAllocationVehicle()

    if (newDriverAllocationId.value === null) {
      await deleteConnectionToDriver()
    }

    if (newDriverAllocationId.value) {
      await updateConnectionToDriver({
        vehicleAllocationId: resourceAllocation.value?.id,
      })
    }
  }

  const driverNormalized = computed(() => {
    // Allocated driver was unlinked
    if (newDriverAllocationId.value === null) return undefined

    // New unsaved driver was selected
    if (unsavedDriverNormalized.value) return unsavedDriverNormalized.value

    // Already saved driver is present
    if (allocatedDriverNormalized.value) return allocatedDriverNormalized.value

    return undefined
  })

  const unsavedDriverNormalized = computed(() => {
    if (!driverAllocation.value?.address) return

    const address = driverAllocation.value.address
    const name = getDisplayNameOfAddress(address)
    const number =
      driverAllocation.value.resourceFunctionAllocation?.job?.number

    return `${name}@${number}`
  })

  const allocatedDriverNormalized = computed(() => {
    if (!resourceAllocation.value?.resourceAllocationDriver?.address) return

    const name = getDisplayNameOfAddress(
      resourceAllocation.value.resourceAllocationDriver.address
    )

    const number =
      resourceAllocation.value.resourceAllocationDriver
        ?.resourceFunctionAllocation?.job?.number

    return `${name}@${number}`
  })

  const driverAllocationQuery = useQuery(
    DriverAllocationDocument,
    // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
    () => {
      return {
        id: newDriverAllocationId.value,
      }
    },
    () => ({
      enabled: newDriverAllocationId.value ? true : false,
    })
  )

  const driverAllocation = computed(
    () => driverAllocationQuery.result.value?.driverAllocation
  )

  const driverLoading = computed(() => {
    return driverAllocationQuery.loading.value
  })

  const vehicleIdNormalized = computed(() => {
    return vehicleId.value || resourceAllocation.value?.vehicle?.id
  })

  const vehicleQuery = useQuery(
    VehicleDocument,
    // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
    () => {
      return {
        id: vehicleIdNormalized.value,
      }
    },
    () => ({
      enabled: vehicleIdNormalized.value ? true : false,
    })
  )

  const label = computed(() => {
    const vehicle = vehicleQuery.result.value?.vehicle
    if (!vehicle) return

    return vehicle.caption
  })

  /**
   * This can be used for vehicles which are not actual vehicles, e.g. "PKW privat"
   */
  const isConflictsVisible = computed(() => {
    return true
  })

  return {
    create,
    update,
    driverNormalized,
    driverLoading,
    newDriverAllocationId,
    label,
    isConflictsVisible,
  }
}
