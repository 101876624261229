<template>
  <div :class="componentClass.root">
    <div
      v-for="group in normalizedEmployeeGroups"
      :key="group.id"
      :class="componentClass.element('group')"
    >
      <template v-if="group.addresses">
        <template v-for="employee in group.addresses">
          <PmResourceTimeline
            v-if="employee"
            :id="employee.id"
            :key="employee.id"
            :class="componentClass.element('timeline')"
            :start-date="startDate"
            :end-date="endDate"
            :type="RESOURCE_TYPE.ADDRESS"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * @todo Use primitive types for showDetails Event (?)
 */
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { useStore } from 'vuex'
import { useComponentClass } from '@thomasaull-shared/composables'
import type { Get } from 'type-fest'

import { VIEW_SETTING_MODE, RESOURCE_TYPE } from '@/constants/persoplan'
import PmResourceTimeline from '@/components/persoplan/PmResourceTimeline/PmResourceTimeline.vue'
import { EmployeeGroupsDocument } from '@/../generated/graphql'

export interface Props {
  startDate: Date
  endDate: Date
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass()
const store = useStore()

const normalizedEmployeeGroups = computed(() => {
  if (!employeeGroups.value) return

  const filteredEmployeeGroups = getFilteredEmployeeGroups()
  return filteredEmployeeGroups
})

function getFilteredEmployeeGroups() {
  const mode = store.state.view.currentView.employeeGroups.mode

  if (mode === VIEW_SETTING_MODE.EXPLICIT)
    return getFilteredEmployeeGroupsExplicit()

  if (mode === VIEW_SETTING_MODE.EXCLUDE)
    return getFilteredEmployeeGroupsExclude()

  throw new Error('employeeGroups.mode must be either explicit or exclude')
}

function getFilteredEmployeeGroupsExplicit() {
  if (!employeeGroups.value) return

  type Result = NonNullable<Get<typeof employeeGroups.value, '[0]'>>
  const result: Result[] = []

  employeeGroups.value.filter((employeeGroup) => {
    if (!employeeGroup) return

    const isIncluded = store.state.view.currentView.employeeGroups.ids.includes(
      employeeGroup.id
    )

    if (isIncluded) result.push(employeeGroup)
  })

  return result
}

function getFilteredEmployeeGroupsExclude() {
  if (!employeeGroups.value) return

  type Result = NonNullable<Get<typeof employeeGroups.value, '[0]'>>
  const result: Result[] = []

  employeeGroups.value.filter((employeeGroup) => {
    if (!employeeGroup) return

    const isIncluded =
      !store.state.view.currentView.employeeGroups.ids.includes(
        employeeGroup.id
      )

    if (isIncluded) result.push(employeeGroup)
  })

  return result
}

const employeeGroupsQuery = useQuery(EmployeeGroupsDocument)
const employeeGroups = computed(
  () => employeeGroupsQuery.result.value?.employeeGroups
)
</script>

<style lang="scss">
.PmResourceTimelinesAddresses {
  $block: &;

  padding-top: 8px;
  padding-bottom: 8px;

  &-group {
    padding-top: 17px;
    position: relative;

    &:not(:last-child) {
      padding-bottom: 15px;
    }

    &::before {
      content: '';
      position: absolute;
      background-color: color.$gray-200--alpha;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      top: 4px;
    }
  }
}
</style>
