export const AddressSortFields = {
  birthday: 'birthday',
  firstName: 'firstName',
  manualSort: 'manualSort',
  surname: 'surname',
} as const

export type AddressSortFields =
  (typeof AddressSortFields)[keyof typeof AddressSortFields]
export const EasyJobUserSortFields = {
  firstName: 'firstName',
  surname: 'surname',
} as const

export type EasyJobUserSortFields =
  (typeof EasyJobUserSortFields)[keyof typeof EasyJobUserSortFields]
export const EventSortFields = {
  endDate: 'endDate',
  startDate: 'startDate',
} as const

export type EventSortFields =
  (typeof EventSortFields)[keyof typeof EventSortFields]
export const EventType = {
  exception: 'exception',
  occurrence: 'occurrence',
  seriesMaster: 'seriesMaster',
  singleInstance: 'singleInstance',
} as const

export type EventType = (typeof EventType)[keyof typeof EventType]
export const ExternalServiceRequestAction = {
  abort: 'abort',
  accept: 'accept',
  create: 'create',
  decline: 'decline',
  update: 'update',
} as const

export type ExternalServiceRequestAction =
  (typeof ExternalServiceRequestAction)[keyof typeof ExternalServiceRequestAction]
export const ExternalServiceRequestState = {
  aborted: 'aborted',
  accepted: 'accepted',
  created: 'created',
  declined: 'declined',
  updated: 'updated',
} as const

export type ExternalServiceRequestState =
  (typeof ExternalServiceRequestState)[keyof typeof ExternalServiceRequestState]
export const FreeBusyStatus = {
  busy: 'busy',
  free: 'free',
  oof: 'oof',
  tentative: 'tentative',
  unknown: 'unknown',
  workingElsewhere: 'workingElsewhere',
} as const

export type FreeBusyStatus =
  (typeof FreeBusyStatus)[keyof typeof FreeBusyStatus]
export const Importance = {
  high: 'high',
  low: 'low',
  normal: 'normal',
} as const

export type Importance = (typeof Importance)[keyof typeof Importance]
export const JobAppointmentSortFields = {
  caption: 'caption',
  endDate: 'endDate',
  startDate: 'startDate',
} as const

export type JobAppointmentSortFields =
  (typeof JobAppointmentSortFields)[keyof typeof JobAppointmentSortFields]
export const JobSortFields = {
  caption: 'caption',
  endDate: 'endDate',
  number: 'number',
  startDate: 'startDate',
} as const

export type JobSortFields = (typeof JobSortFields)[keyof typeof JobSortFields]
export const LeaveRequestAction = {
  abort: 'abort',
  accept: 'accept',
  create: 'create',
  decline: 'decline',
  update: 'update',
} as const

export type LeaveRequestAction =
  (typeof LeaveRequestAction)[keyof typeof LeaveRequestAction]
export const LeaveRequestApprovalType = {
  hr: 'hr',
  supervisor: 'supervisor',
} as const

export type LeaveRequestApprovalType =
  (typeof LeaveRequestApprovalType)[keyof typeof LeaveRequestApprovalType]
export const LeaveRequestState = {
  aborted: 'aborted',
  accepted: 'accepted',
  created: 'created',
  declined: 'declined',
  updated: 'updated',
} as const

export type LeaveRequestState =
  (typeof LeaveRequestState)[keyof typeof LeaveRequestState]
export const LeaveRequestType = {
  overtimeCompensation: 'overtimeCompensation',
  specialVacation: 'specialVacation',
  vacation: 'vacation',
} as const

export type LeaveRequestType =
  (typeof LeaveRequestType)[keyof typeof LeaveRequestType]
export const NotificationStatus = {
  read: 'read',
  unread: 'unread',
} as const

export type NotificationStatus =
  (typeof NotificationStatus)[keyof typeof NotificationStatus]
export const NotificationType = {
  AddressUpdated: 'AddressUpdated',
  ExternalServiceRequestAccepted: 'ExternalServiceRequestAccepted',
  ExternalServiceRequestCreated: 'ExternalServiceRequestCreated',
  ExternalServiceRequestDeclined: 'ExternalServiceRequestDeclined',
  ExternalServiceRequestUpdated: 'ExternalServiceRequestUpdated',
  LeaveRequestAccepted: 'LeaveRequestAccepted',
  LeaveRequestCreated: 'LeaveRequestCreated',
  LeaveRequestDeclined: 'LeaveRequestDeclined',
  LeaveRequestUpdated: 'LeaveRequestUpdated',
  ResourceRequestFeedbackStateUpdated: 'ResourceRequestFeedbackStateUpdated',
  ResourceRequestUpdated: 'ResourceRequestUpdated',
} as const

export type NotificationType =
  (typeof NotificationType)[keyof typeof NotificationType]
export const PaymentType = {
  companyCreditCard: 'companyCreditCard',
  private: 'private',
  supplierCreditCard: 'supplierCreditCard',
} as const

export type PaymentType = (typeof PaymentType)[keyof typeof PaymentType]
export const ProjectSortFields = {
  budget: 'budget',
  caption: 'caption',
  created: 'created',
  endDate: 'endDate',
  number: 'number',
  startDate: 'startDate',
  updated: 'updated',
} as const

export type ProjectSortFields =
  (typeof ProjectSortFields)[keyof typeof ProjectSortFields]
export const PurchaseOrderSortFields = {
  caption: 'caption',
  createdAt: 'createdAt',
  number: 'number',
  orderDate: 'orderDate',
  receiveDate: 'receiveDate',
  updatedAt: 'updatedAt',
} as const

export type PurchaseOrderSortFields =
  (typeof PurchaseOrderSortFields)[keyof typeof PurchaseOrderSortFields]
export const ResourceRequestState = {
  accept: 'accept',
  cancel: 'cancel',
  decline: 'decline',
  reserve: 'reserve',
} as const

export type ResourceRequestState =
  (typeof ResourceRequestState)[keyof typeof ResourceRequestState]
export const ResourceRequestUpdatedAction = {
  created: 'created',
  deleted: 'deleted',
  unknown: 'unknown',
  updated: 'updated',
} as const

export type ResourceRequestUpdatedAction =
  (typeof ResourceRequestUpdatedAction)[keyof typeof ResourceRequestUpdatedAction]
export const ResourceRequestUserStatus = {
  accept: 'accept',
  decline: 'decline',
  maybe: 'maybe',
  not_available: 'not_available',
  unknown: 'unknown',
} as const

export type ResourceRequestUserStatus =
  (typeof ResourceRequestUserStatus)[keyof typeof ResourceRequestUserStatus]
export const ResourceStateSortFields = {
  caption: 'caption',
  endDate: 'endDate',
  startDate: 'startDate',
} as const

export type ResourceStateSortFields =
  (typeof ResourceStateSortFields)[keyof typeof ResourceStateSortFields]
export const Sort = {
  asc: 'asc',
  desc: 'desc',
} as const

export type Sort = (typeof Sort)[keyof typeof Sort]
export const StockTypeServiceSortFields = {
  caption: 'caption',
  endDate: 'endDate',
  number: 'number',
  startDate: 'startDate',
} as const

export type StockTypeServiceSortFields =
  (typeof StockTypeServiceSortFields)[keyof typeof StockTypeServiceSortFields]
export const VehicleSortFields = {
  caption: 'caption',
  loadingCapacity: 'loadingCapacity',
  numberSign: 'numberSign',
} as const

export type VehicleSortFields =
  (typeof VehicleSortFields)[keyof typeof VehicleSortFields]
export const WebfleetState = {
  aborted: 'aborted',
  created: 'created',
  deleted: 'deleted',
  draft: 'draft',
  updated: 'updated',
  workInProgress: 'workInProgress',
} as const

export type WebfleetState = (typeof WebfleetState)[keyof typeof WebfleetState]
