<template>
  <render />
</template>

<script setup lang="ts">
import { computed, ref, useSlots } from 'vue'
import { cloneDeep } from 'lodash-es'
import { useComponentClass } from '@thomasaull-shared/composables'

import { useDragAndDrop } from '@/pinia/dragAndDrop'
import { useCurrentElement, useEventListener } from '@vueuse/core'
import type { DragAndDropType } from '@/constants/persoplan'

export interface Props {
  type: DragAndDropType
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  drop: { type: DragAndDropType; data: any }
}

const emit = defineEmits<{
  drop: [Emits['drop']]
}>()

const slots = useSlots()
const componentClass = useComponentClass()
const isDraggedOver = ref(false)
const el = useCurrentElement()
const dragAndDrop = useDragAndDrop()

// @ts-expect-error TODO
useEventListener(el, 'pointerenter', () => onDragEnter())
// @ts-expect-error TODO
useEventListener(el, 'pointerleave', () => onDragLeave())
// @ts-expect-error TODO
useEventListener(el, 'pointerup', () => onDrop())

function onDragEnter() {
  if (!dragAndDrop.canBeDropped) return
  if (!isDropAllowed.value) return

  isDraggedOver.value = true
}

function onDragLeave() {
  if (!dragAndDrop.canBeDropped) return
  if (!isDropAllowed.value) return

  isDraggedOver.value = false
}

function onDrop() {
  if (!dragAndDrop.canBeDropped) return
  if (!isDropAllowed.value) return
  if (!dragAndDrop.type) throw new Error('dragAndDrop.type is undefined')

  emit('drop', {
    type: dragAndDrop.type,
    data: cloneDeep(dragAndDrop.data),
  })
}

const isDropAllowed = computed(() => {
  return dragAndDrop.type === props.type
})

const classes = computed(() => {
  let dragClasses = {}

  if (dragAndDrop.canBeDropped) {
    dragClasses = {
      'is-draggedOver': isDraggedOver.value,
      'is-dropNotAllowed': !isDropAllowed.value,
    }
  }

  return {
    [componentClass.root]: true,
    ...dragClasses,
  }
})

const render = () => {
  if (!slots.default) return
  return slots.default({
    classes: classes.value,
  })[0]
}
</script>

<style lang="scss">
.PmDropzonePure {
  $block: &;

  pointer-events: auto;

  &.is-dropNotAllowed {
    opacity: 0.25;
    pointer-events: none;
  }
}
</style>
