<template>
  <PmCreateExpenseReportPure
    :is-loading="staticDataQuery.loading.value"
    :state="xstate.path.value"
    :error-message="xstate.snapshot.context.error"
    :error-details="xstate.snapshot.context.errorDetails"
    :default-values="defaultValues"
    :options-expense-type="optionsExpenseType"
    :job-id="jobId"
    :expense-type-id-catering="3"
    :expense-type-id-travel-expense="2"
    @submit="
      (variables) => xstate.send({ type: 'create', variables: variables })
    "
    @close="emit('close')"
  >
    <template #dropdownJob>
      <PmDropdownJob v-model:selected-job-id="jobId" :required="true" />
    </template>
  </PmCreateExpenseReportPure>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { fromPromise, log } from 'xstate5'
import { useStore } from 'vuex'

import { throwFriendlyError } from '@/functional/error'
import PmCreateExpenseReportPure, {
  type Props as PropsCreateExpenseReportPure,
  type Emits as EmitsCreateExpenseReportPure,
} from '@/components/PmCreateExpenseReport/PmCreateExpenseReportPure.vue'
import { useXState } from '@/composition/useXState5'
import { PmCreateExpenseReportState } from '@/components/PmCreateExpenseReport/PmCreateExpenseReportState'
import { formatToServerDateString } from '@/utilities/date'
import {
  CreateExpenseReportDocument,
  CreateExpenseReportStaticDataDocument,
  PaymentType,
} from '@/../generated/graphql'
import PmDropdownJob from '@/components/PmDropdownJob/PmDropdownJob.vue'

const emit = defineEmits<{
  close: []
}>()

const store = useStore()

const xstate = useXState(
  PmCreateExpenseReportState.provide({
    actions: {
      showSuccessNotification: () => {
        store.commit('notification/add', {
          variant: 'success',
          title: 'Deine Spesenabrechnung wurde erfolgreich eingereicht',
        })
      },
      emitClose: () => emit('close'),
    },

    actors: {
      createExpenseReport: fromPromise(async ({ input }) =>
        createExpenseReport(input.variables)
      ),
    },
  })
)

const createExpenseReportMutation = useMutation(CreateExpenseReportDocument, {
  context: {
    // hasUpload: true,
  },
})

async function createExpenseReport(
  variables: EmitsCreateExpenseReportPure['submit']
) {
  try {
    const files =
      variables.base.receipts && Array.from(variables.base.receipts.values())

    const isPublicTransportation = variables.travelExpense
      ? variables.travelExpense?.typeOfTransportId === 'cabOrPublicTransport'
      : undefined

    await createExpenseReportMutation.mutate({
      // Base
      expenseTypeId: variables.base.expenseTypeId,
      date: formatToServerDateString(variables.base.dateOfSubmission, {
        type: 'date',
      }),
      jobId: variables.base.jobId,
      details: variables.base.notes,
      paymentType: PaymentType[variables.base.typeOfPaymentId],
      paymentInfo: variables.base.paymentNotes,
      files: files,

      // Travel Expense
      route: variables.travelExpense?.route,
      kilometer: variables.travelExpense?.numberOfKilometers,
      publicTransportation: isPublicTransportation,

      // Catering
      dayOfCatering: variables.catering?.dateOfCatering,
      location: variables.catering?.placeOfHospitalityAddress,
      servedPersons: variables.catering?.personsServed,
      reason: variables.catering?.reasonForCatering,
      invoiceAmount: variables.catering?.amountOfExpenses,
      tipAmount: variables.catering?.amountTip,
      totalAmount: variables.catering?.amountTotal,
    })
  } catch (error) {
    throwFriendlyError(error)
  }
}

const staticDataQuery = useQuery(CreateExpenseReportStaticDataDocument)

const optionsExpenseType = computed(() => {
  const result: PropsCreateExpenseReportPure['optionsExpenseType'] = []

  staticDataQuery.result.value?.expenseTypes?.forEach((expenseType) => {
    if (!expenseType) return

    const option: (typeof result)[0] = {
      id: expenseType.id,
      label: expenseType.caption,
    }

    result.push(option)
  })

  return result
})

const jobId = ref<number>()

const defaultValues = computed<PropsCreateExpenseReportPure['defaultValues']>(
  () => {
    return {
      dateOfSubmission: new Date(),
      dateOfCatering: new Date(),
    }
  }
)
</script>
