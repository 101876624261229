<template>
  <div v-if="checksCompleted" ref="elRoot" class="PmPersoplan">
    <portal to="notifications">
      <PmAppNotificationPure
        v-if="notificationEndDateAdjustedVisible"
        :key="`${$options.name}.endDateAdjusted`"
        title="Zeitraum Ende automatisch angepasst"
        variant="warning"
        :with-shadow="true"
        :can-be-closed="true"
        @close="notificationEndDateAdjustedVisible = false"
      >
        Der Start des angezeigten Zeitraums muss vor dem Ende liegen. Das Ende
        wurde deshalb automatisch angepasst.
      </PmAppNotificationPure>
    </portal>

    <PmSidebar
      @open-search="isSearchVisible = true"
      @open-date-controller="dateControllerVisible = true"
      @create-resource-state-freelancer="
        isCreateResourceStateFreelancerVisible = true
      "
      @initial-loading-finished="endLoading('sidebar')"
    />

    <PmDateController
      v-if="dateControllerVisible"
      @close="dateControllerVisible = false"
    />

    <PmViewEditor
      v-if="store.state.persoplan.viewEditorVisible"
      @close="store.commit('persoplan/hideViewEditor')"
      @close-and-save="onViewEditorCloseAndSave"
      @close-and-create="onViewEditorCloseAndCreate"
    />

    <portal to="modal">
      <PmViewCreate
        v-if="isViewCreateVisible"
        @close="isViewCreateVisible = false"
      />
    </portal>

    <PmSearch
      v-if="isSearchVisible"
      :now="time.today"
      @close="isSearchVisible = false"
      @select="jumpToJob"
    />

    <PmActionbar
      v-if="persoplanState.state.value.matches('edit')"
      class="PmPersoplan-actionbar"
    />

    <div v-show="hasPortalContext" class="PmPersoplan-portalContext">
      <div class="PmPersoplan-portalContextContent">
        <portal-target name="context">
          <template #wrapper="nodes">
            <component :is="nodes[0]" />
          </template>
        </portal-target>

        <PmMultipleResourcesController
          v-if="
            isMultipleResourcesControllerVisible &&
            propsMultipleResourcesController
          "
          class="PmPersoplan-multiSelectionController"
          :resource-type="propsMultipleResourcesController.resourceType"
          :resource-function-ids="
            propsMultipleResourcesController.resourceFunctionIds
          "
          @close="closeMultipleResourcesController"
        />

        <PmMultipleResourceAllocationsController
          v-if="isMultipleResourceAllocationsControllerVisible"
          @open-delete-dialog="
            isMultipleResourceAllocationsDeleteVisible = true
          "
          @open-update-dialog="
            isMultipleResourceAllocationsUpdateVisible = true
          "
          @open-request-dialog="
            isMultipleResourceAllocationsRequestVisible = true
          "
        />
      </div>
    </div>

    <PmMultipleResourceAllocationsDelete
      v-if="isMultipleResourceAllocationsDeleteVisible"
      :resource-allocation-ids="selectionResourceAllocations.selectedIds"
      :type="selectionResourceAllocations.selectionType"
      @close="isMultipleResourceAllocationsDeleteVisible = false"
    />

    <PmMultipleResourceAllocationsUpdate
      v-if="
        isMultipleResourceAllocationsUpdateVisible &&
        selectionResourceAllocations.selectionType
      "
      :resource-allocation-ids="selectionResourceAllocations.selectedIds"
      :type="selectionResourceAllocations.selectionType"
      @close="isMultipleResourceAllocationsUpdateVisible = false"
    />

    <PmMultipleResourceAllocationsRequest
      v-if="isMultipleResourceAllocationsRequestVisible"
      :resource-allocation-ids="selectionResourceAllocations.selectedIds"
      @close="isMultipleResourceAllocationsRequestVisible = false"
    />

    <PmCalendar
      :initial-loading-finished="initialLoadingFinished"
      :user-is-idle="userIsIdle"
      @open-search="isSearchVisible = true"
      @open-date-controller="dateControllerVisible = true"
      @create-resource-state-freelancer="
        isCreateResourceStateFreelancerVisible = true
      "
      @initial-loading-finished="endLoading('calendar')"
    />

    <PmResourceStateFreelancerCreate
      v-if="isCreateResourceStateFreelancerVisible"
      @cancel="isCreateResourceStateFreelancerVisible = false"
      @close="isCreateResourceStateFreelancerVisible = false"
    />

    <PmPause
      :is-enabled="initialLoadingFinished"
      @user-is-inactive="userIsIdle = true"
      @user-is-active="userIsIdle = false"
    />

    <PmSendToWebfleet
      v-if="
        isModalSendToWebfleetVisible && resourceAllocationIdToSendToWebfleet
      "
      :resource-allocation-id="resourceAllocationIdToSendToWebfleet"
      @close="hideModalSendToWebfleet"
    />
  </div>
</template>

<script setup lang="ts">
import {
  onMounted,
  ref,
  onBeforeUnmount,
  computed,
  reactive,
  provide,
  onActivated,
} from 'vue'
// import gsap from 'gsap'
import { isBefore } from 'date-fns'
import { useStore } from 'vuex'
import type { ApolloCache } from '@apollo/client/cache'
import { useApolloClient } from '@vue/apollo-composable'
import { Wormhole } from 'portal-vue'
import { useRoute } from 'vue-router'
import * as v from 'valibot'
import { isNil } from 'lodash-es'

import { EVENT } from '@/constants/events'
import { ICONS } from '@/constants/icons'
import { CALENDAR_ITEM_TYPE } from '@/constants/persoplan'

import EventHub from '@/eventHub'
import { handleMutationError } from '@/functional/handleMutationError'
import { useLoading } from '@/composition/useLoading'
import {
  persoplanStateKey,
  querySettingsKey,
  injectStrict,
} from '@/utilities/inject'
import { useShortcut } from '@/composition/useShortcut'
import { useSelectionResourceAllocations } from '@/pinia/selectionResourceAllocations'
import { useJumpTargets } from '@/pinia/jumpTargets'
import { useTime } from '@/pinia/time'

import PmActionbar from '@/components/persoplan/Actionbar/PmActionbar.vue'
import PmSearch, {
  type Emit as EmitSearch,
} from '@/components/persoplan/Search/PmSearch.vue'
import PmSidebar from '@/components/persoplan/PmSidebar/PmSidebar.vue'
import PmDateController from '@/components/persoplan/PmDateController.vue'
import PmViewEditor from '@/components/persoplan/ViewEditor/PmViewEditor.vue'
import PmCalendar from '@/components/persoplan/PmCalendar/PmCalendar.vue'
import PmMultipleResourcesController, {
  type Props as PropsMultipleResourcesController,
} from '@/components/persoplan/PmMultiSelectionController/PmMultipleResourcesController.vue'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmViewCreate from '@/components/persoplan/PmViewCreate/PmViewCreate.vue'
import PmResourceStateFreelancerCreate from '@/components/persoplan/PmResourceStateEdit/PmResourceStateFreelancerCreate.vue'
import PmPause from '@/components/persoplan/PmPause/PmPause.vue'
import PmMultipleResourceAllocationsController from '@/components/persoplan/PmMultipleResourceAllocationsController/PmMultipleResourceAllocationsController.vue'
import PmMultipleResourceAllocationsDelete from '@/components/persoplan/PmMultipleResourceAllocationsDelete/PmMultipleResourceAllocationsDelete.vue'
import PmMultipleResourceAllocationsUpdate from '@/components/persoplan/PmMultipleResourceAllocationsUpdate/PmMultipleResourceAllocationsUpdate.vue'
import PmMultipleResourceAllocationsRequest from '@/components/persoplan/PmMultipleResourceAllocationsRequest/PmMultipleResourceAllocationsRequest.vue'
import PmSendToWebfleet from '@/components/persoplan/PmSendToWebfleet/PmSendToWebfleet.vue'
import { parseServerDateString } from '@/utilities/date'
import { useSelectionResources } from '@/composition/useSelectionResources'
import { UpdateViewDocument } from '@/../generated/graphql'

export interface QuerySettings {
  useCache: boolean
}

const store = useStore()
const { client: apolloClient } = useApolloClient()

const persoplanState = injectStrict(persoplanStateKey)

const querySettings = reactive<QuerySettings>({
  useCache: true,
})
provide(querySettingsKey, querySettings)

const selectionResourceAllocations = useSelectionResourceAllocations()
const selectionResources = useSelectionResources()
const time = useTime()

const elRoot = ref()
const userIsIdle = ref(false)
const notificationEndDateAdjustedVisible = ref(false)
const checksCompleted = ref(false)
const isSearchVisible = ref(false)
const dateControllerVisible = ref(false)
const isViewCreateVisible = ref(false)
const isCreateResourceStateFreelancerVisible = ref(false)

let resizeObserver: ResizeObserver | null = new ResizeObserver(() => {
  EventHub.$emit(EVENT.UPDATE_SCROLLBAR_DIMENSIONS)
})

const initialLoadingFinished = ref(false)

const { endLoading } = useLoading({
  loaders: ['calendar', 'sidebar'],
  autoStart: true,
  onLoadingFinished: () => {
    initialLoadingFinished.value = true
  },
})

const isMultipleResourceAllocationsControllerVisible = computed(() => {
  return selectionResourceAllocations.isInSelectionMode
})

const isMultipleResourceAllocationsDeleteVisible = ref(false)
const isMultipleResourceAllocationsUpdateVisible = ref(false)
const isMultipleResourceAllocationsRequestVisible = ref(false)

const hasPortalContext = computed(() => {
  if (isMultipleResourcesControllerVisible.value === true) return true
  if (isMultipleResourceAllocationsControllerVisible.value === true) return true

  const hasWormholeContent = Wormhole.transports.get('context') ? true : false
  if (hasWormholeContent) return true

  return false
})

/**
 * Shortcuts
 */
useShortcut(['Alt', 'S'], () => {
  if (isSearchVisible.value) return
  isSearchVisible.value = true
})

useShortcut(['Alt', 'E'], () => {
  if (store.state.persoplan.viewEditorVisible) return
  store.commit('persoplan/showViewEditor')
})

useShortcut(['Alt', 'D'], () => {
  if (dateControllerVisible.value) return
  dateControllerVisible.value = true
})

onMounted(() => {
  if (!resizeObserver) return
  resizeObserver.observe(elRoot.value)
})

onBeforeUnmount(() => {
  if (!resizeObserver) return
  resizeObserver.disconnect()
  resizeObserver = null
})

const doChecks = () => {
  // Check if startDate and endDate are in order
  const startAndEndDateAreInOrder = isBefore(
    store.state.persoplan.visibleStartDate,
    store.state.persoplan.visibleEndDate
  )

  if (!startAndEndDateAreInOrder) {
    store.commit('persoplan/setVisibleDates', {
      startDate: store.state.persoplan.visibleStartDate,
      endDate: new Date(store.state.persoplan.visibleStartDate.getTime()),
    })

    notificationEndDateAdjustedVisible.value = true
  }

  checksCompleted.value = true
}

const propsMultipleResourcesController = computed(() => {
  if (isNil(selectionResources.selectedType.value)) return
  if (selectionResources.selectedIds.value.size === 0) return

  const props = {
    resourceType: selectionResources.selectedType.value,
    resourceFunctionIds: selectionResources.selectedIds.value,
  } satisfies PropsMultipleResourcesController

  return props
})

const isMultipleResourcesControllerVisible = computed(() => {
  return !!propsMultipleResourcesController.value
})

function closeMultipleResourcesController() {
  selectionResources.clear()
}

const onViewEditorCloseAndSave = async () => {
  store.commit('persoplan/hideViewEditor')

  try {
    if (!store.state.view.id) {
      throw new Error('no view id in store')
    }

    const name = store.state.view.title
    if (!name) {
      throw new Error('view name not found in store')
    }

    const query = JSON.stringify(store.state.view.currentView)

    await apolloClient.mutate({
      mutation: UpdateViewDocument,

      variables: {
        id: store.state.view.id,
        title: name,
        public: undefined, // TODO: Check if this works if public is not defined
        query: query,
      },

      update: (cache: ApolloCache<any>, result) => {
        const updatedView = result?.data?.updatePersoPlanView
        if (!updatedView) return

        store.commit('view/setView', {
          id: updatedView.id,
          title: updatedView.title,
          query: updatedView.query,
        })
      },
    })

    store.commit('notification/add', {
      variant: 'success',
      icon: ICONS.CHECK,
      title: 'Ansicht gespeichert',
    })
  } catch (error) {
    handleMutationError(error)
  }
}

const onViewEditorCloseAndCreate = () => {
  store.commit('persoplan/hideViewEditor')
  isViewCreateVisible.value = true
}

doChecks()

/**
 * There is a bug when using transitions with portal-vue
 * Therefore transitions on context portal are disabled until this is fixed
 * @see: https://github.com/LinusBorg/portal-vue/issues/378
 */
// onEnter(el, done) {
//   gsap.set(el, { y: '200%' })
//   gsap.to(el, {
//     // ease: 'back.out(1.7)',
//     ease: 'elastic.out(1, 0.75)',
//     duration: 1,
//     y: 0,
//   })
//   done()
// },
// onLeave(el, done) {
//   done()
// },

/**
 * Jump to job when user selects a search result
 */
const jumpTargets = useJumpTargets()

function jumpToJob(result: EmitSearch['select']) {
  console.log('jumpToJob', result)

  const resultType = result.isCollectedWithAllJobsOfProject
    ? CALENDAR_ITEM_TYPE.PROJECT
    : CALENDAR_ITEM_TYPE.JOB
  let appointmentContainer

  if (resultType === CALENDAR_ITEM_TYPE.JOB) {
    appointmentContainer = {
      id: result.id,
      type: CALENDAR_ITEM_TYPE.JOB,
    }
  }

  if (resultType === CALENDAR_ITEM_TYPE.PROJECT) {
    appointmentContainer = {
      id: result.projectId,
      type: CALENDAR_ITEM_TYPE.PROJECT,
    }
  }

  if (!appointmentContainer) {
    throw new Error('appointmentContainer is undefined')
  }

  // Set highlight
  store.commit('persoplan/clearHighlightedItems')
  store.commit('persoplan/addToHighlightedItems', {
    id: appointmentContainer.id,
    type: appointmentContainer.type,
  })

  jumpTargets.jumpTo(
    {
      type: 'AppointmentsContainer',
      id: appointmentContainer.id,
      startDate: result.startDate,
      endDate: result.endDate,
    },
    { changeDate: true }
  )

  isSearchVisible.value = false
}

/**
 * Show modal send to webfleet
 */
const isModalSendToWebfleetVisible = ref(false)
const resourceAllocationIdToSendToWebfleet = ref<number>()

onMounted(() => {
  EventHub.$on(EVENT.MODAL_SEND_TO_WEBFLEET_SHOW, showModalSendToWebfleet)
})

onBeforeUnmount(() => {
  EventHub.$off(EVENT.MODAL_SEND_TO_WEBFLEET_SHOW, showModalSendToWebfleet)
})

function showModalSendToWebfleet(event) {
  if (!('resourceAllocationId' in event)) {
    throw new Error('resourceAllocationId is undefined')
  }

  resourceAllocationIdToSendToWebfleet.value = event.resourceAllocationId
  isModalSendToWebfleetVisible.value = true
}

function hideModalSendToWebfleet() {
  resourceAllocationIdToSendToWebfleet.value = undefined
  isModalSendToWebfleetVisible.value = false
}

/**
 * Update start- and end-date when url params change
 */
const route = useRoute()

const urlParamsVisibleTimeframeSchema = v.object({
  'start-date': v.string(),
  'end-date': v.string(),
})

onActivated(() => {
  maybeApplyTimeframeFromUrlParams()
})

function maybeApplyTimeframeFromUrlParams() {
  const result = v.safeParse(urlParamsVisibleTimeframeSchema, route.query)
  if (!result.success) return

  let startDate: Date | undefined
  let endDate: Date | undefined

  try {
    // Try to parse string to dates
    startDate = parseServerDateString(result.output['start-date'], {
      type: 'date',
    })
    endDate = parseServerDateString(result.output['end-date'], {
      type: 'date',
    })
  } catch (error) {
    // slient error
    return
  }

  if (!startDate || !endDate) return

  store.commit('persoplan/setVisibleDates', {
    startDate: startDate,
    endDate: endDate,
  })
}
</script>

<style lang="scss">
.PmPersoplan {
  $block: &;

  position: relative;

  &-portalContext {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 11;
    padding-left: calc(var(--sidebarLeftWidthWithVisibility) + 12px);
    padding-right: calc(var(--sidebarRightWidthWithVisibility) + 12px);
    padding-bottom: 12px;
    display: flex;
    justify-content: center;
    will-change: transform;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      top: -80px;
      left: var(--sidebarLeftWidthWithVisibility);
      right: var(--sidebarRightWidthWithVisibility);
      pointer-events: none;
      background: linear-gradient(
        0deg,
        rgba(color.$black, 0.35),
        ease-out,
        rgba(color.$black, 0)
      );
    }

    // pointer-events: none;
    // outline: 2px solid fuchsia;
    // // transition: transform 0.5s ease-out;
    // transition: transform 0.5s cubic-bezier(0, 0, 0.52, 1.36);
    // opacity: 1;

    // &.v-enter {
    //   transform: translateY(200%);
    // }

    // &.v-leave-to {
    //   transform: translateY(200%);
    // }
  }

  &-portalContextContent {
    display: flex;
    width: 100%;
    justify-content: center;

    & > * {
      pointer-events: auto;
    }
  }
}
</style>
