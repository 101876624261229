import { setup, fromPromise, assertEvent } from 'xstate5'

import type { Emits as EmitsMultipleResourcesDefault } from '@/components/persoplan/PmMultiSelectionController/PmMultipleResourcesDefault/PmMultipleResourcesDefault.vue'
import { assertFriendlyError } from '@/functional/friendlyErrors'

export const PmMultiSelectionControllerState = setup({
  types: {
    events: {} as
      | {
          type: 'nextToAllocateSettings'
          variables: {
            resourcesToAllocateIds: number[]
          }
        }
      | { type: 'cancel' }
      | {
          type: 'createResourceAllocations'
          variables: EmitsMultipleResourcesDefault['createResourceAllocations']
        }
      | {
          type: 'goToStart'
        },

    context: {} as {
      resourcesToAllocateIds?: number[]
      error: string | undefined
      errorDetails: string[] | undefined
    },
  },

  actions: {
    close: () => {
      throw new Error('close not implemented')
    },

    showSuccessNotification: () => {
      throw new Error('showSuccessNotification not implemented')
    },
  },

  actors: {
    createResourceAllocations: fromPromise<
      void,
      { variables: EmitsMultipleResourcesDefault['createResourceAllocations'] }
    >(async () => {
      throw new Error('createResourceAllocations not implemented')
    }),
  },
}).createMachine({
  id: 'PmMultiSelectionControllerState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
  },

  states: {
    default: {
      on: {
        createResourceAllocations: 'createResourceAllocations',
      },
    },

    createResourceAllocations: {
      initial: 'saving',

      states: {
        saving: {
          invoke: {
            id: 'createResourceAllocations',
            src: 'createResourceAllocations',
            input: ({ event }) => {
              assertEvent(event, 'createResourceAllocations')
              return { variables: event.variables }
            },
            onDone: {
              target:
                '#PmMultiSelectionControllerState.createResourceAllocations.success',
            },
            onError: {
              target:
                '#PmMultiSelectionControllerState.createResourceAllocations.failed',
              actions: ({ context, event }) => {
                assertFriendlyError(event.error)
                context.error = event.error.message
                context.errorDetails = event.error.details
              },
            },
          },
        },

        success: {
          type: 'final',
          entry: ['close', 'showSuccessNotification'],
        },

        failed: {
          on: {
            goToStart: '#PmMultiSelectionControllerState.default',
          },
        },
      },
    },
  },
})
