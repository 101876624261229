<template>
  <PmTableGroupPure class="PmMultipleResourceAllocationsRequestGroupPure">
    <template #group>
      <div class="PmMultipleResourceAllocationsRequestGroupPure-header">
        <div class="PmMultipleResourceAllocationsRequestGroupPure-inner">
          <div class="PmMultipleResourceAllocationsRequestGroupPure-content">
            <strong>{{ name }}</strong>
            <template v-if="project">
              für Projekt „{{ project.title }}”
            </template>
          </div>

          <div class="PmMultipleResourceAllocationsRequestGroupPure-status">
            <PmLoadingPure v-if="state === 'requesting'" size="small" />

            <template v-else>
              <template v-if="state === 'error'">
                <PmButtonPure
                  v-if="false"
                  class="PmMultipleResourceAllocationsRequestGroupPure-button"
                  label="Erneut versuchen"
                  icon="reload"
                />

                <PmIconPure
                  name="alert"
                  class="PmMultipleResourceAllocationsRequestGroupPure-statusIcon is-error"
                />
              </template>

              <PmIconPure
                v-if="state === 'success'"
                name="check"
                class="PmMultipleResourceAllocationsRequestGroupPure-statusIcon is-success"
              />
            </template>
          </div>
        </div>

        <PmErrorNotificationPure
          v-if="state === 'error' && errorMessage"
          :message="errorMessage"
          :details="errorDetails"
          class="PmMultipleResourceAllocationsRequestGroupPure-error"
        />
      </div>
    </template>

    <slot />
  </PmTableGroupPure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmMultipleResourceAllocationsRequestGroupPure'

export const propTypes = {
  state: {
    allowed: ['requesting', 'error', 'success'],
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import PmTableGroupPure from '@/components/basics/PmTable/PmTableGroupPure.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmErrorNotificationPure, {
  type Props as PropsErrorNotificationPure,
} from '@/components/basics/PmErrorNotificationPure.vue'

export interface Props {
  name?: string
  project?: Project
  state?: (typeof propTypes.state.allowed)[number]
  errorMessage?: PropsErrorNotificationPure['message']
  errorDetails?: PropsErrorNotificationPure['details']
}

export type Project = {
  number?: string
  title?: string
}

const props = withDefaults(defineProps<Props>(), {
  name: undefined,
  project: undefined,
  state: undefined,
})
</script>

<style lang="scss">
.PmMultipleResourceAllocationsRequestGroupPure {
  $block: &;
  $parent: '.PmTableGroupPure';

  @include cssVar.define(
    $block,
    'cellPadding',
    cssVar.use($parent, 'cellPadding')
  );

  &-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-inner {
    display: flex;
    justify-content: space-between;
  }

  &-content {
    text-align: left;
  }

  &-status {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: calc(cssVar.use($parent, 'cellPadding') * -1);
    margin-bottom: calc(cssVar.use($parent, 'cellPadding') * -1);
  }

  &-statusIcon {
    width: 20px;
    height: 20px;

    &.is-error {
      color: color.$danger-600;
    }

    &.is-success {
      color: color.$success-600;
    }
  }
}
</style>
