import { setup } from 'xstate5'

export const PmMultipleResourcesAllocatePureState = setup({
  types: {
    meta: {} as {
      error?: boolean
      message?: string
    },
  },
}).createMachine({
  id: 'PmMultipleResourcesAllocatePureState',
  initial: 'default',

  states: {
    default: {},
    saving: {},

    success: {
      meta: {
        message: 'Die Ressourcen-Zuordnungen wurden erfolgreich erstellt',
      },
    },

    failed: {
      meta: {
        error: true,
        message:
          'Es gab einen unbekannten Fehler beim Erstellen der Ressourcen-Zuordnungen',
      },
    },
  },
})
