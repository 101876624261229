<template>
  <PmResourceDropzonePure
    :resources="resourcesNormalized"
    :resource-type="resourceType"
    @remove="remove"
    @add="add"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import type { Get } from 'type-fest'

import type { ResourceType } from '@/constants/persoplan'
import { AddressesDocument, VehiclesDocument } from '@/../generated/graphql'
import PmResourceDropzonePure, {
  type Props as PropsResourceDropzonePure,
  type Emits as EmitsResourceDropzonePure,
} from '@/components/persoplan/PmResourceDropzone/PmResourceDropzonePure.vue'
import { getDisplayNameOfAddress } from '@/utilities/string'

export interface Props {
  resourceType: Exclude<ResourceType, 'freelancer'>
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  remove: [id: number]
  add: [id: number]
}>()

const resourceIds = defineModel<Set<number>>('resourceIds', {
  default: new Set(),
})

type ResourceNormalized = NonNullable<
  Get<PropsResourceDropzonePure, 'resources'>
>[0]

const resourcesNormalized = computed(() => {
  if (props.resourceType === 'address') return addressesNormalized.value
  if (props.resourceType === 'vehicle') return vehiclesNormalized.value
  return undefined
})

/**
 * Load and normalize addresses
 */
const addressesQuery = useQuery(
  AddressesDocument,
  () => ({
    addressIds: Array.from(resourceIds.value),
  }),
  () => ({
    enabled: resourceIds.value && resourceIds.value.size > 0,
  })
)

const addressesNormalized = computed(() => {
  const result: ResourceNormalized[] = []
  if (!resourceIds.value.size) return result

  addressesQuery.result.value?.addresses?.forEach((address) => {
    if (!address) return

    const item: ResourceNormalized = {
      id: address.id,
      name: getDisplayNameOfAddress(address) ?? 'n/a',
    }

    result.push(item)
  })

  return result
})

/**
 * Load and normalize vehicles
 */
const vehiclesQuery = useQuery(
  VehiclesDocument,
  () => ({
    vehicleIds: Array.from(resourceIds.value),
  }),
  () => ({
    enabled: resourceIds.value && resourceIds.value.size > 0,
  })
)

const vehiclesNormalized = computed(() => {
  const result: ResourceNormalized[] = []
  if (!resourceIds.value.size) return result

  vehiclesQuery.result.value?.vehicles?.forEach((vehicle) => {
    if (!vehicle) return

    const item: ResourceNormalized = {
      id: vehicle.id,
      name: vehicle.caption ?? 'n/a',
    }

    result.push(item)
  })

  return result
})

function add(payload: EmitsResourceDropzonePure['add']) {
  resourceIds.value.add(payload.id)
}

function remove(payload: EmitsResourceDropzonePure['remove']) {
  resourceIds.value.delete(payload.id)
}
</script>

<style lang="scss">
.PmResourceDropzone {
  $block: &;
}
</style>
