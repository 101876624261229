<template>
  <div :class="componentClass.root">
    <div
      v-if="xstate.snapshot.matches('saving')"
      :class="componentClass.element('loading')"
    >
      <PmLoadingPure size="default" label="Zuordnungen werden durchgeführt" />
    </div>

    <template v-if="xstate.snapshot.matches('success')">
      <PmAppNotificationPure
        class="PmMultiSelectionAllocatePure-notification"
        variant="success"
        layout="compact"
        :timeout="3"
        @timeout-complete="emit('goToStart')"
      >
        {{ xstate.meta.value?.message }}
      </PmAppNotificationPure>

      <div :class="componentClass.element('control')">
        <PmButtonListPure align="right">
          <PmButtonPure
            label="Zurück zum Start"
            icon="reset"
            @click="emit('goToStart')"
          />
        </PmButtonListPure>
      </div>
    </template>

    <template v-if="xstate.meta.value?.error">
      <PmErrorNotificationPure
        class="PmMultiSelectionAllocatePure-notification"
        :message="errorMessage || xstate.meta.value.message"
        :details="errorDetails"
      />

      <div :class="componentClass.element('control')">
        <PmButtonPure
          label="Zurück"
          icon="arrowLeft"
          @click="emit('goToStart')"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { toRef } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'

import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import { useXState, type StatePathOrValue } from '@/composition/useXState5'
import { PmMultipleResourcesAllocatePureState } from '@/components/persoplan/PmMultiSelectionController/PmMultipleResourcesAllocate/PmMultipleResourcesAllocatePureState'
import PmAppNotificationPure from '@/components/basics/PmAppNotification/PmAppNotificationPure.vue'
import PmErrorNotificationPure from '@/components/basics/PmErrorNotificationPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'

export interface Props {
  state?: StatePathOrValue<typeof PmMultipleResourcesAllocatePureState>
  errorMessage?: string
  errorDetails?: string[]
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  goToStart: []
}>()

const componentClass = useComponentClass()

const xstate = useXState(PmMultipleResourcesAllocatePureState, {
  syncStateWith: toRef(props, 'state'),
})
</script>

<style lang="scss">
.PmMultipleResourcesAllocatePure {
  $block: &;

  width: 100%;

  &-loading {
    padding-block: 28px;
    display: flex;
    justify-content: center;
  }

  &-control {
    margin-top: 16px;
  }
}
</style>
