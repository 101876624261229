<template>
  <PmInputContainerPure
    :label="label"
    :error-message="errorMessage"
    :invalid-message="formValidation.invalidMessage.value"
  >
    <div :class="[componentClass.root, classes]">
      <PmRadioPure
        v-for="item in items"
        ref="elsRadio"
        :key="item.id"
        v-bind="item"
        :name="name"
        :checked="item.id === checkedId"
        :required="required"
        :trigger-validity-check="triggerValidityCheck"
        :disabled="isDisabledNormalized"
        @check="onCheck"
        @invalid="onInvalid"
      />
    </div>
  </PmInputContainerPure>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'

import { useFormValidation } from '@/composition/useFormValidation'
import PmInputContainerPure, {
  type Props as PropsInputContainerPure,
} from '@/components/basics/PmInputContainer/PmInputContainerPure.vue'
import PmRadioPure, {
  type Props as PropsRadioPure,
} from '@/components/basics/PmRadio/PmRadioPure.vue'
import { useForm } from '@/composition/useForm'

export interface Props extends PropsInputContainerPure {
  name: string
  required?: boolean
  items?: Omit<PropsRadioPure, 'name'>[]
  disabled?: boolean
  layout?: 'horizontal' | 'vertical'
}

const props = withDefaults(defineProps<Props>(), {
  layout: 'vertical',
})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass({ props })
const checkedId = defineModel<PropsRadioPure['id']>('checkedId')
const triggerValidityCheck = ref(0)

const elsRadio = ref<InstanceType<typeof PmRadioPure>[] | null>()
const elFirstRadio = computed(() => {
  return elsRadio.value?.[0].elInput
})

function onInvalid(event: Event) {
  formValidation.onInvalid(event)
}

const formValidation = useFormValidation({
  elInput: elFirstRadio,
  type: 'radio',
})

function onCheck(id: string) {
  checkedId.value = id
  triggerValidityCheck.value += 1

  formValidation.checkValidity()
}

/**
 * Disabled state
 */
const form = useForm()
const isDisabledNormalized = computed(() => {
  if (props.disabled) return true
  if (form.disabled?.value) return true
  return undefined
})

const classes = computed(() => {
  return [componentClass.propModifier({ name: 'layout' })]
})
</script>

<style lang="scss">
.PmRadioListPure {
  $block: &;

  &--layoutHorizontal {
    display: flex;
    gap: var(--space-gutters);
  }
}
</style>
