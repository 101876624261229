<template>
  <PmGeneralPageSectionPure title="Meine Anträge" width="full">
    <template #control>
      <PmButtonWithActionsPure
        label="Neuer Antrag"
        icon="plus"
        :has-primary-action="false"
        :actions="[
          {
            id: 'createLeaveRequest',
            icon: 'vacation',
            label: 'Urlaub',
          },
          {
            id: 'createExternalServiceRequest',
            icon: 'alien',
            label: 'Fremddienstleistung',
          },
          {
            id: 'createExpenseReport',
            icon: 'receiptEuro',
            label: 'Spesenabrechnung',
          },
        ]"
        @create-leave-request="isCreateLeaveRequestVisible = true"
        @create-external-service-request="
          isCreateExternalServiceRequestVisible = true
        "
        @create-expense-report="isCreateExpenseReportVisible = true"
      />
    </template>

    <PmMyRequestsList @open-details="openDetails" @abort="onAbort" />

    <PmModalPure
      v-if="isDetailsVisible && visibleDetailsId && visibleDetailsType"
      :title="visibleDetailsTitle"
      @close="isDetailsVisible = false"
    >
      <PmRequestDetail :id="visibleDetailsId" :type="visibleDetailsType" />
    </PmModalPure>

    <PmCreateLeaveRequest
      v-if="isCreateLeaveRequestVisible"
      @close="isCreateLeaveRequestVisible = false"
    />

    <PmCreateExternalServiceRequest
      v-if="isCreateExternalServiceRequestVisible"
      @close="isCreateExternalServiceRequestVisible = false"
    />

    <PmCreateExpenseReport
      v-if="isCreateExpenseReportVisible"
      @close="isCreateExpenseReportVisible = false"
    />

    <PmDialogConfirmPure
      v-if="xstate.snapshot.matches('abort') && abortSubject"
      :subject="abortSubject"
      action-label="Zurückziehen"
      :is-loading="xstate.snapshot.matches({ abort: 'saving' })"
      :error-message="xstate.snapshot.context.error"
      :error-details="xstate.snapshot.context.errorDetails"
      @cancel="xstate.send({ type: 'cancel' })"
      @confirm="xstate.send({ type: 'confirmAbort' })"
    />
  </PmGeneralPageSectionPure>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import { useStore } from 'vuex'

import PmMyRequestsList, {
  type Emits as EmitsMyRequestsList,
} from '@/components/PmMyRequests/PmMyRequestsList.vue'
import PmGeneralPageSectionPure from '@/components/PmGeneralPage/PmGeneralPageSectionPure.vue'
import PmButtonWithActionsPure from '@/components/basics/PmButtonWithActionsPure.vue'
import PmCreateLeaveRequest from '@/components/PmCreateLeaveRequest/PmCreateLeaveRequest.vue'
import PmRequestDetail from '@/components/PmRequestDetail/PmRequestDetail.vue'
import PmCreateExternalServiceRequest from '@/components/PmCreateExternalServiceRequest/PmCreateExternalServiceRequest.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'
import { useXState } from '@/composition/useXState5'
import { PmMyRequestsState } from '@/components/PmMyRequests/PmMyRequestsState'
import { fromPromise } from 'xstate5'
import PmDialogConfirmPure from '@/components/basics/PmDialog/PmDialogConfirmPure.vue'
import {
  AbortExternalServiceRequestDocument,
  AbortLeaveRequestDocument,
  type AbortLeaveRequestMutationVariables,
} from '@/../generated/graphql'
import { throwFriendlyError } from '@/functional/friendlyErrors'
import { FEATURE_FLAG } from '@/constants/featureFlags'
import PmCreateExpenseReport from '@/components/PmCreateExpenseReport/PmCreateExpenseReport.vue'

const store = useStore()

const xstate = useXState(
  PmMyRequestsState.provide({
    actions: {
      showAbortSuccessNotification: () => {
        store.commit('notification/add', {
          variant: 'success',
          title: 'Antrag zurückgezogen',
        })
      },
    },

    actors: {
      abortRequest: fromPromise(async ({ input }) =>
        abortRequest(input.variables)
      ),
    },
  })
)

const isCreateLeaveRequestVisible = ref(false)
const isCreateExternalServiceRequestVisible = ref(false)
const isCreateExpenseReportVisible = ref(false)
const isDetailsVisible = ref(false)
const visibleDetailsId = ref<number>()
const visibleDetailsType = ref<
  'leaveRequest' | 'externalServiceRequest' | 'expenseReport'
>()

function openDetails(payload: EmitsMyRequestsList['openDetails']) {
  isDetailsVisible.value = true
  visibleDetailsId.value = payload.id
  visibleDetailsType.value = payload.requestType
}

const visibleDetailsTitle = computed(() => {
  if (visibleDetailsType.value === 'leaveRequest') return 'Urlaubsantrag'
  if (visibleDetailsType.value === 'externalServiceRequest')
    return 'Antrag Fremddienstleistung'
  if (visibleDetailsType.value === 'expenseReport') return 'Spesenabrechnung'

  return undefined
})

/**
 * Abort
 */
const abortLeaveRequestMutation = useMutation(AbortLeaveRequestDocument)

const abortSubject = computed(() => {
  const requestType = xstate.value.snapshot.context.requestType
  if (requestType === 'leaveRequest') return 'Urlaubsantrag'
  if (requestType === 'externalServiceRequest')
    return 'Antrag Fremddienstleistung'

  return undefined
})

async function abortLeaveRequest(
  variables: AbortLeaveRequestMutationVariables
) {
  try {
    await abortLeaveRequestMutation.mutate(variables)
  } catch (error) {
    throwFriendlyError(error)
  }
}

const abortExternalServiceRequestMutation = useMutation(
  AbortExternalServiceRequestDocument
)

async function abortExternalServiceRequest(
  variables: AbortLeaveRequestMutationVariables
) {
  try {
    await abortExternalServiceRequestMutation.mutate(variables)
  } catch (error) {
    throwFriendlyError(error)
  }
}

async function abortRequest(options: {
  id: number
  type: 'leaveRequest' | 'externalServiceRequest'
}) {
  if (options.type == 'leaveRequest') {
    return abortLeaveRequest({ id: options.id })
  }

  if (options.type === 'externalServiceRequest') {
    return abortExternalServiceRequest({ id: options.id })
  }
}

function onAbort(payload: EmitsMyRequestsList['abort']) {
  xstate.value.send({
    type: 'abort',
    variables: {
      id: payload.id,
      type: payload.requestType,
    },
  })
}
</script>
