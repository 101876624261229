<template>
  <PmResourcePure
    v-if="resource"
    v-bind="normalizedResource"
    @create-resource-allocation="createResourceAllocation"
    @search="setActionbarFilter"
    @select="onSelect"
    @unselect="onUnselect"
    @show-details="showDetails"
  >
    <template #default="props">
      <template v-if="!resourceAllocations">
        <PmResourceAllocationPure
          v-for="index in numberOfSkeletons"
          :id="`skeleton.${index}`"
          :key="index"
          type="skeleton"
          :class="props.classes"
          :is-skeleton="true"
        />
      </template>

      <template v-for="resourceAllocation in resourceAllocations">
        <PmResourceAllocation
          v-if="resourceAllocation && type"
          :id="resourceAllocation.id"
          :key="resourceAllocation.id"
          :class="props.classes"
          :type="type"
          :edit-button-visible="editButtonsVisible"
          :container-start-date="containerStartDate"
        />
      </template>

      <div
        v-if="xstate.state.value.matches('createResourceAllocation')"
        class="PmResource-createSkeleton"
      >
        <div ref="popoverElement" class="PmResource-popoverElement"></div>

        <PmResourceAllocationPure
          id="createSkeleton"
          type="skeleton"
          :class="props.classes"
          :is-skeleton="true"
          title="Blupp"
        />

        <PmResourceAllocationEdit
          v-if="type"
          :resource-id="id"
          :address-id="xstate.state.value.context.addressId"
          :vehicle-id="xstate.state.value.context.vehicleId"
          :type="type"
          :popover-element="popoverElement"
          @close="xstate.service.value.send('FINISH')"
          @create-resource-request="
            (payload) => {
              createResourceRequestId = payload.resourceAllocationId
              isCreateResourceRequestVisible = true
            }
          "
        />
      </div>

      <PmMultipleResourceAllocationsRequest
        v-if="isCreateResourceRequestVisible && createResourceRequestId"
        :resource-allocation-ids="[createResourceRequestId]"
        @close="
          () => {
            createResourceRequestId = undefined
            isCreateResourceRequestVisible = false
          }
        "
      />
    </template>
  </PmResourcePure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmResource'

export const propTypes = {} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { ref, computed, toRef } from 'vue'
import { find, orderBy } from 'lodash-es'
import { isSameMinute } from 'date-fns'
import { useStore } from 'vuex'
import type { FixedLengthArray } from 'type-fest'

import {
  RESOURCE_TYPE_LOOKUP,
  RESOURCE_TYPE,
  DATA_MODAL_TYPE,
  type ResourceAllocationStatus,
  STATUS_RESOURCE_ALLOCATION_LOOKUP,
  STATUS_RESOURCE_ALLOCATION_SORT_ORDER_CALENDAR,
} from '@/constants/persoplan'
import { ICONS } from '@/constants/icons'
import { EVENT } from '@/constants/events'
import { parseServerDateString } from '@/utilities/date'
import useLazyloadedResourceDetails from '@/composition/useLazyloadedResourceDetails'
import EventHub from '@/eventHub'
import { persoplanStateKey, injectStrict } from '@/utilities/inject'
import { lookup } from '@/utilities/misc'
import { useCachedQuery } from '@/composition/useCachedQuery'
import { useXState } from '@/composition/useXState'
import { PmResourceState } from '@/components/persoplan/PmResource/PmResourceState'
import { useSelectionResourceAllocations } from '@/pinia/selectionResourceAllocations'
import PmResourcePure from '@/components/persoplan/PmResource/PmResourcePure.vue'
import PmResourceAllocation from '@/components/persoplan/PmResourceAllocation/PmResourceAllocation.vue'
import PmResourceAllocationPure from '@/components/persoplan/PmResourceAllocation/PmResourceAllocationPure.vue'
import PmResourceAllocationEdit from '@/components/persoplan/PmResourceAllocation/PmResourceAllocationEdit.vue'
import { ResourceDocument } from '@/../generated/graphql'
import { useSelectionResourcesItem } from '@/composition/useSelectionResources'
import PmMultipleResourceAllocationsRequest from '@/components/persoplan/PmMultipleResourceAllocationsRequest/PmMultipleResourceAllocationsRequest.vue'

export interface Props {
  id: number
  editButtonsVisible?: boolean
  containerStartDate: Date
  isInViewport?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isInViewport: true,
})

const store = useStore()
const persoplanState = injectStrict(persoplanStateKey)
const popoverElement = ref()

const xstate = useXState(PmResourceState)
const selectionResourceAllocations = useSelectionResourceAllocations()

/**
 * Start resource request after creating an new allocation
 */
const isCreateResourceRequestVisible = ref(false)
const createResourceRequestId = ref<number>()

useLazyloadedResourceDetails({
  id: toRef(props, 'id'),
  isInViewport: toRef(props, 'isInViewport'),
})

const resourceQuery = useCachedQuery(ResourceDocument, () => {
  const queryVariables = store.getters['queryVariables/calendar']

  return {
    ...queryVariables,
    id: props.id,
  }
})

const resource = computed(() => resourceQuery.result?.value?.resource)

const type = computed(() => {
  const resourceTypeId = resource.value?.resourceFunction?.resourceType?.id
  if (!resourceTypeId) return undefined

  return lookup(resourceTypeId, RESOURCE_TYPE_LOOKUP)
})

const selectionResourcesItem = useSelectionResourcesItem({
  id: props.id,
  type: type,
})

const resourceAllocations = computed(() => {
  if (!resource.value?.resourceAllocations) return

  const resourceAllocations = resource.value?.resourceAllocations

  // Sort by status and and name
  const result = orderBy(
    resourceAllocations,
    [
      // Status
      (resourceAllocation) => {
        const statusId = resourceAllocation?.resourceAllocationState?.id
        if (!statusId) {
          console.log('no status id')

          return -1
        }

        const status = lookup(statusId, STATUS_RESOURCE_ALLOCATION_LOOKUP)
        if (!status) {
          console.log('no status lookup result')

          return -1
        }

        const index =
          STATUS_RESOURCE_ALLOCATION_SORT_ORDER_CALENDAR.indexOf(status)
        return index
      },

      // Last name
      (resourceAllocation) => {
        return resourceAllocation?.address?.surname
      },

      // First name
      (resourceAllocation) => {
        return resourceAllocation?.address?.firstName
      },

      // Id to make it stable in case previous sort order is not unique
      'id',
    ],
    ['asc', 'asc', 'asc', 'asc']
  )

  return result
})

const createResourceAllocation = ({
  id,
  resourceType,
}: {
  id: number
  resourceType: 'address' | 'vehicle'
}) => {
  if (
    type.value === RESOURCE_TYPE.ADDRESS &&
    resourceType === RESOURCE_TYPE.ADDRESS
  ) {
    return xstate.service.value.send('CREATE_RESOURCE_ALLOCATION_ADDRESS', {
      addressId: id,
    })
  }

  if (
    type.value === RESOURCE_TYPE.VEHICLE &&
    resourceType === RESOURCE_TYPE.VEHICLE
  ) {
    return xstate.service.value.send('CREATE_RESOURCE_ALLOCATION_VEHICLE', {
      vehicleId: id,
    })
  }

  throw new Error('createResourceAllocation: No type defined')
}

function onSelect() {
  selectionResourcesItem.add()
}

function onUnselect() {
  selectionResourcesItem.remove()
}

function setActionbarFilter() {
  const settings = {
    type: type.value,
    resourceFunctionId: resource.value?.resourceFunction?.id,
  }

  EventHub.$emit(EVENT.ACTIONBAR_SET_FILTER, settings)
}

function showDetails() {
  EventHub.$emit(EVENT.DATA_MODAL_SHOW, {
    type: DATA_MODAL_TYPE.RESOURCE,
    id: props.id,
  })
}

const normalizedResource = computed(() => {
  return {
    startDate: startDate.value,
    endDate: endDate.value,
    shortText: shortText.value,
    quantityNeeded: resource.value?.quantity,
    quantityAchieved: numberOfConfirmedResourceAllocations.value,
    quantityUnclear: quantityUnclear.value,
    icon: icon.value,
    type: type.value,
    selectVisible: selectVisible.value,
    canBeSelected: canBeSelected.value,
    hasSearchButton: hasSearchButton.value,
    isSelected: isSelected.value,
    isLocked: resource.value?.timesScheduled,
    isTimeVisible: isTimeVisible.value,
  }
})

const startDate = computed(() => {
  return parseServerDateString(resource.value?.startDate)
})

const endDate = computed(() => {
  return parseServerDateString(resource.value?.endDate)
})

const isTimeVisible = computed(() => {
  if (!startDate.value) return false
  if (!endDate.value) return false

  const appointmentStartDate = parseServerDateString(
    resource.value?.jobAppointment?.startDate
  )
  const appointmentEndDate = parseServerDateString(
    resource.value?.jobAppointment?.endDate
  )

  if (!appointmentStartDate) return
  if (!appointmentEndDate) return

  const startDateIsSame = isSameMinute(startDate.value, appointmentStartDate)

  const endDateIsSame = isSameMinute(endDate.value, appointmentEndDate)

  if (!startDateIsSame || !endDateIsSame) return true
  return false
})

const shortText = computed(() => {
  const abbreviation = resource.value?.resourceFunction?.abbreviation
  return abbreviation ? abbreviation : resource.value?.resourceFunction?.caption
})

const numberOfConfirmedResourceAllocations = computed(() => {
  const entry = find(resource.value?.allocationStatistics, {
    key: 'Bestätigt',
  })
  return entry?.value ? parseInt(entry.value) : 0
})

const numberOfRequestedResourceAllocations = computed(() => {
  const entry = find(resource.value?.allocationStatistics, {
    key: 'Anfrage',
  })
  return entry?.value ? parseInt(entry.value) : 0
})

const numberOfConsideredResourceAllocations = computed(() => {
  const entry = find(resource.value?.allocationStatistics, {
    key: 'Angedacht',
  })
  return entry?.value ? parseInt(entry.value) : 0
})

const numberOfReservedResourceAllocations = computed(() => {
  const entry = find(resource.value?.allocationStatistics, {
    key: 'Reserviert',
  })
  return entry?.value ? parseInt(entry.value) : 0
})

const numberOfCancelledResourceAllocations = computed(() => {
  const entry = find(resource.value?.allocationStatistics, {
    key: 'Abgesagt',
  })
  return entry?.value ? parseInt(entry.value) : 0
})

const numberOfSkeletons = computed(() => {
  /**
   *  @todo: Include resources only when active in view settings
   */
  return (
    numberOfConfirmedResourceAllocations.value +
    numberOfRequestedResourceAllocations.value +
    numberOfConsideredResourceAllocations.value +
    numberOfReservedResourceAllocations.value
  )
})

const quantityUnclear = computed(() => {
  return (
    numberOfRequestedResourceAllocations.value +
    numberOfConsideredResourceAllocations.value
  )
})

const icon = computed(() => {
  if (type.value === RESOURCE_TYPE.VEHICLE) return ICONS.VEHICLE
  return undefined
})

const selectVisible = computed(() => {
  if (selectionResourceAllocations.isInSelectionMode) return false
  if (persoplanState.state.value.matches('edit')) return true

  return false
})

const canBeSelected = computed(() => {
  if (selectionResourcesItem.selectedType.value === undefined) return true
  return selectionResourcesItem.selectedType.value === type.value
})

const isSelected = computed(() => {
  return selectionResourcesItem.isSelected.value
})

const hasSearchButton = computed(() => {
  if (persoplanState.state.value.matches('edit')) return true

  return false
})

const createSkeletonVisibleNormalized = computed(() => {
  if (store.state.persoplan.resourceShowSkeletonIds.includes(props.id)) {
    return true
  }

  return false
})
</script>

<style lang="scss">
.PmResource {
  $block: &;

  &-createSkeleton {
    position: relative;
  }

  &-popoverElement {
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
