<template>
  <div :class="componentClass.root">
    <div :class="componentClass.element('container')">
      <div :class="componentClass.element('content')">
        <PmStatusPure
          v-if="xstate.state.value.matches('checkIfLoggedIn')"
          :icon="ICONS.LOCK_OPEN"
        >
          Prüfe Anmeldung
        </PmStatusPure>

        <PmStatusPure
          v-if="xstate.state.value.matches('autoLogin')"
          :icon="ICONS.LOCK_OPEN"
        >
          Versuche automatische Anmeldung
        </PmStatusPure>

        <PmStatusPure
          v-if="xstate.state.value.matches('login.success')"
          :icon="ICONS.CHECK"
        >
          Anmeldung erfolgreich
        </PmStatusPure>

        <PmLoginFormPure
          v-if="loginFormVisible"
          :class="componentClass.element('loginForm')"
          :state="loginFormState"
          :error-message="errorMessage"
          :error-details="errorDetails"
          @login="(payload) => emit('loginWithCredentials', payload)"
        />
      </div>
    </div>

    <div :class="componentClass.element('sponsor')">
      <img :src="sponsorLogoUrl" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'

import { ICONS } from '@/constants/icons'
import { PmLoginPureState } from '@/views/PmLogin/PmLoginPureState'
import type { TState } from '@/views/PmLogin/PmLoginPureState'
import { useXState } from '@/composition/useXState'
import PmLoginFormPure, {
  type Emits as EmitsLoginFormPure,
} from '@/components/PmLogin/PmLoginFormPure.vue'
import PmStatusPure from '@/components/basics/PmStatusPure.vue'
import sponsorLogoUrl from '@/assets/images/BMWi_Fz_2021_WebSVG_de.svg?url'

export interface Props {
  state?: TState
  errorMessage?: string
  errorDetails?: string[]
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  loginWithCredentials: EmitsLoginFormPure['login']
}

const emit = defineEmits<{
  loginWithCredentials: [Emits['loginWithCredentials']]
}>()

const componentClass = useComponentClass()

const xstate = useXState(PmLoginPureState, {
  syncStateWith: toRef(props, 'state'),
})

const loginFormState = computed(() => {
  if (xstate.state.value.matches('login.default')) return 'default'
  if (xstate.state.value.matches('login.loggingIn')) return 'loggingIn'
  if (xstate.state.value.matches('login.success')) return 'success'
  if (xstate.state.value.matches('login.failed')) return 'failed'

  return undefined
})

const loginFormVisible = computed(() => {
  if (xstate.state.value.matches('login.success')) return false
  if (xstate.state.value.matches('login')) return true

  return false
})
</script>

<style lang="scss">
@use '@/assets/scss/shadows.scss' as shadow;

.PmLoginPure {
  $block: &;

  background-color: color.$gray-300;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // min-height: -webkit-fill-available; // Does not seem to work correctly in Chrome
    padding-left: var(--space-default);
    padding-right: var(--space-default);
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 1;

    &::before {
      content: '';
      flex-grow: 1;
    }

    &::after {
      content: '';
      flex-grow: 1.25;
    }
  }

  &-content {
    @include shadow.default('medium');

    padding: var(--space-small);
    background-color: color.$white;
    border-radius: constant.$borderRadius-large;
    width: 100%;
    max-width: 600px;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-loginForm {
    width: 100%;
  }

  &-sponsor {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: var(--space-smallest);
  }
}
</style>
