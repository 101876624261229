<template>
  <PmDropdownPure
    v-model:value="selectedJobId"
    :options="optionsNormalized"
    :label="label ?? 'Job'"
    :required="required"
    :is-loading="isLoading"
    @input-searchterm="(value) => (searchTerm = value)"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { isNil, uniqBy } from 'lodash-es'

import {
  DropdownJobDataDocument,
  DropdownJobSearchQueryDocument,
} from '@/../generated/graphql'
import PmDropdownPure, {
  type Props as PropsDropdownPure,
} from '@/components/basics/PmDropdownPure.vue'
import { parseServerDateString } from '@/utilities/date'

export interface Props extends Pick<PropsDropdownPure, 'required' | 'label'> {}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const searchTerm = ref<string>()
const selectedJobId = defineModel<number>('selectedJobId')

const hasSearchTerm = computed(() => {
  if (isNil(searchTerm.value)) return false
  const hasMinLength = searchTerm.value.trim().length >= 3
  return hasMinLength
})

const jobSearchQuery = useQuery(
  DropdownJobSearchQueryDocument,
  () => ({
    searchterm: searchTerm.value,
  }),
  () => ({
    enabled: hasSearchTerm.value,
    debounce: 250,
  })
)

const jobQuery = useQuery(
  DropdownJobDataDocument,
  // @ts-expect-error https://github.com/vuejs/apollo/issues/1243
  () => ({
    id: selectedJobId.value,
  }),
  () => ({
    enabled: Boolean(selectedJobId.value),
  })
)

const isLoading = computed(() => {
  if (jobSearchQuery.loading.value === true) return true
  if (jobQuery.loading.value === true) return true

  return false
})

const selectedJobNormalized = computed(() => {
  if (!jobQuery.result.value?.job) return
  if (!selectedJobId.value) return

  return {
    id: jobQuery.result.value.job.id,
    label: jobQuery.result.value.job.caption,
    number: jobQuery.result.value.job.number,
    startDate: parseServerDateString(jobQuery.result.value.job.startDate),
    endDate: parseServerDateString(jobQuery.result.value.job.endDate),
  }
})

const optionsNormalized = computed(() => {
  const searchResults =
    jobSearchQuery.result.value?.jobs?.reduce(
      (result: NonNullable<PropsDropdownPure['options']>, job) => {
        if (!job) return result

        result.push({
          id: job.id,
          label: job.caption,
          number: job.number,
        })

        return result
      },
      []
    ) ?? []

  const jobs: PropsDropdownPure['options'] = []

  if (selectedJobNormalized.value) {
    jobs.push(selectedJobNormalized.value)
  }

  return uniqBy([...searchResults, ...jobs], 'id')
})
</script>
