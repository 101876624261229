<template>
  <div :class="[componentClass.root, classes]">
    <PmPillPure
      v-if="pill.label"
      v-bind="pill"
      :title="label ?? undefined"
      :class="componentClass.element('pill')"
    />
    <div :class="componentClass.element('label')">
      {{ label }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import PmPillPure, {
  type Props as PropsPillPure,
} from '@/components/basics/PmPillPure.vue'

import type { Nilable } from '@/types/misc'
import { useComponentClass } from '@thomasaull-shared/composables'

export interface Props {
  pill: PropsPillPure
  label?: Nilable<string>
  multiline?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'example', id: string): void
}>()

const componentClass = useComponentClass({ props })

const classes = computed(() => {
  return [componentClass.propModifier({ name: 'multiline' })]
})
</script>

<style lang="scss">
.PmPillWithLabelPure {
  $block: &;

  display: flex;
  position: relative;
  gap: 4px;
  align-items: start;

  @container (width < 300px) {
    flex-direction: column;
  }

  &-pill {
    flex-shrink: 0;
  }

  &-label {
    #{$block}--hasNotMultiline & {
      @include mixin.truncateText;
    }

    padding-top: 0.1em;
  }
}
</style>
