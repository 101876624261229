<template>
  <PmMultipleResourceAllocationsUpdatePure
    :type="type"
    :status-options="resourceAllocationStatesNormalized"
    :state="xstate.path.value"
    :number-of-items="numberOfItems"
    :index-currently-updating="indexCurrentlyUpdating"
    :error-message="xstate.state.value.context.error"
    :error-details="xstate.state.value.context.errorDetails"
    @update:values-to-change="(values) => (valuesToChange = values)"
    @confirm-and-close="
      xstate.service.value.send({
        type: 'UPDATE_RESOURCE_ALLOCATIONS',
        closeAfterSuccess: true,
      })
    "
    @close="emit('close')"
  >
    <template #table>
      <PmMultipleResourceAllocationsUpdateItemPure
        v-for="resourceAllocation in resourceAllocationsNormalized"
        :key="resourceAllocation.id"
        v-bind="resourceAllocation"
      />
    </template>
  </PmMultipleResourceAllocationsUpdatePure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmMultipleResourceAllocationsUpdate'

export const propTypes = {
  myProp: {
    allowed: ['example', 'anotherOne'],
    default: 'example',
  },
  state: {
    allowed: states,
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { sortBy } from 'lodash-es'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import {
  type ResourceType,
  RESOURCE_TYPE,
  STATUS_RESOURCE_ALLOCATION_SORT_ORDER,
  STATUS_RESOURCE_ALLOCATION_LOOKUP,
} from '@/constants/persoplan'
import { ICONS } from '@/constants/icons'
import { EVENT } from '@/constants/events'

import { useXState } from '@/composition/useXState'
import { lookup } from '@/utilities/misc'
import { FriendlyError, throwFriendlyError } from '@/functional/friendlyErrors'
import EventHub from '@/eventHub'

import {
  PmMultipleResourceAllocationsUpdateState,
  states,
  type State,
} from '@/components/persoplan/PmMultipleResourceAllocationsUpdate/PmMultipleResourceAllocationsUpdateState'

import PmMultipleResourceAllocationsUpdatePure, {
  type ValuesToChange,
} from '@/components/persoplan/PmMultipleResourceAllocationsUpdate/PmMultipleResourceAllocationsUpdatePure.vue'

import PmMultipleResourceAllocationsUpdateItemPure, {
  type Props as PropsMultipleResourceAllocationsUpdateItemPure,
} from '@/components/persoplan/PmMultipleResourceAllocationsUpdate/PmMultipleResourceAllocationsUpdateItemPure.vue'

import type { Nilable } from '@/types/misc'

import {
  ResourceAllocationsDocument,
  UpdateResourceAllocationAddressDocument,
  UpdateResourceAllocationVehicleDocument,
  ResourceAllocationStatesDocument,
} from '@/../generated/graphql'

export interface Props {
  resourceAllocationIds?: number[]
  type: ResourceType
}

const props = withDefaults(defineProps<Props>(), {
  resourceAllocationIds: () => [],
})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const store = useStore()

const xstate = useXState(PmMultipleResourceAllocationsUpdateState, {
  services: {
    updateResourceAllocations: updateResourceAllocations,
  },

  actions: {
    showSuccessNotification: () => {
      store.commit('notification/add', {
        variant: 'success',
        icon: ICONS.CHECK,
        title: t('success', numberOfItems.value),
      })
    },
    emitClose: () => {
      emit('close')
    },
  },
})

const { t } = useI18n({
  messages: {
    de: {
      success:
        'Die Ressourcen-Zuordnung wurde geändert | Die Ressourcen-Zuordnungen wurden geändert',
    },
  },
})

const numberOfItems = computed(() => props.resourceAllocationIds?.length ?? 0)

const resourceAllocationsQuery = useQuery(
  ResourceAllocationsDocument,
  () => ({
    ids: props.resourceAllocationIds,
  }),
  () => ({
    enabled: props.resourceAllocationIds.length > 0,
  })
)

const resourceAllocations = computed(
  () => resourceAllocationsQuery.result.value?.resourceAllocations
)

type ResourceAllocationNormalized =
  PropsMultipleResourceAllocationsUpdateItemPure & {
    id: number
  }

const resourceAllocationsNormalized = computed(() => {
  if (!resourceAllocations.value) return

  const data: ResourceAllocationNormalized[] = []

  resourceAllocations.value.forEach((resourceAllocation) => {
    if (!resourceAllocation) return

    const statusBefore = resourceAllocationStates.value?.find(
      (status) => status?.id === resourceAllocation.resourceAllocationState?.id
    )

    const statusAfter = resourceAllocationStates.value?.find(
      (status) => status?.id === valuesToChange.value.status.value
    )

    data.push({
      id: resourceAllocation.id,

      statusBefore: statusBefore
        ? {
            status: lookup(statusBefore.id, STATUS_RESOURCE_ALLOCATION_LOOKUP),
            label: statusBefore.caption,
          }
        : undefined,

      statusAfter: statusAfter
        ? {
            status: lookup(
              valuesToChange.value.status.value,
              STATUS_RESOURCE_ALLOCATION_LOOKUP
            ),
            label: statusAfter?.caption,
          }
        : undefined,

      noteBefore: resourceAllocation.extra?.notice ?? undefined,
      noteAfter: valuesToChange.value.note.value,
      overwriteNote: valuesToChange.value.note.overwrite,

      travelBefore: resourceAllocation.travel ?? undefined,
      travelAfter: valuesToChange.value.travel.value,
      overwriteTravel: valuesToChange.value.travel.overwrite,

      hotelBefore: resourceAllocation.hotel ?? undefined,
      hotelAfter: valuesToChange.value.hotel.value,
      overwriteHotel: valuesToChange.value.hotel.overwrite,

      state: updateStates.value.get(resourceAllocation.id),
    })
  })

  return data
})

const resourceAllocationStatesQuery = useQuery(ResourceAllocationStatesDocument)

const resourceAllocationStates = computed(
  () => resourceAllocationStatesQuery.result.value?.resourceAllocationStates
)

const resourceAllocationStatesNormalized = computed(() => {
  if (!resourceAllocationStates.value) return []

  const states = resourceAllocationStates.value.reduce<
    {
      id: number
      label: string
    }[]
  >((result, state) => {
    if (!state) return result

    result.push({
      id: state.id,
      label: state.caption,
    })

    return result
  }, [])

  const statesOrdered = sortBy(states, (state) => {
    // TODO: Type it better
    // @ts-expect-error Need better typing
    const index = STATUS_RESOURCE_ALLOCATION_SORT_ORDER.indexOf(state.id)
    return index
  })

  return statesOrdered
})

const valuesToChange = ref<ValuesToChange>({
  status: {
    value: undefined,
  },

  note: {
    value: undefined,
    overwrite: true,
  },

  hotel: {
    value: undefined,
    overwrite: true,
  },

  travel: {
    value: undefined,
    overwrite: true,
  },
})

/**
 * Update Data
 */
const updateStates = ref(
  new Map<number, PropsMultipleResourceAllocationsUpdateItemPure['state']>()
)

const indexCurrentlyUpdating = ref<number>(0)
const hasErrors = ref(false)

async function updateResourceAllocations() {
  if (!resourceAllocations.value) {
    throw new Error('No resource allocations to update')
  }

  // Clear updatStates
  updateStates.value.clear()

  const items: {
    resourceId: number
    resourceAllocationId: number
    noteBefore: Nilable<string>
    travelBefore: Nilable<string>
    hotelBefore: Nilable<string>
  }[] = []

  resourceAllocations.value.forEach((resourceAllocation) => {
    if (!resourceAllocation) throw new Error('resourceAllocation is undefined')

    const resourceId = resourceAllocation.resourceFunctionAllocation?.id
    if (!resourceId) throw new Error('resourceId is undefined')

    const resourceAllocationId = resourceAllocation.id
    if (!resourceAllocationId)
      throw new Error('resourceAllocationId is undefined')

    items.push({
      resourceId,
      resourceAllocationId,
      noteBefore: resourceAllocation.extra?.notice,
      travelBefore: resourceAllocation.travel,
      hotelBefore: resourceAllocation.hotel,
    })
  })

  if (!items.length) throw new Error('No resource allocations to delete')

  indexCurrentlyUpdating.value = 0

  for (const item of items) {
    indexCurrentlyUpdating.value = indexCurrentlyUpdating.value + 1

    try {
      await updateResourceAllocation(item)
    } catch (error) {
      hasErrors.value = true
    }
  }

  // Update Calendar
  EventHub.$emit(EVENT.CACHE_CALENDAR_UPDATE)

  // Throw general error, when there was an error on one of the items
  if (hasErrors.value === true) {
    throw new FriendlyError({
      message:
        'Es gab einen oder mehrere Fehler beim Ändern der Ressourcen-Zuordnungen',
      details: [
        'Die Ressourcen-Zuordnungen mit Problemen wurden in der Liste mit einem Symbol markiert. Bitte prüfe im Kalender ob deine Änderungen durchgeführt wurden und versuche es gegebenenfalls noch einmal',
      ],
    })
  }
}

const updateResourceAllocationAddressMutation = useMutation(
  UpdateResourceAllocationAddressDocument
)
const updateResourceAllocationVehicleMutation = useMutation(
  UpdateResourceAllocationVehicleDocument
)

async function updateResourceAllocation({
  resourceId,
  resourceAllocationId,
  noteBefore,
  travelBefore,
  hotelBefore,
}: {
  resourceId: number
  resourceAllocationId: number
  noteBefore: Nilable<string>
  travelBefore: Nilable<string>
  hotelBefore: Nilable<string>
}) {
  updateStates.value.set(resourceAllocationId, 'updating')

  const noteNormalized =
    valuesToChange.value.note.overwrite === false && noteBefore
      ? noteBefore
      : valuesToChange.value.note.value

  try {
    if (
      props.type === RESOURCE_TYPE.ADDRESS ||
      props.type === RESOURCE_TYPE.FREELANCER
    ) {
      /**
       * We need to do some normalization due to inconsistent behaviour on the API
       * @see https://gitlab.com/pro-musik/graphql-api/-/issues/197
       * REFERENCE: 2u0mcjr
       */
      const travelNormalized =
        valuesToChange.value.travel.overwrite === false && travelBefore
          ? travelBefore
          : valuesToChange.value.travel.value

      const hotelNormalized =
        valuesToChange.value.hotel.overwrite === false && hotelBefore
          ? hotelBefore
          : valuesToChange.value.hotel.value

      await updateResourceAllocationAddressMutation.mutate({
        resourceAllocationId,
        stateId: valuesToChange.value.status.value,
        notice: noteNormalized,
        travel: travelNormalized,
        hotel: hotelNormalized,
      })
    }

    if (props.type === RESOURCE_TYPE.VEHICLE) {
      await updateResourceAllocationVehicleMutation.mutate({
        resourceAllocationId,
        stateId: valuesToChange.value.status.value,
        notice: noteNormalized,
      })
    }

    updateStates.value.set(resourceAllocationId, 'success')
  } catch (error) {
    updateStates.value.set(resourceAllocationId, 'error')
    throwFriendlyError(error)
  }
}
</script>

<style lang="scss">
.PmMultipleResourceAllocationsUpdate {
  $block: &;
}
</style>
