<template>
  <PmModalPure
    title="Ansichtseinstellungen"
    :padding="false"
    :can-be-closed="true"
    @close="$emit('close')"
  >
    <div class="PmViewEditor">
      <PmSectionWrapperPure>
        <PmSectionPure v-if="$store.state.view.title">
          Ausgewählte Ansicht: <strong>{{ $store.state.view.title }}</strong>
        </PmSectionPure>

        <PmSectionPure title="Bereiche">
          <PmCheckboxListPure>
            <PmViewEditorCheckbox
              class="PmViewEditor-checkbox"
              attribute="showGeneralEvents"
              :value="view.showGeneralEvents"
              @update="setBoolean"
            >
              Allgemeine Termine
            </PmViewEditorCheckbox>

            <PmViewEditorCheckbox
              class="PmViewEditor-checkbox"
              attribute="showCalendarEvents"
              :value="view.showCalendarEvents"
              @update="setBoolean"
            >
              Termine
            </PmViewEditorCheckbox>

            <PmViewEditorCheckbox
              class="PmViewEditor-checkbox"
              attribute="showBirthdays"
              :value="view.showBirthdays"
              @update="setBoolean"
            >
              Geburtstag
            </PmViewEditorCheckbox>

            <PmViewEditorCheckbox
              class="PmViewEditor-checkbox"
              attribute="showFreelancers"
              :value="view.showFreelancers"
              @update="setBoolean"
            >
              Externe Dienstleister
            </PmViewEditorCheckbox>

            <PmViewEditorCheckbox
              class="PmViewEditor-checkbox"
              attribute="showEmployees"
              :value="view.showEmployees"
              @update="setBoolean"
            >
              Mitarbeiter
            </PmViewEditorCheckbox>

            <PmViewEditorCheckbox
              class="PmViewEditor-checkbox"
              attribute="showVehicles"
              :value="view.showVehicles"
              @update="setBoolean"
            >
              Fahrzeuge
            </PmViewEditorCheckbox>

            <template v-if="FEATURE_FLAG.OPERATIONS">
              <PmViewEditorCheckbox
                class="PmViewEditor-checkbox"
                attribute="showOperations"
                :value="view.showOperations"
                @update="setBoolean"
              >
                Vorgänge
              </PmViewEditorCheckbox>
            </template>
          </PmCheckboxListPure>
        </PmSectionPure>

        <PmSectionPure title="Zusätzliche Informationen">
          <PmCheckboxListPure>
            <PmViewEditorCheckbox
              class="PmViewEditor-checkbox"
              attribute="showOpeningHours"
              :value="view.showOpeningHours"
              @update="setBoolean"
            >
              Öffnungszeiten
            </PmViewEditorCheckbox>

            <PmViewEditorCheckbox
              class="PmViewEditor-checkbox"
              attribute="showCoreHours"
              :value="view.showCoreHours"
              @update="setBoolean"
            >
              Kernarbeitszeiten
            </PmViewEditorCheckbox>

            <PmViewEditorCheckbox
              class="PmViewEditor-checkbox"
              attribute="showMissingResourcesWarning"
              :value="view.showMissingResourcesWarning"
              @update="setBoolean"
            >
              Warnung bei fehlenden Ressourcen
            </PmViewEditorCheckbox>
          </PmCheckboxListPure>
        </PmSectionPure>

        <PmSectionPure title="Allgemein">
          <PmCheckboxListPure>
            <PmViewEditorCheckbox
              class="PmViewEditor-checkbox"
              attribute="showOnlyActive"
              note="Projekte mit Status Abgesagt und Jobs mit Status Abgesagt, Freies Angebot (Vermietung), Fertigungsplanung, Inventur und Kalkulationsgrundlage Verkauf werden ausgeblendet"
              :value="view.showOnlyActive"
              @update="setBoolean"
            >
              Inaktive und nicht relevante Projekte/Jobs ausblenden
            </PmViewEditorCheckbox>

            <PmViewEditorCheckbox
              class="PmViewEditor-checkbox"
              attribute="showOnlyWithResources"
              :value="view.showOnlyWithResources"
              @update="setBoolean"
            >
              Projekte und Jobs ohne Ressourcen ausblenden
            </PmViewEditorCheckbox>
          </PmCheckboxListPure>
        </PmSectionPure>

        <PmSectionPure title="Mitarbeiter-Gruppen">
          <PmViewEditorDropdown
            class="PmViewEditor-dropdown"
            attribute="employeeGroups"
            :options="normalizedEmployeeGroups"
            :is-loading="isLoading"
            :mode="view.employeeGroups.mode"
            :value="view.employeeGroups.ids"
            @update="setIds"
            @toggle-mode="toggleMode"
          />
        </PmSectionPure>

        <PmSectionPure title="Kunde">
          <PmViewEditorClients
            class="PmViewEditor-dropdown"
            :mode="view.clients.mode"
            :value="view.clients.ids"
            @update="setIds"
            @toggle-mode="toggleMode"
          />
        </PmSectionPure>

        <PmSectionPure title="Projekt">
          <PmViewEditorDropdown
            class="PmViewEditor-dropdown"
            label="Projektleiter"
            attribute="projectLeaders"
            :options="normalizedEasyJobUsers"
            :is-loading="isLoading"
            :mode="view.projectLeaders.mode"
            :value="view.projectLeaders.ids"
            @update="setIds"
            @toggle-mode="toggleMode"
          />

          <PmViewEditorProjects
            class="PmViewEditor-dropdown"
            :mode="view.projects.mode"
            :value="view.projects.ids"
            @update="setIds"
            @toggle-mode="toggleMode"
          />

          <PmViewEditorDropdown
            class="PmViewEditor-dropdown"
            label="Projektstatus"
            attribute="projectStates"
            :options="normalizedProjectStates"
            :is-loading="isLoading"
            :note="
              isExcludeInactiveFilterActive
                ? `Einige Optionen werden bereits durch den Filter „Inaktive und nicht relevante Projekte/Jobs ausblenden” ausgeblendet.`
                : null
            "
            :mode="view.projectStates.mode"
            :value="view.projectStates.ids"
            @update="setIds"
            @toggle-mode="toggleMode"
          />

          <PmViewEditorDropdown
            class="PmViewEditor-dropdown"
            label="Projekttyp"
            attribute="projectTypes"
            :options="normalizedProjectTypes"
            :is-loading="isLoading"
            :mode="view.projectTypes.mode"
            :value="view.projectTypes.ids"
            @update="setIds"
            @toggle-mode="toggleMode"
          />
        </PmSectionPure>

        <PmSectionPure title="Job">
          <PmViewEditorDropdown
            class="PmViewEditor-dropdown"
            label="Jobleiter"
            attribute="jobLeaders"
            :options="normalizedEasyJobUsers"
            :is-loading="isLoading"
            :mode="view.jobLeaders.mode"
            :value="view.jobLeaders.ids"
            @update="setIds"
            @toggle-mode="toggleMode"
          />

          <PmViewEditorDropdown
            class="PmViewEditor-dropdown"
            label="Jobstatus"
            attribute="jobStates"
            :options="normalizedJobStates"
            :is-loading="isLoading"
            :note="
              isExcludeInactiveFilterActive
                ? `Einige Optionen werden bereits durch den Filter „Inaktive und nicht relevante Projekte/Jobs ausblenden” ausgeblendet.`
                : null
            "
            :mode="view.jobStates.mode"
            :value="view.jobStates.ids"
            @update="setIds"
            @toggle-mode="toggleMode"
          />

          <PmViewEditorDropdown
            class="PmViewEditor-dropdown"
            label="Jobservice"
            attribute="jobServices"
            :options="normalizedJobServices"
            :is-loading="isLoading"
            :mode="view.jobServices.mode"
            :value="view.jobServices.ids"
            @update="setIds"
            @toggle-mode="toggleMode"
          />
        </PmSectionPure>

        <PmSectionPure title="Ressource">
          <PmViewEditorDropdown
            class="PmViewEditor-dropdown"
            label="Ressourcentyp"
            attribute="resourceTypes"
            :options="normalizedResourceTypes"
            :is-loading="isLoading"
            :mode="view.resourceTypes.mode"
            :value="view.resourceTypes.ids"
            @update="setIds"
            @toggle-mode="toggleMode"
          />

          <PmViewEditorDropdown
            class="PmViewEditor-dropdown"
            label="Ressourcenfunktion"
            attribute="resourceFunctions"
            :options="normalizedResourceFunctions"
            :is-loading="isLoading"
            :mode="view.resourceFunctions.mode"
            :value="view.resourceFunctions.ids"
            @update="setIds"
            @toggle-mode="toggleMode"
          />
        </PmSectionPure>

        <PmSectionPure title="Ressourcen-Zuordnungen">
          <PmViewEditorResourceAllocations
            class="PmViewEditor-dropdown"
            :mode="view.resourceAllocations.mode"
            :value="view.resourceAllocations.ids"
            @update="setIds"
            @toggle-mode="toggleMode"
          />

          <PmViewEditorDropdown
            class="PmViewEditor-dropdown"
            label="Ressourcenstatus"
            attribute="resourceAllocationStates"
            :options="normalizedResourceAllocationStates"
            :is-loading="isLoading"
            :mode="view.resourceAllocationStates.mode"
            :value="view.resourceAllocationStates.ids"
            @update="setIds"
            @toggle-mode="toggleMode"
          />
        </PmSectionPure>
      </PmSectionWrapperPure>
    </div>

    <template #footer>
      <PmButtonListPure align="right">
        <PmButtonPure label="Abbrechen" @click="$emit('close')" />

        <PmButtonPure
          label="Anwenden"
          :disabled="!isViewChanged"
          :icon="ICONS.CHECK"
          @click="closeAndApply"
        />

        <PmButtonPure
          label="Anwenden und Ansicht speichern"
          variant="primary"
          :disabled="!isViewChanged"
          :icon="ICONS.SAVE"
          @click="closeAndSave"
        />

        <PmContextNavigationPure
          :items="[
            {
              id: 'create',
              icon: ICONS.PLUS,
              label: 'Anwenden und neue Ansicht erstellen',
            },
          ]"
          @create="closeAndCreate"
        />
      </PmButtonListPure>
    </template>
  </PmModalPure>
</template>

<script>
import { sortBy, cloneDeep, isEqual } from 'lodash-es'

import {
  STATUS_RESOURCE_ALLOCATION_SORT_ORDER,
  VIEW_SETTING_MODE,
} from '@/constants/persoplan'
import { FEATURE_FLAG } from '@/constants/featureFlags'
import { ICONS } from '@/constants/icons'

import {
  INACTIVE_PROJECT_STATE_IDS,
  INACTIVE_JOB_STATE_IDS,
} from '@/store/modules/view'

import PmSectionWrapperPure from '@/components/basics/PmSectionWrapperPure.vue'
import PmSectionPure from '@/components/basics/PmSectionPure.vue'
import PmViewEditorCheckbox from '@/components/persoplan/ViewEditor/PmViewEditorCheckbox.vue'
import PmViewEditorDropdown from '@/components/persoplan/ViewEditor/PmViewEditorDropdown.vue'
import PmViewEditorClients from '@/components/persoplan/ViewEditor/PmViewEditorClients.vue'
import PmViewEditorProjects from '@/components/persoplan/ViewEditor/PmViewEditorProjects.vue'
import PmViewEditorResourceAllocations from '@/components/persoplan/ViewEditor/PmViewEditorResourceAllocations.vue'
import PmModalPure from '@/components/basics/PmModalPure.vue'
import PmCheckboxListPure from '@/components/basics/PmCheckboxListPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmContextNavigationPure from '@/components/basics/PmContextNavigation/PmContextNavigationPure.vue'

import ViewEditorStaticQuery from '@/components/persoplan/ViewEditor/ViewEditorStaticQuery.graphql'

export default {
  name: 'PmViewEditor',
  components: {
    PmSectionWrapperPure,
    PmSectionPure,
    PmViewEditorCheckbox,
    PmViewEditorDropdown,
    PmViewEditorClients,
    PmViewEditorProjects,
    PmViewEditorResourceAllocations,
    PmModalPure,
    PmCheckboxListPure,
    PmButtonPure,
    PmButtonListPure,
    PmContextNavigationPure,
  },

  emits: ['close', 'closeAndSave', 'closeAndCreate'],

  setup() {
    return {
      FEATURE_FLAG,
      ICONS,
    }
  },

  data() {
    return {
      isLoading: undefined,
      selected: undefined,
      staticFilterOptions: {
        employeeGroups: [],
        easyJobUsers: [],
        projectStates: [],
        projectTypes: [],
        jobStates: [],
        jobServices: [],
        resourceTypes: [],
        resourceFunctions: [],
        resourceAllocationStates: [],
      },
      buttons: [
        // { label: 'Ansicht auf Standard zurücksetzen', type: 'reset' }
        // { label: 'Löschen', type: 'delete', loading: true }
      ],

      view: {},
    }
  },

  computed: {
    isExcludeInactiveFilterActive() {
      return this.view.showOnlyActive === true
    },

    normalizedEmployeeGroups() {
      return this.staticFilterOptions.employeeGroups.map((employeeGroup) => ({
        id: employeeGroup.id,
        label: employeeGroup.caption,
      }))
    },

    normalizedEasyJobUsers() {
      return this.staticFilterOptions.easyJobUsers.map((easyJobUser) => ({
        id: easyJobUser.id,
        label: easyJobUser.displayName,
      }))
    },

    normalizedProjectStates() {
      return this.staticFilterOptions.projectStates.map((projectState) => {
        const isExcludedByExcludeInactive = INACTIVE_PROJECT_STATE_IDS.includes(
          projectState.id
        )

        const isActive = this.isExcludeInactiveFilterActive
          ? isExcludedByExcludeInactive === false
          : null

        return {
          id: projectState.id,
          label: projectState.caption,
          active: isActive,
        }
      })
    },

    normalizedProjectTypes() {
      return this.staticFilterOptions.projectTypes.map((projectType) => ({
        id: projectType.id,
        label: projectType.caption,
      }))
    },

    normalizedJobStates() {
      return this.staticFilterOptions.jobStates.map((jobState) => {
        let label = jobState.caption

        if (jobState.jobType?.caption) {
          label = `${label} ${jobState.jobType.caption}`
        }

        const isExcludedByExcludeInactive = INACTIVE_JOB_STATE_IDS.includes(
          jobState.id
        )

        const isActive = this.isExcludeInactiveFilterActive
          ? isExcludedByExcludeInactive === false
          : null

        return {
          id: jobState.id,
          label: label,
          active: isActive,
        }
      })
    },

    normalizedJobServices() {
      return this.staticFilterOptions.jobServices.map((jobService) => ({
        id: jobService.id,
        label: jobService.caption,
      }))
    },

    normalizedResourceTypes() {
      return this.staticFilterOptions.resourceTypes.map((resourceType) => ({
        id: resourceType.id,
        label: resourceType.caption,
      }))
    },

    normalizedResourceFunctions() {
      return this.staticFilterOptions.resourceFunctions.map(
        (resourceFunction) => ({
          id: resourceFunction.id,
          label: resourceFunction.caption,
          number: resourceFunction.abbreviation,
        })
      )
    },

    normalizedResourceAllocationStates() {
      const states = this.staticFilterOptions.resourceAllocationStates.map(
        (resourceAllocationState) => ({
          id: resourceAllocationState.id,
          label: resourceAllocationState.caption,
        })
      )

      const statesOrdered = sortBy(states, (state) => {
        const index = STATUS_RESOURCE_ALLOCATION_SORT_ORDER.indexOf(state.id)
        return index
      })

      return statesOrdered
    },

    isViewChanged() {
      return !isEqual(this.view, this.$store.state.view.currentView)
    },

    saveActions() {
      return [
        {
          id: 'save',
          icon: ICONS.SAVE,
          label: 'Anwenden und Ansicht speichern',
          visible: this.$store.getters['view/isSavedView'],
        },
        {
          id: 'create',
          icon: ICONS.PLUS,
          label: 'Anwenden und neue Ansicht erstellen',
        },
      ]
    },
  },

  created() {
    const currentView = this.$store.state.view.currentView
    this.view = cloneDeep(currentView)

    this.showGeneralEvents = currentView.showGeneralEvents
    this.showGeneralEvents = currentView.showGeneralEvents
    this.showGeneralEvents = currentView.showGeneralEvents
  },

  methods: {
    setBoolean({ attribute, value }) {
      this.view[attribute] = value
    },

    setIds({ attribute, ids }) {
      this.view[attribute].ids = ids
    },

    toggleMode({ attribute }) {
      const currentMode = this.view[attribute].mode

      if (currentMode === VIEW_SETTING_MODE.EXPLICIT) {
        this.view[attribute].mode = VIEW_SETTING_MODE.EXCLUDE
      }

      if (currentMode === VIEW_SETTING_MODE.EXCLUDE) {
        this.view[attribute].mode = VIEW_SETTING_MODE.EXPLICIT
      }
    },

    closeAndApply() {
      this.$store.commit('view/setCurrentViewSettings', { settings: this.view })
      this.$emit('close')
    },

    closeAndSave() {
      this.$store.commit('view/setCurrentViewSettings', { settings: this.view })
      this.$emit('closeAndSave')
    },

    closeAndCreate() {
      this.$store.commit('view/setCurrentViewSettings', { settings: this.view })
      this.$emit('closeAndCreate')
    },
  },

  apollo: {
    staticFilterOptions: {
      query: ViewEditorStaticQuery,

      watchLoading(isLoading) {
        this.isLoading = isLoading
      },

      update(data) {
        return data
      },
    },
  },
}
</script>

<style lang="scss">
.PmViewEditor {
  $block: &;

  &-content {
    padding: 30px;
  }

  &-dropdown:not(:last-child) {
    margin-bottom: 10px;
  }
}
</style>
