import { createMachine, assign } from 'xstate'

import type { Typegen0 } from './PmResourceAllocationEditState.typegen'
export type TState = Typegen0['matchesStates']

export const PmResourceAllocationEditState = createMachine({
  tsTypes: {} as import('./PmResourceAllocationEditState.typegen').Typegen0,
  schema: {
    events: {} as
      | { type: 'SAVE' }
      | { type: 'SAVE_AND_REQUEST' }
      | { type: 'CREATE' }
      | { type: 'CREATE_AND_REQUEST' }
      | { type: 'DELETE' }
      | {
          type: 'START_VEHICLE_ALLOCATION'
          jobId: number
          allocatedVehicleId: number
        }
      | {
          type: 'START_DRIVER_ALLOCATION'
          jobId: number
          allocatedDriverId: number
        }
      | { type: 'CANCEL' }
      | { type: 'FINISH' },

    services: {} as {
      waitForClick: any
      updateResourceAllocation: any
      deleteResourceAllocation: any
    },

    context: {} as {
      error: string | undefined
      errorDetails: string[] | undefined
      requestAfterCreatedOrUpdated: boolean | undefined
    },
  },

  id: 'PmResourceAllocationEditState',
  initial: 'default',

  context: {
    error: undefined,
    errorDetails: undefined,
    requestAfterCreatedOrUpdated: undefined,
  },

  states: {
    default: {},

    create: {
      initial: 'default',

      states: {
        default: {
          entry: assign({
            requestAfterCreatedOrUpdated: false,
          }),

          on: {
            CREATE: 'save',
            CREATE_AND_REQUEST: {
              target: 'save',
              actions: assign({
                requestAfterCreatedOrUpdated: true,
              }),
            },
            START_VEHICLE_ALLOCATION: 'vehicleAllocation',
            START_DRIVER_ALLOCATION: 'driverAllocation',
          },
        },

        save: {
          initial: 'saving',

          states: {
            saving: {
              invoke: {
                id: 'createResourceAllocation',
                src: 'createResourceAllocation',
                onError: {
                  target: 'failed',
                  actions: (context, error) => {
                    context.error = error.data.message
                    context.errorDetails = error.data.details
                  },
                },
                onDone: '#PmResourceAllocationEditState.update',
              },
            },
            failed: {
              on: {
                CREATE: 'saving',
              },
            },
          },
        },

        vehicleAllocation: {
          initial: 'pickResource',

          states: {
            pickResource: {
              entry: 'startVehicleAllocation',
              exit: 'finishAllocation',

              invoke: {
                id: 'waitForClick',
                src: 'waitForClick',
                onDone: '#PmResourceAllocationEditState.create',
              },

              on: {
                CANCEL: '#PmResourceAllocationEditState.create',
                FINISH: '#PmResourceAllocationEditState.create',
              },
            },
          },
        },

        driverAllocation: {
          initial: 'pickResource',

          states: {
            pickResource: {
              entry: 'startDriverAllocation',
              exit: 'finishAllocation',

              invoke: {
                id: 'waitForClick',

                src: 'waitForClick',
                onDone: '#PmResourceAllocationEditState.create',
              },

              on: {
                CANCEL: '#PmResourceAllocationEditState.create',
                FINISH: '#PmResourceAllocationEditState.create',
              },
            },
          },
        },
      },
    },

    update: {
      initial: 'default',

      states: {
        default: {
          entry: assign({
            requestAfterCreatedOrUpdated: false,
          }),

          on: {
            SAVE: 'save',
            SAVE_AND_REQUEST: {
              target: 'save',
              actions: assign({
                requestAfterCreatedOrUpdated: true,
              }),
            },
            DELETE: 'delete',
            START_VEHICLE_ALLOCATION: 'vehicleAllocation',
            START_DRIVER_ALLOCATION: 'driverAllocation',
          },
        },

        save: {
          initial: 'saving',
          states: {
            saving: {
              invoke: {
                id: 'updateResourceAllocation',
                src: 'updateResourceAllocation',
                onError: {
                  target: 'failed',
                  actions: (context, error) => {
                    context.error = error.data.message
                    context.errorDetails = error.data.details
                  },
                },
                onDone: '#PmResourceAllocationEditState.update',
              },
            },
            failed: {
              on: { SAVE: 'saving' },
            },
          },
        },

        delete: {
          initial: 'deleting',
          states: {
            deleting: {
              invoke: {
                id: 'deleteResourceAllocation',
                src: 'deleteResourceAllocation',
                onError: {
                  target: 'failed',
                  actions: (context, error) => {
                    context.error = error.data.message
                    context.errorDetails = error.data.details
                  },
                },
              },
            },
            failed: {
              on: {
                DELETE: 'deleting',
                SAVE: '#PmResourceAllocationEditState.update.save',
              },
            },
          },
        },

        vehicleAllocation: {
          initial: 'pickResource',

          states: {
            pickResource: {
              entry: 'startVehicleAllocation',
              exit: 'finishAllocation',

              invoke: {
                id: 'waitForClick',
                src: 'waitForClick',
                onDone: '#PmResourceAllocationEditState.update',
              },

              on: {
                CANCEL: '#PmResourceAllocationEditState.update',
                FINISH: '#PmResourceAllocationEditState.update',
              },
            },
          },
        },

        driverAllocation: {
          initial: 'pickResource',

          states: {
            pickResource: {
              entry: 'startDriverAllocation',
              exit: 'finishAllocation',

              invoke: {
                id: 'waitForClick',
                src: 'waitForClick',
                onDone: '#PmResourceAllocationEditState.update',
              },

              on: {
                CANCEL: '#PmResourceAllocationEditState.update',
                FINISH: '#PmResourceAllocationEditState.update',
              },
            },
          },
        },
      },
    },
  },
})
