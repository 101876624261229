import type { PaymentType } from '@/../generated/enumsAsConst'

export const paymentTypeLabel: Record<PaymentType, string> = {
  companyCreditCard: 'Kreditkarte',
  supplierCreditCard: 'Kundenkarte',
  private: 'Privat/Bar',
}

export const transportType = ['private', 'cabOrPublicTransport'] as const
export type TransportType = (typeof transportType)[number]

export const transportTypeLabel: Record<TransportType, string> = {
  private: 'Privat',
  cabOrPublicTransport: 'Taxi oder öffentlicher Nahverkehr',
}
