<template>
  <div class="PmStatusPure">
    <PmLoadingPure v-if="loading" class="PmStatusPure-icon" />

    <PmIconPure
      v-if="icon"
      :key="icon"
      :name="icon"
      class="PmStatusPure-icon"
    />

    <div class="PmStatusPure-text">
      <slot />
    </div>
  </div>
</template>

<script>
import PmIconPure from '@/components/basics/PmIcon/PmIconPure.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'

export default {
  name: 'PmStatusPure',
  components: {
    PmIconPure,
    PmLoadingPure,
  },

  props: {
    icon: { type: String, default: undefined },
    loading: { type: Boolean, default: undefined },
  },
}
</script>

<style lang="scss">
.PmStatusPure {
  $block: &;

  display: flex;
  align-items: center;
  flex-direction: column;

  &-icon {
    width: 44px;
    height: 44px;
    margin-bottom: 32px;
  }

  &-text {
    @include typography.copy-large;

    text-align: center;
  }
}
</style>
