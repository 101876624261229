<template>
  <PmInputContainerPure
    :class="[$attrs.class]"
    :label="label"
    :error-message="errorMessage"
    :invalid-message="invalidMessage"
    :required="required"
    :note="note"
  >
    <div class="PmInputPure" :class="classes">
      <div v-if="icon" class="PmInputPure-icon">
        <PmIconPure :key="icon" :name="icon" size="default" />
      </div>

      <input
        :id="uid"
        ref="elInput"
        :type="typeNormalized"
        v-bind="separateAttrs($attrs).attributes"
        class="PmInputPure-input"
        :value="valueNormalized"
        :placeholder="placeholder"
        :disabled="isDisabledNormalized"
        :readonly="readonly"
        :required="required"
        size="1"
        @blur="(event) => $emit('blur', event)"
        @keydown="(event) => $emit('keydown', event)"
        @input="onInput"
      />

      <div class="PmInputPure-actions">
        <div v-if="loading" class="PmInputPure-loadingContainer">
          <PmLoadingPure v-if="loading" class="PmInputPure-loading" />
        </div>

        <PmButtonPure
          v-if="resetVisible"
          class="PmInputPure-action"
          icon="close"
          variant="danger"
          size="small"
          @click.stop="reset"
        />
      </div>
    </div>
  </PmInputContainerPure>
</template>

<script setup lang="ts" generic="Type extends TypeBase = 'text'">
/**
 * Stuff regarding form validation:
 * @see https://pageclip.co/blog/2018-02-20-you-should-use-html5-form-validation.html
 */
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { isNil, isFinite } from 'lodash-es'
import cuid from '@paralleldrive/cuid2'
import { parseISO, parse, isValid, format } from 'date-fns'
import { useForm } from '@/composition/useForm'

import { ICONS } from '@/constants/icons'

import { separateAttrs } from '@/utilities/misc'
import { normalizeSearchInput } from '@/utilities/persoplan'

import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmIconPure, {
  type Props as PropsIconPure,
} from '@/components/basics/PmIcon/PmIconPure.vue'

import PmInputContainerPure from '@/components/basics/PmInputContainer/PmInputContainerPure.vue'

import type { Nilable } from '@/types/misc'

export type ValidationStateKeys = keyof ValidityState | 'notAllowed'
export type ValidationMessages = Partial<Record<ValidationStateKeys, string>>

export type TypeBase =
  | 'text'
  | 'password'
  | 'search'
  | 'datetime-local'
  | 'date'
  | 'time'
  | 'number'

type ValueType = {
  date: Date
  number: number
  time: Date
}

// Use type from `ValueType` or fall back to string if not found
type Value<T extends Type> = T extends keyof ValueType ? ValueType[T] : string

interface Props {
  type?: Type
  value?: Value<Type> | null | ValueType[keyof ValueType]
  label?: string
  note?: string
  error?: boolean
  errorMessage?: string
  placeholder?: string
  disabled?: boolean
  readonly?: boolean
  loading?: boolean
  hasReset?: boolean
  required?: boolean
  size?: 'default' | 'large'
  validationMessages?: ValidationMessages
  icon?: PropsIconPure['name']
  notAllowed?: string | string[]
  triggerValidityCheck?: number
  focusOnMounted?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  // @ts-expect-error https://github.com/vuejs/core/issues/11167
  type: 'text',
  placeholder: '',
  size: 'default',
})

const emit = defineEmits<{
  input: [Value<Type> | null]
  'update:value': [Value<Type> | null]
  blur: [Event]
  keydown: [Event]
}>()

defineOptions({
  inheritAttrs: false,
})

const FORMAT_DATE = 'yyyy-MM-dd'
const FORMAT_TIME = 'HH:mm'
const COMPONENT_NAME = 'PmInputPure'

const uid = cuid.createId()
const isInvalid = ref<boolean>()
const elInput = ref<HTMLInputElement>()
const invalidMessage = ref<string>()
const hasFirstValidationHappened = ref(false)

const classes = computed(() => {
  return {
    [`${COMPONENT_NAME}--sizeLarge`]: props.size === 'large',
    [`${COMPONENT_NAME}--withIcon`]: props.icon ? true : false,
    'is-error': props.error || isInvalid.value,
    'is-disabled': isDisabledNormalized.value,
    'is-readonly': props.readonly,
    'is-required': props.required,
    'is-notEditable': props.readonly || isDisabledNormalized.value,
    'is-search': props.type === 'search',
  }
})

const valueNormalized = computed(() => {
  if (!props.value) return props.value

  if (props.type === 'date') {
    return normalizeValueDate(props.value)
  }

  if (props.type === 'time') {
    return normalizeValueTime(props.value)
  }

  return props.value
})

function normalizeValueDate(value: Props['value']) {
  if (!(value instanceof Date)) throw new Error('value is not a Date')
  if (!isValid(value)) return

  const timestamp = value.valueOf()
  return format(timestamp, FORMAT_DATE)
}

function normalizeValueTime(value: Props['value']) {
  if (!(value instanceof Date)) throw new Error('value is not a Date')
  if (!isValid(value)) return

  const timestamp = value.valueOf()
  return format(timestamp, FORMAT_TIME)
}

const typeNormalized = computed(() => {
  if (props.type === 'search') return 'text'
  return props.type
})

const resetVisible = computed(() => {
  if (!props.hasReset) return false
  return props.value ? true : false
})

const notAllowedNormalized = computed(() => {
  if (isNil(props.notAllowed)) return

  if (!Array.isArray(props.notAllowed)) return [props.notAllowed]

  return props.notAllowed
})

const validationMessageNotAllowed = computed(() => {
  if (props.validationMessages?.notAllowed)
    return props.validationMessages.notAllowed

  return 'Diese Eingabe ist nicht erlaubt'
})

watch(() => props.triggerValidityCheck, checkValidity)

onMounted(() => {
  if (!elInput.value) throw new Error('elInput is undefined')

  elInput.value.addEventListener('invalid', onInvalid)

  if (props.focusOnMounted) {
    elInput.value.focus()
  }
})

onBeforeUnmount(() => {
  if (!elInput.value) throw new Error('elInput is undefined')

  elInput.value.removeEventListener('invalid', onInvalid)
})

/**
 * Customized error messages
 * @see https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation#implementing_a_customized_error_message
 */
function onInvalid(event: Event) {
  event.preventDefault()

  // elInput.setCustomValidity('Blupp JO')
  // elInput.reportValidity()

  showInvalidMessage()
}

function showInvalidMessage() {
  if (!elInput.value) throw new Error('elInput is undefined')

  isInvalid.value = true
  hasFirstValidationHappened.value = true

  /**
   * Check for custom validation messages to display
   */
  const invalidTypes: Array<keyof ValidityState> = []

  let key: keyof typeof elInput.value.validity
  for (key in elInput.value.validity) {
    const isInvalid = elInput.value.validity[key]

    if (isInvalid) {
      invalidTypes.push(key)
    }
  }

  const useCustomMessageForKey = invalidTypes.find((key) => {
    if (!props.validationMessages) return

    const hasCustomMessage = Object.prototype.hasOwnProperty.call(
      props.validationMessages,
      key
    )

    return hasCustomMessage
  })

  let validationMessage = elInput.value.validationMessage

  if (useCustomMessageForKey) {
    const customValidationMessage =
      props.validationMessages?.[useCustomMessageForKey]

    if (customValidationMessage) {
      // setCustomValidity needs to be removed manually
      // this.$refs.input.setCustomValidity(validationMessage)
      validationMessage = customValidationMessage
    }
  }

  invalidMessage.value = validationMessage
}

function onInput(event: Event) {
  if (!(event.target instanceof HTMLInputElement)) {
    throw new Error('event.target is not a HTMLInputElement')
  }

  checkValidity()
  const value = normalizeInputValue(event.target.value)
  emitInput(value)
}

function checkValidity() {
  if (!elInput.value) throw new Error('elInput is undefined')

  const inputValue = elInput.value.value

  checkForNotAllowedInput({ value: inputValue })

  if (elInput.value.validity.valid) {
    isInvalid.value = false
    invalidMessage.value = undefined
  } else {
    if (!hasFirstValidationHappened.value) return
    showInvalidMessage()
  }
}

function checkForNotAllowedInput({ value }: { value: string }) {
  if (!elInput.value) throw new Error('elInput is undefined')
  if (!notAllowedNormalized.value) return

  const valueIsInNotAllowed = notAllowedNormalized.value.includes(value)

  valueIsInNotAllowed
    ? elInput.value.setCustomValidity(validationMessageNotAllowed.value)
    : elInput.value.setCustomValidity('')
}

function reset() {
  emitInput(null)
}

function normalizeInputValue(value?: string | null) {
  const isEmptyString = value?.trim() === ''
  if (isEmptyString) return null

  if (props.type === 'search') {
    return normalizeSearchInput(value)
  }

  if (props.type === 'number' && !isNil(value)) {
    const numberValue = parseFloat(value)

    const isValidNumber = isFinite(numberValue)
    if (!isValidNumber) emitInput(null)

    return numberValue
  }

  if (props.type === 'date' && value) {
    return parseISO(value)
  }

  if (props.type === 'time' && value) {
    return parse(value, FORMAT_TIME, new Date(0))
  }

  return value
}

function emitInput(value: Nilable<string | number | Date>) {
  const assertedValue = value as Value<Type> | null
  emit('input', assertedValue)
  emit('update:value', assertedValue)
}

/**
 * Disabled state
 */
const form = useForm()
const isDisabledNormalized = computed(() => {
  if (props.disabled) return true
  if (form.disabled?.value) return true
  return undefined
})
</script>

<style lang="scss">
@use 'sass:color' as sassColor;

@function convertToRgba($rgb, $background: #fff) {
  @return mix(
    rgb(red($rgb), green($rgb), blue($rgb)),
    $background,
    alpha($rgba) * 50%
  );
}

.PmInputPure {
  $block: &;

  @include mixin.transition-hover((border-color), $block);

  background-color: color.$white;
  border: 1px solid color.$gray-300;
  border-radius: constant.$borderRadius-default;
  display: flex;
  align-items: center;
  min-height: 30px;
  min-width: 30px;
  width: 100%;

  &:not(.is-notEditable):hover,
  &:not(.is-notEditable).is-hover {
    background-color: color.$gray-50;
    border-color: color.$gray-400;
  }

  &:not(.is-notEditable):focus-within,
  &:not(.is-notEditable).is-focus {
    outline: none;
    border-color: color.$primary-500;
    background-color: color.$primary-50;
    color: color.$primary-900;
  }

  &.is-disabled {
    background-color: color.$gray-100;
    border-color: color.$gray-200;
  }

  &.is-error {
    border-color: color.$danger-500;
    background-color: color.$danger-50;
    color: color.$danger-800;
  }

  &.is-readonly {
    background-color: color.$gray-100;
  }

  &-loadingContainer {
    width: 20px;
    // padding-right: 4px;
    box-sizing: content-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    #{$block}--sizeLarge & {
      width: 26px;
      // padding-right: 4px;
    }
  }

  &-icon {
    width: 30px;
    height: 30px;
    padding: 3px;
    flex-shrink: 0;
    opacity: 0.5;
    transition: opacity constant.$duration-default;

    /**
    * This fixes a bug, where in iOS Safari if this component is used in a container with a fractional width
    * (e.g. 250.23px, by using a percentage value or similar) the icon jumps when the input receives focus 🤡
    */
    will-change: opacity;

    #{$block}:focus-within &,
    #{$block}.is-focus & {
      opacity: 1;
    }

    #{$block}--sizeLarge & {
      margin-left: 4px;
    }
  }

  &-input {
    width: 100%;
    padding: 7px;
    padding-bottom: 6px;
    font-weight: 500;
    height: 32px;
    position: relative;
    box-shadow: none;
    background-color: transparent;
    color: inherit;
    line-height: constant.$lineHeight-default;

    #{$block}.is-focus &,
    &:focus {
      outline: none;
    }

    &::placeholder {
      font-weight: normal;
      opacity: 0.5;
    }

    #{$block}.is-disabled & {
      opacity: 0.5;
    }

    #{$block}--sizeLarge & {
      font-size: constant.$fontSize-larger;
      padding: 8px 12px;
      height: auto;
    }

    #{$block}--withIcon & {
      padding-left: 4px;
    }

    /**
     * Hacky why to align label on mobile safari
     * @see https://stackoverflow.com/a/70735398
     */
    // stylelint-disable-next-line
    &::-webkit-date-and-time-value {
      text-align: left;
    }
  }

  &-actions {
    max-height: 30px;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-right: 4px;
  }
}
</style>
