<template>
  <div class="PmDataModalResourceAllocationPure">
    <PmLoadingPure v-if="isLoading" size="default" />

    <template v-if="!isLoading">
      <PmKeyValueListPure>
        <PmKeyValuePure v-if="IS_DEVELOPMENT" label="Id" variant="debug">
          {{ id }}
        </PmKeyValuePure>

        <PmKeyValuePure label="Status">
          <PmStatusPillPure
            v-if="status"
            type="resourceAllocation"
            :resource-allocation-status="status"
          />
        </PmKeyValuePure>

        <PmKeyValuePure label="Faktor">{{ factor }}</PmKeyValuePure>

        <template v-if="type === 'address'">
          <PmKeyValuePure label="Travel">
            <div
              v-if="travel"
              class="PmDataModalResourceAllocationPure-statusTravel"
            >
              <div class="PmDataModalResourceAllocationPure-statusTravelDot">
                <PmStatusDotPure type="travel" :travel-status="travelStatus" />
              </div>

              {{ travelNormalized }}
            </div>
          </PmKeyValuePure>

          <PmKeyValuePure label="Hotel">
            <div
              v-if="hotel"
              class="PmDataModalResourceAllocationPure-statusHotel"
            >
              <div class="PmDataModalResourceAllocationPure-statusHotelDot">
                <PmStatusDotPure type="hotel" :hotel-status="hotelStatus" />
              </div>

              {{ hotelNormalized }}
            </div>
          </PmKeyValuePure>
        </template>

        <PmKeyValuePure label="Bemerkung">{{ note }}</PmKeyValuePure>

        <template v-if="type === 'address'">
          <PmKeyValuePure label="Name">{{ name }}</PmKeyValuePure>
          <PmKeyValuePure label="Firma">{{ company }}</PmKeyValuePure>

          <PmKeyValuePure
            v-if="IS_DEVELOPMENT"
            label="address.id"
            variant="debug"
          >
            {{ address?.id }}
          </PmKeyValuePure>

          <PmKeyValuePure label="Adresse">
            <PmAddressPure :address="address" />
          </PmKeyValuePure>

          <PmKeyValuePure label="Telefon">
            <PmRichTextPure>
              <p v-if="phone?.standard">
                <b>Standard:</b>&nbsp;

                <PmLinkPure
                  :href="`tel: ${phone.standard}`"
                  :label="phone.standard"
                />
              </p>

              <p v-if="phone?.company">
                <b>Firma:</b>&nbsp;

                <PmLinkPure
                  :href="`tel: ${phone.company}`"
                  :label="phone.company"
                />
              </p>

              <p v-if="phone?.mobile">
                <b>Mobil:</b>&nbsp;

                <PmLinkPure
                  :href="`tel: ${phone.mobile}`"
                  :label="phone.mobile"
                />
              </p>

              <p v-if="phone?.private">
                <b>Privat:</b>&nbsp;

                <PmLinkPure
                  :href="`tel: ${phone.private}`"
                  :label="phone.private"
                />
              </p>
            </PmRichTextPure>
          </PmKeyValuePure>

          <PmKeyValuePure label="E-Mail">
            <PmLinkPure
              v-if="email"
              :label="email"
              :href="`mailto: ${email}`"
            />
          </PmKeyValuePure>
        </template>

        <template v-if="type === 'vehicle'">
          <PmKeyValuePure label="Fahrzeug">
            {{ vehicle }}
          </PmKeyValuePure>
        </template>

        <PmKeyValuePure label="Projekt">
          <div v-if="project" class="PmDataModalResourceAllocationPure-project">
            <PmPillPure
              :label="project.number ?? undefined"
              :color="projectStatus.color.value"
            />
            <PmLinkPure :label="project.label" @click="emit('openProject')" />
          </div>
        </PmKeyValuePure>

        <PmKeyValuePure label="Job">
          <div v-if="job" class="PmDataModalResourceAllocationPure-job">
            <PmPillPure
              :label="job.number ?? undefined"
              :color="jobStatus.color.value"
            />
            <PmLinkPure :label="job.label" @click="emit('openJob')" />
          </div>
        </PmKeyValuePure>

        <PmKeyValuePure label="Termin">
          <template v-if="appointment">
            <PmLinkPure :label="appointment" @click="emit('openAppointment')" />
          </template>
        </PmKeyValuePure>

        <PmKeyValuePure label="Ressource">
          <template v-if="resource">
            <PmLinkPure :label="resource" @click="emit('openResource')" />
          </template>
        </PmKeyValuePure>

        <template v-if="type === 'address'">
          <PmKeyValuePure
            label="Anfrage"
            :action="actionResourceRequest"
            @open-resource-request="emit('openResourceRequest')"
          >
            <div
              v-if="resourceRequest"
              class="PmDataModalResourceAllocationPure-resourceRequest"
            >
              <PmStatusPillPure
                type="resourceRequestFeedback"
                :resource-request-feedback-status="
                  resourceRequest.statusFeedback
                "
              />

              vom
              {{
                formatWithLocale(
                  resourceRequest.created,
                  FORMAT_DATETIME_DEFAULT
                )
              }}.

              <template v-if="resourceRequest.dateOfExpire">
                Gültig bis
                {{
                  formatWithLocale(
                    resourceRequest.dateOfExpire,
                    FORMAT_DATETIME_DEFAULT
                  )
                }}</template
              >
            </div>
          </PmKeyValuePure>
        </template>

        <PmKeyValuePure
          v-if="type === 'vehicle'"
          label="TomTom Webfleet"
          :action="actionWebfleet"
          @send-to-webfleet="emit('sendToWebfleet')"
        >
          <PmStatusPillPure
            v-if="statusWebfleet"
            type="webfleet"
            :webfleet-status="statusWebfleet"
          />
        </PmKeyValuePure>
      </PmKeyValueListPure>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const COMPONENT_NAME = 'PmDataModalResourceAllocationPure'

export const propTypes = {
  type: {
    allowed: [RESOURCE_TYPE.ADDRESS, RESOURCE_TYPE.VEHICLE],
  },
} as const

export default defineComponent({
  name: COMPONENT_NAME,
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import {
  type ResourceAllocationStatus,
  STATUS_TRAVEL_AND_HOTEL_LOOKUP,
  STATUS_RESOURCE_ALLOCATION_LOOKUP_LABEL,
  type ProjectStatus,
  type JobStatus,
  type StatusResourceRequestFeedback,
  RESOURCE_TYPE,
  FORMAT_DATETIME_DEFAULT,
  type WebfleetStatus,
} from '@/constants/persoplan'
import { IS_DEVELOPMENT } from '@/constants/general'
import { FEATURE_FLAG } from '@/constants/featureFlags'

import {
  extractStatusAndValueFromString,
  stringifyValueWithStatus,
} from '@/utilities/string'
import { lookup } from '@/utilities/misc'
import { formatWithLocale } from '@/utilities/date'

import { useProjectStatus } from '@/composition/useProjectStatus'
import { useJobStatus } from '@/composition/useJobStatus'

import PmRichTextPure from '@/components/basics/PmRichText/PmRichTextPure.vue'
import PmPillPure from '@/components/basics/PmPillPure.vue'
import PmLinkPure from '@/components/basics/PmLink/PmLinkPure.vue'
import PmStatusDotPure from '@/components/persoplan/PmStatusDot/PmStatusDotPure.vue'
import PmStatusPillPure from '@/components/persoplan/PmStatusPill/PmStatusPillPure.vue'
import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmLoadingPure from '@/components/basics/PmLoading/PmLoadingPure.vue'
import PmKeyValueListPure from '@/components/basics/PmKeyValueList/PmKeyValueListPure.vue'
import PmKeyValuePure, {
  type Props as PropsKeyValuePure,
} from '@/components/basics/PmKeyValueList/PmKeyValuePure.vue'
import PmAddressPure from '@/components/basics/PmAddress/PmAddressPure.vue'

import type { Nilable } from '@/types/misc'

export interface Props {
  isLoading?: boolean
  type?: (typeof propTypes.type.allowed)[number]
  id: Nilable<number>
  status?: Nilable<ResourceAllocationStatus>
  factor?: Nilable<number>
  travel?: Nilable<string>
  hotel?: Nilable<string>
  note?: Nilable<string>
  name?: Nilable<string>
  company?: Nilable<string>
  address?: Nilable<Address>
  phone?: Nilable<Phone>
  email?: Nilable<string>
  vehicle?: Nilable<string>
  project?: Nilable<Project>
  job?: Nilable<Job>
  appointment?: Nilable<string>
  resource?: Nilable<string>
  resourceRequest: Nilable<ResourceRequest>
  statusWebfleet?: Nilable<WebfleetStatus>
  isWebfleetVehicle?: Nilable<boolean>
  canCreateWebfleetOrder?: boolean
}

export type Address = {
  id?: Nilable<number>
  street?: Nilable<string>
  zip?: Nilable<string>
  city?: Nilable<string>
  country?: Nilable<string>
}

export type Phone = {
  standard?: Nilable<string>
  company?: Nilable<string>
  mobile?: Nilable<string>
  private?: Nilable<string>
}

export type Project = {
  number: Nilable<string>
  label: Nilable<string>
  status: Nilable<ProjectStatus>
}

export type Job = {
  number: Nilable<string>
  label: Nilable<string>
  status: Nilable<JobStatus>
}

export type ResourceRequest = {
  created: Date
  dateOfExpire: Nilable<Date>
  statusFeedback: Nilable<StatusResourceRequestFeedback>
  hasNotification?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
  (event: 'openProject'): void
  (event: 'openJob'): void
  (event: 'openAppointment'): void
  (event: 'openResource'): void
  (event: 'openResourceRequest'): void
  (event: 'sendToWebfleet'): void
}>()

const statusNormalized = computed(() => {
  return lookup(props.status, STATUS_RESOURCE_ALLOCATION_LOOKUP_LABEL)
})

const travelNormalized = computed(() => {
  return stringifyValueWithStatus(props.travel)
})

const travelStatus = computed(() => {
  const statusId = extractStatusAndValueFromString(props.travel)?.statusId
  if (!statusId) return

  return lookup(statusId, STATUS_TRAVEL_AND_HOTEL_LOOKUP)
})

const hotelNormalized = computed(() => {
  return stringifyValueWithStatus(props.hotel)
})

const hotelStatus = computed(() => {
  const statusId = extractStatusAndValueFromString(props.hotel)?.statusId
  if (!statusId) return

  return lookup(statusId, STATUS_TRAVEL_AND_HOTEL_LOOKUP)
})

const projectStatusNormalized = computed(() => props.project?.status)

const projectStatus = useProjectStatus({
  status: projectStatusNormalized,
})

const jobStatusNormalized = computed(() => props.job?.status)

const jobStatus = useJobStatus({
  status: jobStatusNormalized,
})

const actionWebfleet = computed<PropsKeyValuePure['action'] | undefined>(() => {
  if (!props.canCreateWebfleetOrder) return

  return {
    id: 'sendToWebfleet',
    label: 'An Webfleet übertragen',
    icon: 'forward',
    disabled: props.isWebfleetVehicle !== true,
  }
})

const actionResourceRequest = computed<PropsKeyValuePure['action'] | undefined>(
  () => {
    if (!props.resourceRequest) return

    return {
      id: 'openResourceRequest',
      label: 'Details',
      notification: props.resourceRequest?.hasNotification,
      iconAfter: 'arrowRight',
    }
  }
)
</script>

<style lang="scss">
.PmDataModalResourceAllocationPure {
  $block: &;

  &-statusTravel {
    display: flex;
    gap: 4px;
  }

  &-statusHotel {
    display: flex;
    gap: 4px;
  }

  &-project {
    display: flex;
    gap: 8px;
  }

  &-job {
    display: flex;
    gap: 8px;
  }

  &-resourceRequest {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
  }

  &-resourceRequestMore {
    margin-left: auto;
    margin-top: -6px;
    margin-bottom: -6px;
    margin-right: -6px;
  }
}
</style>
