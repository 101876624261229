<template>
  <div :class="componentClass.root">
    <div :class="componentClass.element('selection')">
      <div :class="componentClass.element('selectionLabel')">
        {{ t('resourceFunctions', numberOfSelectedResourceFunctions) }}
        ausgewählt
      </div>

      <div :class="componentClass.element('selectionContent')">
        <div :class="componentClass.element('selectionActions')">
          <PmButtonPure
            :icon="ICONS.SEARCH"
            label="Passende Ressource suchen"
            @click="emit('search')"
          />
        </div>
      </div>
    </div>

    <PmFormPure :id="formId" variant="compact" @submit.prevent="onSubmit">
      <div :class="componentClass.element('resourcesInput')">
        <slot name="resourcesInput">
          <PmSlotPlaceholderPure>resourcesInput</PmSlotPlaceholderPure>
        </slot>
      </div>

      <PmDropdownPure
        v-model:value="statusId"
        :options="statusOptions"
        label="Status"
        required
      />

      <PmInputPure v-model:value="note" label="Bemerkung" />

      <PmInputWithStatusPure
        v-if="type === 'address'"
        v-model:value="travel"
        label="Travel"
      />

      <PmInputWithStatusPure
        v-if="type === 'address'"
        v-model:value="hotel"
        label="Hotel"
      />

      <template #control>
        <PmButtonListPure>
          <PmButtonPure
            label="Abbrechen"
            icon="close"
            @click="emit('cancel')"
          />

          <PmButtonListDividerPure />

          <PmButtonPure
            variant="primary"
            label="Erstellen"
            type="submit"
            icon-after="plus"
            :form="formId"
          />
        </PmButtonListPure>
      </template>
    </PmFormPure>
  </div>
</template>

<script setup lang="ts">
import { type VNode, ref, watch } from 'vue'
import { useComponentClass } from '@thomasaull-shared/composables'
import { useI18n } from 'vue-i18n'
import cuid from '@paralleldrive/cuid2'
import * as v from 'valibot'

import { type ResourceType } from '@/constants/persoplan'
import { ICONS } from '@/constants/icons'

import PmButtonPure from '@/components/basics/PmButtonPure.vue'
import PmButtonListPure from '@/components/basics/PmButtonListPure.vue'
import PmSlotPlaceholderPure from '@/components/basics/PmSlotPlaceholder/PmSlotPlaceholderPure.vue'
import PmFormPure from '@/components/basics/PmForm/PmFormPure.vue'
import PmButtonListDividerPure from '@/components/basics/PmButtonListDivider/PmButtonListDividerPure.vue'
import PmDropdownPure, {
  type Props as PropsDropdownPure,
} from '@/components/basics/PmDropdownPure.vue'
import PmInputPure from '@/components/basics/PmInput/PmInputPure.vue'
import PmInputWithStatusPure from '@/components/basics/PmInputWithStatus/PmInputWithStatusPure.vue'
import type { RequireKeys } from '@/types/misc'

export interface Props {
  type: Exclude<ResourceType, 'freelancer'>
  /** How many resource functions will be allocated? */
  numberOfSelectedResourceFunctions: number
  /** How many people/vehicles will be allocated to the resources? */
  numberOfSelectedResourcesToAllocate: number
  statusOptions: PropsDropdownPure['options']
  defaultValues?: {
    statusId?: number
  }
}

const props = withDefaults(defineProps<Props>(), {})

export type Emits = {
  submit: FormDataAddress | FormDataVehicle
}

const emit = defineEmits<{
  cancel: []
  search: []
  submit: [Emits['submit']]
}>()

const slots = defineSlots<{
  resourcesInput(): VNode[]
}>()

const componentClass = useComponentClass()
const formId = cuid.createId()

const { t } = useI18n({
  messages: {
    de: {
      resourceFunctions: '{n} Ressourcenfunktion | {n} Ressourcenfunktionen',
      resources: '{n} Ressource wird | {n} Ressourcen werden',
    },
  },
})
const formDataBaseSchema = v.object({
  statusId: v.number(),
  note: v.optional(v.string()),
})

const formDataAddressSchema = v.object({
  ...formDataBaseSchema.entries,
  type: v.literal('address'),
  hotel: v.optional(v.string()),
  travel: v.optional(v.string()),
})

const formDataVehicleSchema = v.object({
  type: v.literal('vehicle'),
  ...formDataBaseSchema.entries,
})

export type FormDataAddress = v.InferOutput<typeof formDataAddressSchema>
export type FormDataVehicle = v.InferOutput<typeof formDataVehicleSchema>

const statusId = ref<number>()
const note = ref<string>()
const travel = ref<string>()
const hotel = ref<string>()

function onSubmit() {
  if (props.type === 'address') submitAddress()
  if (props.type === 'vehicle') submitVehicle()
}

function submitAddress() {
  const formData = v.parse(formDataAddressSchema, {
    type: 'address',
    statusId: statusId.value,
    note: note.value,
    hotel: hotel.value,
    travel: travel.value,
  } satisfies RequireKeys<FormDataAddress>)

  emit('submit', formData)
}

function submitVehicle() {
  const formData = v.parse(formDataVehicleSchema, {
    type: 'vehicle',
    statusId: statusId.value,
    note: note.value,
  } satisfies RequireKeys<FormDataVehicle>)

  emit('submit', formData)
}

/**
 * Set default values
 */
watch(
  () => props.defaultValues,
  () => {
    if (!props.defaultValues) return

    statusId.value = props.defaultValues.statusId
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss">
.PmMultipleResourcesDefaultPure {
  $block: &;

  width: 100%;

  &-selection {
    margin-bottom: 12px;
  }

  &-selectionLabel {
    @include mixin.textLabel;

    margin-bottom: 8px;
  }

  &-selectionContent {
    display: flex;
  }

  &-selectionResourceFunctions {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  &-resourcesInput {
    margin-bottom: 16px;
  }
}
</style>
