import type { ResourceType } from '@/constants/persoplan'
import { computed, readonly, ref, watch, type Ref } from 'vue'
import { isNil } from 'lodash-es'

/**
 * resource ids of the selected items
 */
const selectedIds = ref(new Set<number>())

type SelectedType = Exclude<ResourceType, 'freelancer'>
const selectedType = ref<SelectedType>()

watch(
  () => selectedIds.value.size,
  () => {
    // Reset type when selection is empty
    if (selectedIds.value.size === 0) {
      selectedType.value = undefined
    }
  }
)

function clear() {
  selectedIds.value.clear()
}

export function useSelectionResources() {
  const isEmpty = computed(() => {
    return selectedIds.value.size === 0
  })

  return {
    isEmpty,
    selectedType: readonly(selectedType),
    selectedIds: readonly(selectedIds),
    clear: clear,
  }
}

function assertType(type: Options['type']): asserts type is Ref<SelectedType> {
  if (isNil(type)) throw new Error('type is nil')
}

type Options = {
  id: number
  type: Ref<SelectedType | undefined>
}

export function useSelectionResourcesItem(options: Options) {
  function add() {
    assertType(options.type)

    if (!selectedType.value) {
      selectedType.value = options.type.value
    }

    if (selectedType.value !== options.type.value) {
      throw new Error('wrong type')
    }

    selectedIds.value.add(options.id)
  }

  function remove() {
    assertType(options.type)

    if (selectedType.value !== options.type.value) {
      throw new Error('wrong type')
    }

    selectedIds.value.delete(options.id)
  }

  const isSelected = computed(() => {
    if (options.type.value !== selectedType.value) return false
    return selectedIds.value.has(options.id)
  })

  return {
    add,
    remove,
    isSelected,
    selectedType: readonly(selectedType),
  }
}
